import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { useRoles } from "../../api/role";
import LabeledSelectField from "src/components/LabeledSelectField";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (props: any) => void;
  submitText?: string;
  title?: string;
}

function UserModal({
  isOpen,
  onClose,
  submitText = "submit",
  title = "Modal Title",
  onSubmit,
}: UserModalProps) {
  const { data: roles } = useRoles();

  const rolesOptions = (roles?.docs || []).map((item) => ({
    value: item._id,
    label: item.label,
  }));


  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <LabeledTextField name="name" label="Tên" required />
                <LabeledTextField name="username" label="Username" required />
                <LabeledTextField name="password" label="Password" required />
                <LabeledSelectField
                  required
                  options={rolesOptions}
                  name="roles"
                  label="Vai trò"
                />
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} variant="ghost" mr={3}>
                  Hủy
                </Button>
                <Button type="submit" colorScheme="blue" >
                  {submitText}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>

        )}
      />

    </Modal>
  );
}

export default UserModal;
