import React from "react";
import {
  useQueryParams,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
  RadioGroup,
  SimpleGrid,
  Radio,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import DATE_OPTIONS from "src/utils/dateOptions";
import { DateKey } from "src/types/DateKey";

const options = DATE_OPTIONS;

function FilterByUpdated() {
  const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [currentOption, setCurrentOption] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
    label: StringParam,
  });

  const [dateKey, setDateKey] = useQueryParam(
    "dateKey",
    withDefault(StringParam, DateKey.UpdatedAt)
  );

  const selectedOption = DATE_OPTIONS.find(
    (item) => item.value.startDate === currentOption.startDate
  );

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentOption.startDate ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedOption?.label || "Thời gian"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Thời gian</PopoverHeader>
        <PopoverBody>
          <RadioGroup onChange={setDateKey} value={dateKey}>
            <SimpleGrid borderWidth="1px" columns={2} py={2} px={4} my={2} borderRadius="md">
              <Radio size="sm" cursor="pointer" value={DateKey.UpdatedAt}>Cập nhật</Radio>
              <Radio size="sm" cursor="pointer" value={DateKey.CreatedAt}>Tạo</Radio>
            </SimpleGrid>
          </RadioGroup>
          <Box maxH="56" overflowY="auto">
            {options.map((option) => (
              <HStack
                onClick={() => {
                  setCurrentOption({ ...option.value });
                  onClose();
                }}
                color={
                  option.value.startDate === currentOption.startDate
                    ? "blue.500"
                    : undefined
                }
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={option.value.startDate}
                p={2}
              >
                <Text fontSize="sm" fontWeight="semibold">
                  {option.label}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setCurrentOption({
                  startDate: "",
                  endDate: "",
                });
                onClose();
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default FilterByUpdated;
