import React, { useState } from "react";
import { useQueryParams, StringParam } from "use-query-params";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import * as locales from "react-date-range/dist/locale";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Box,
  ButtonGroup,
  LightMode,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";

interface FilterByTimeProps {
  name: string;
  label: string;
}

function FilterByTime({ name, label }: FilterByTimeProps) {
  const initialFocusRef = React.useRef();
  const [currentRange, setCurrentRange] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
    dateKey: StringParam,
  });

  const defaultSelection = {
    startDate: currentRange.startDate
      ? new Date(currentRange.startDate)
      : new Date(),
    endDate: currentRange.endDate ? new Date(currentRange.endDate) : new Date(),
    key: `selection-${name}`,
  };

  const [selection, setSelection] = useState(defaultSelection);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleApplyRange = () => {
    setCurrentRange({
      startDate: selection.startDate.toISOString(),
      endDate: selection.endDate.toISOString(),
      dateKey: name,
    });
    onClose();
  };

  const handleClearRange = () => {
    setCurrentRange({
      startDate: "",
      endDate: "",
      dateKey: "",
    });
    setSelection(defaultSelection);
    onClose();
  };

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          variant="ghost"
          onClick={onOpen}
          color={currentRange?.startDate ? "blue.500" : undefined}
        >
          {label}
        </Button>
      </PopoverTrigger>
      <LightMode>
        <PopoverContent bg="white" w="min-content" p={4}>
          <Box borderWidth="1px" borderColor="gray.100">
            <DateRangePicker
              locale={locales["vi"]}
              ranges={[selection]}
              onChange={(e) => {
                setSelection(e[Object.keys(e)[0]]);
              }}
            />
          </Box>
          <ButtonGroup mt={4} d="flex" justifyContent="flex-end">
            <Button onClick={handleClearRange} variant="ghost">
              Xóa
            </Button>
            <Button onClick={handleApplyRange}>Áp dụng</Button>
          </ButtonGroup>
        </PopoverContent>
      </LightMode>
    </Popover>
  );
}

export default FilterByTime;
