import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Form } from "react-final-form";
import { Link, useParams } from "react-router-dom";
import LabeledTextField from "src/components/LabeledTextField";
import { useCenter, useUpdateCenter } from "../../api/center";

function CenterDetail() {
  const { id } = useParams<{ id: string }>();
  const updateCenter = useUpdateCenter(id);

  const { data } = useCenter(id);

  return (
    <Container maxW="container.xl">
      <Breadcrumb
        my={8}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/center?page=1">
            Trung Tâm
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{data?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Form
        onSubmit={(v) => {
          updateCenter.mutate(v);
        }}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <VStack maxW="sm" spacing={6}>
              <LabeledTextField name="name" label="Tên" />
              <LabeledTextField name="address" label="Địa chỉ" />
              <LabeledTextField name="latitude" label="Latitude" />
              <LabeledTextField name="longitude" label="Longitude" />
              <Button
                isLoading={updateCenter.isLoading}
                w="full"
                type="submit"
                colorScheme="blue"
              >
                Cập nhật
              </Button>
            </VStack>
          </form>
        )}
      />
    </Container>
  );
}

function CenterDetailWrapper() {
  return (
    <React.Suspense fallback="loading...">
      <CenterDetail />
    </React.Suspense>
  );
}

export default CenterDetailWrapper;
