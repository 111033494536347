import React from "react";
import { useQueryParam, StringParam } from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { useEventCategories } from "../api/eventCategory";

function Filter() {
  const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [currentEventCategory, setCurrentEventCategory] = useQueryParam(
    "category",
    StringParam
  );

  const { data: eventCategories } = useEventCategories();

  const selectedEventCategory = (eventCategories?.docs || []).find(
    (item) => item._id === currentEventCategory
  );

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentEventCategory ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedEventCategory?.name || "Danh mục"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Danh mục</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {eventCategories?.docs?.map((eventCategory) => (
              <HStack
                onClick={() => {
                  setCurrentEventCategory(eventCategory._id);
                  onClose();
                }}
                color={
                  eventCategory._id === currentEventCategory
                    ? "blue.500"
                    : undefined
                }
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={eventCategory._id}
                p={2}
              >
                <Box
                  flexShrink={0}
                  w={2.5}
                  h={2.5}
                  bg={`${eventCategory.color}.500`}
                  borderRadius="full"
                />
                <Text fontSize="sm" fontWeight="semibold">
                  {eventCategory.name}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setCurrentEventCategory("");
                onClose();
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByEventCategory() {
  return (
    <Suspense
      fallback={
        <Button variant="ghost" isLoading>
          Danh mục
        </Button>
      }
    >
      <Filter />
    </Suspense>
  );
}

export default FilterByEventCategory;
