import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { SimpleGrid } from "@chakra-ui/react";
import { UpdateStudentDto } from "../../api/student";
import LabeledSelectUser from "src/components/LabeledSelectUser";
import LabeledSelectCenter from "src/components/LabeledSelectCenter";
import LabeledNumberField from "src/components/LabeledNumberField";
import { formatMoney } from "src/utils/format";
import LabeledTextareaField from "src/components/LabeledTextareaField";

interface StudentFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateStudentDto;
}

function StudentForm({ onSubmit, formId, initialValues }: StudentFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <SimpleGrid columns={2} gap={4}>
            <LabeledTextField name="name" label="Tên" required />
            <LabeledTextField name="phone" label="Điện thoại" />
            <LabeledTextField name="birthDay" label="Ngày sinh" type="date" />
            <LabeledTextField name="email" label="Email" />
            <LabeledTextField name="motherName" label="Tên mẹ" />
            <LabeledTextField name="fatherName" label="Tên bố" />
            <LabeledSelectCenter name="center" label="Trung tâm" />
            <LabeledSelectUser name="takeCareBy" label="Take care" />
            <LabeledTextField name="startCourseDate" label="Ngày bắt đầu khóa" type="date" />
            <LabeledNumberField
              min={0}
              autoComplete="off"
              name="numberOfSessions"
              label="Số buổi tập đăng kí"
              type="number"
            />
            <LabeledNumberField
              min={0}
              autoComplete="off"
              name="feePerSession"
              label="Học phí mỗi buổi"
              type="number"
              helperText={formatMoney(values.feePerSession || 0)}
            />
            <LabeledTextareaField name="sessionNote" label="Ghi chú về số buổi tập" />
          </SimpleGrid>
        </form>
      )}
    />
  );
}

export default StudentForm;
