import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";

export interface CreateSkillCategoryDto {
  name: string;
}

export interface GetSkillCategoryDto extends Partial<CreateSkillCategoryDto> {}

export interface UpdateSkillCategoryDto
  extends Partial<CreateSkillCategoryDto> {
  status?: Status;
}

export interface SkillCategoryDocument extends TimeStamp, ObjectId {
  name: string;
}

// api
export const createSkillCategory = async (
  createSkillCategoryDto: CreateSkillCategoryDto
) => {
  const { data } = await axios.post("/skill-category", createSkillCategoryDto);
  return data;
};

export const updateSkillCategory = async (
  id: string,
  updateSkillCategoryDto: UpdateSkillCategoryDto
) => {
  const { data } = await axios.patch(
    `/skill-category/${id}`,
    updateSkillCategoryDto
  );
  return data;
};

export const getSkillCategory = async (id: string) => {
  const { data } = await axios.get<SkillCategoryDocument>(
    `/skill-category/${id}`
  );
  return data;
};

export const getSkillCategories = async (
  params?: QueryOptionParams & GetSkillCategoryDto
) => {
  const { data } = await axios.get<PaginateDocument<SkillCategoryDocument>>(
    "/skill-category",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

const deleteSkillCategory = async (id: string) => {
  const { data } = await axios.delete(`/skill-category/${id}`);
  return data;
};

// hooks
export const skillCategoryKeys = generateQueryKey("skill-category");

export const useSkillCategories = (
  params?: QueryOptionParams & GetSkillCategoryDto
) => {
  return useQuery(skillCategoryKeys.list(params), () =>
    getSkillCategories(params)
  );
};

export const useSkillCategory = (id: string) => {
  return useQuery(skillCategoryKeys.detail(id), () => getSkillCategory(id));
};

export const useCreateSkillCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(createSkillCategory, {
    onSuccess: () => queryClient.invalidateQueries(skillCategoryKeys.lists()),
  });
};

export const useUpdateSkillCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: string; data: UpdateSkillCategoryDto }) =>
      updateSkillCategory(id, data),
    {
      onSuccess: () => queryClient.invalidateQueries(skillCategoryKeys.lists()),
    }
  );
};

export const useDeleteSkillCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSkillCategory, {
    onSuccess: () => queryClient.invalidateQueries(skillCategoryKeys.lists()),
  });
};
