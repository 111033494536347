import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import SkillForm from "./SkillForm";
import {
  useCreateSkill,
} from "../../api/skill";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-skill";

interface SkillModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function SkillModalAdd({ isOpen, onClose }: SkillModalAddProps) {
  const createSkill = useCreateSkill();

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thêm kĩ năng</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SkillForm
            onSubmit={async (v) => {
              try {
                await createSkill.mutateAsync(v);
                onClose();
              } catch (error) {}
            }}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Thêm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SkillModalAdd;
