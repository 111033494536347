import React, { Suspense, useState } from "react";
import { LeadDocument, useLeads, updateLead, leadKeys } from "../../api/lead";
import {
  useDisclosure,
  Tooltip,
  Avatar,
  HStack,
  Text,
  Badge,
  IconButton,
  Icon,
  Link,
} from "@chakra-ui/react";
import Table from "src/components/Table";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdRestore } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ModalEditLead from "./ModalEditLead";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LeadTableNote from "./LeadTableNote";
import { Status } from "src/types/Status";
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";

function LeadTable() {
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const [editingData, setEditingData] = useState<LeadDocument>();

  const { search } = useLocation();
  const params = queryString.parse(search);

  const { data: leads } = useLeads(params);

  const editLead = React.useCallback(
    (lead: LeadDocument) => {
      onOpenEdit();
      setEditingData(lead);
    },
    [onOpenEdit]
  );

  const archiveLead = async (lead: LeadDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${lead?.name}`)) {
        await updateLead(lead._id, { status: Status.Inactive });
        queryClient.invalidateQueries(leadKeys.all);
      }
    } catch (error) {}
  };

  const restoreLead = async (lead: LeadDocument) => {
    try {
      if (window.confirm(`Khôi phục ${lead?.name}`)) {
        await updateLead(lead._id, { status: Status.Active });
        queryClient.invalidateQueries(leadKeys.all);
      }
    } catch (error) {}
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<LeadDocument>) => (
          <Link
            as={RouterLink}
            to={`/lead/${row.original._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {row.original.name}
          </Link>
        ),
      },
      { Header: "Số điện thoại", accessor: "phone" },
      {
        Header: "Take care",
        accessor: "takeCareBy",
        Cell: ({ value }) => (
          <Link color="blue.300" to={`/user/${value?._id}`} as={RouterLink}>
            <HStack>
              <Avatar size="xs" name={value?.name} />
              <Text>{value?.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Trạng thái",
        accessor: "leadStatus",
        Cell: ({ value }) => (
          <Badge colorScheme={value.color}>{value?.label}</Badge>
        ),
      },
      {
        Header: "Cập nhật",
        accessor: "updatedAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Tạo",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<LeadDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveLead(row.original);
                    } else {
                      restoreLead(row.original);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editLead(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
      {
        Header: "Ghi chú",
        Cell: LeadTableNote,
      },
    ], // eslint-disable-next-line
    [editLead]
  );

  return (
    <>
      <ModalEditLead
        editData={editingData}
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
      />
      <Table columns={columns} data={leads?.docs || []} />
    </>
  );
}

function LeadTableWrapper() {
  return (
    <Suspense fallback="Loading...">
      <LeadTable />
    </Suspense>
  );
}

export default LeadTableWrapper;
