import axios from "src/config/axios";
import { useQuery, useMutation } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { ClassroomDocument } from "./classroom";
import { CenterDocument } from "./center";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { UserDocument } from "./user";
import { NoteDocument } from "./note";

export interface CreateStudentDto {
  name: string;
  phone?: string;
  email?: string;
  fatherName?: string;
  motherName?: string;
  center?: string;
  takeCareBy?: string;
  birthDay?: string;
  photoUrl?: string;
  feePerSession: number;
  startCourseDate: string;
  numberOfSessions: number;
  sessionNote: string;
  remainingSessions?: number;
}

export interface GetStudentDto extends Partial<CreateStudentDto> {
  balance?: number;
}

export interface UpdateStudentDto extends Partial<CreateStudentDto> {
  remainingSessions?: number;
  status?: Status;
  balance?: number;
  notes?: any;
  $addToSet?: any;
  $pull?: any;
}

export interface StudentDocument extends TimeStamp, ObjectId {
  number: number;
  name: string;
  phone: string;
  email: string;
  fatherName?: string;
  motherName?: string;
  classrooms?: ClassroomDocument[];
  center?: CenterDocument;
  status: Status;
  takeCareBy: UserDocument;
  birthDay: string;
  photoUrl: string;
  notes: NoteDocument[];
  feePerSession: number;
  startCourseDate: string;
  remainingSessions: number;
  numberOfSessions: number;
  sessionNote: string;
}

// api
export const createStudent = async (createStudentDto: CreateStudentDto) => {
  const { data } = await axios.post("/student", createStudentDto);
  return data;
};

export const getStudent = async (id: string) => {
  const { data } = await axios.get<StudentDocument>(`/student/${id}`);
  return data;
};

export const getStudents = async (
  params?: QueryOptionParams & GetStudentDto
) => {
  const { data } = await axios.get<PaginateDocument<StudentDocument>>(
    "/student",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

export const updateStudent = async (
  id: string,
  updateStudentDto: UpdateStudentDto
) => {
  const { data } = await axios.patch(`/student/${id || ""}`, updateStudentDto);
  return data;
};

export const getBirthdayStudentComing = async () => {
  const { data } = await axios.get<StudentDocument>(`/student/birthday`);
  return data;
};

export const updateStudentBalanceById = async (id: string) => {
  const { data } = await axios.patch<StudentDocument>(
    `/student/calculate-balance/${id}`
  );
  return data;
};

// hooks
export const studentKeys = generateQueryKey("student");

export const useStudents = (params?: QueryOptionParams & GetStudentDto) => {
  return useQuery(studentKeys.list(params), () => getStudents(params));
};

export const useStudent = (id: string) => {
  return useQuery(studentKeys.detail(id), () => getStudent(id), {
    staleTime: Infinity,
  });
};

export const useUpdateStudent = () => {
  return useMutation(({ id, data }: { id: string; data: UpdateStudentDto }) =>
    updateStudent(id, data)
  );
};

export const useStudentBirthday = () => {
  return useQuery("student-birthday", () => getBirthdayStudentComing());
};


export const getStudentsBody = async (
  params?: QueryOptionParams & GetStudentDto
) => {
  const { data } = await axios.get<StudentDocument[]>(
    "/student/body",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};


// hooks
export const studentBodyKeys = generateQueryKey("student-body");


export const useStudentsBody = (params?: QueryOptionParams & GetStudentDto) => {
  return useQuery(studentBodyKeys.list(params), () => getStudentsBody(params));
};