import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultSize,
  withDefaultVariant,
} from "@chakra-ui/react";

import defaultTheme from "./src";

const theme = extendTheme(
  defaultTheme,
  withDefaultColorScheme({ colorScheme: "blue" }),
  withDefaultVariant({ variant: "filled", components: ["Input", "Textarea"] }),
  withDefaultSize({ size: "sm", components: ["Input", "Button"] })
);

export default theme;
