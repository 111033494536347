import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link, useParams } from "react-router-dom";
import { useLead } from "../../api/lead";
import Ability from "../studentDetail/Ability";

function LeadDetail() {
  const { id } = useParams<{ id: string }>();
  const lead = useLead(id);

  return (
    <Container maxW="container.xl">
      <Breadcrumb
        my={8}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/lead?page=1">
            Lead
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{lead?.data?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Tabs isLazy>
        <TabList>
          <Tab>Thông số</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Ability type="lead" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

function LeadDetailWrapper() {
  return (
    <React.Suspense fallback="loading...">
      <LeadDetail />
    </React.Suspense>
  );
}

export default LeadDetailWrapper;
