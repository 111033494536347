import dayjs from "dayjs";

const DATE_OPTIONS = [
  {
    value: {
      startDate: dayjs().startOf("days").toISOString(),
      endDate: dayjs().endOf("days").toISOString(),
    },
    label: "Hôm nay",
  },
  {
    value: {
      startDate: dayjs().subtract(1, "days").startOf("days").toISOString(),
      endDate: dayjs().startOf("days").toISOString(),
    },
    label: "Hôm qua",
  },
  {
    value: {
      startDate: dayjs().subtract(3, "days").startOf("days").toISOString(),
      endDate: dayjs().startOf("days").toISOString(),
    },
    label: "3 ngày trước",
  },
  {
    value: {
      startDate: dayjs().subtract(7, "days").startOf("days").toISOString(),
      endDate: dayjs().startOf("days").toISOString(),
    },
    label: "7 ngày trước",
  },
  {
    value: {
      startDate: dayjs().subtract(30, "days").startOf("days").toISOString(),
      endDate: dayjs().startOf("days").toISOString(),
    },
    label: "30 ngày trước",
  },
];

export default DATE_OPTIONS