import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";

import {
  Input,
  InputGroup,
  InputRightElement,
  CloseButton,
} from "@chakra-ui/react";
import { useQueryParam, StringParam } from "use-query-params";

interface FilterBySearchKeyProps {
  placeholder?: string;
}

function FilterBySearchKey({
  placeholder = "Tìm kiếm",
}: FilterBySearchKeyProps) {
  const [, setSearch] = useQueryParam("search", StringParam);
  const [value, setValue] = useState("");
  const firstUpdate = useRef(true);

  const debounced = React.useCallback(
    // eslint-disable-line react-hooks/exhaustive-deps
    debounce((v) => setSearch(v), 700),
    []
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    debounced(value);
  }, [value, debounced]);

  return (
    <InputGroup w="xs">
      <Input
        autoFocus
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder={placeholder}
      />
      {value && (
        <InputRightElement>
          <CloseButton
            onClick={() => {
              setValue("");
            }}
            size="sm"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

export default FilterBySearchKey;
