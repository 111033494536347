import axios from "src/config/axios";
import { useQuery } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { UserDocument } from "./user";
import { Device } from "./device";
import { GeoLocationSensorState } from "react-use/lib/useGeolocation";
import { CenterDocument } from "./center";

export interface CreateTimeSheetDto {
  dayOfMonth: string;
  checkIn: string;
  device?: Device;
  location?: GeoLocationSensorState; //deprecated
  checkInLocation: GeoLocationSensorState;
  checkOutLocation?: GeoLocationSensorState;
}

export interface GetTimeSheetDto extends Partial<CreateTimeSheetDto> {
  status?: Status;
  checkOut?: string;
  center?: string;
  user?: string;
}

export interface UpdateTimeSheetDto extends Partial<CreateTimeSheetDto> {
  status?: Status;
  checkOut?: string;
}

export interface TimeSheetDocument extends TimeStamp, ObjectId {
  status: Status;
  user: UserDocument;
  date: Date;
  checkIn: Date;
  checkOut?: Date;
  device?: Device;
  location: GeoLocationSensorState; //deprecated
  checkInLocation: GeoLocationSensorState;
  checkOutLocation?: GeoLocationSensorState;
  center: CenterDocument;
  dayOfMonth?: Date;
}

// api
export const createTimeSheet = async (
  createTimeSheetDto: CreateTimeSheetDto
) => {
  const { data } = await axios.post("/time-sheet", createTimeSheetDto);
  return data;
};

export const getTimeSheet = async (id: string) => {
  const { data } = await axios.get<TimeSheetDocument>(`/time-sheet/${id}`);
  return data;
};

export const getTimeSheets = async (
  params?: QueryOptionParams & GetTimeSheetDto
) => {
  const { data } = await axios.get<PaginateDocument<TimeSheetDocument>>(
    "/time-sheet",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

export const updateTimeSheet = async (
  id: string,
  updateTimeSheetDto: UpdateTimeSheetDto
) => {
  const { data } = await axios.patch(
    `/time-sheet/${id || ""}`,
    updateTimeSheetDto
  );
  return data;
};

// hooks
export const timeSheetKeys = generateQueryKey("timeSheet");

export const useTimeSheets = (
  params?: QueryOptionParams & GetTimeSheetDto,
  enabled?: boolean
) => {
  return useQuery(timeSheetKeys.list(params), () => getTimeSheets(params), {
    enabled: enabled,
  });
};

export const useTimeSheet = (id: string) => {
  return useQuery(timeSheetKeys.detail(id), () => getTimeSheet(id), {
    staleTime: Infinity,
  });
};
