import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import LabeledTextField from "src/components/LabeledTextField";
import { ClassCategoryDto, createClassCategory, classCategoryKeys, updateClassCategory } from "../../api/classCategory";
import { useQueryClient } from "react-query";

function AddClassCategoryForm({ isOpen, editMode, onClose, data }: { isOpen: boolean, editMode: boolean, onClose: any, data: ClassCategoryDto }) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: ClassCategoryDto) => {
    try {
      if (editMode) {
        await updateClassCategory(values._id, values)
      } else {
        await createClassCategory(values);
      }
      onClose();
      queryClient.invalidateQueries(classCategoryKeys.all);
      toast({
        description: "Thành công",
        status: "success",
      });
    } catch (error) {
      toast({
        description: "Lỗi",
        status: "error",
      });
    }
  };
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        closeOnOverlayClick={false}
        size="md"
      >
        <DrawerOverlay />
        <Form
          initialValues={editMode ? data : {}}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                  {!editMode ? `Thêm khóa học` : `Chỉnh sửa khóa học: ${data.name}`}
                </DrawerHeader>
                <DrawerBody>
                  <VStack spacing={4}>
                    <LabeledTextField name="name" label="Tên" required />
                    <LabeledTextField name="price" type="number" label="Giá tiền" required />
                    <LabeledTextField name="numberOfLesson" type="number" label="Số buổi" required />
                    <LabeledTextField name="salaryByLesson" type="number" label="Lương dạy" required />
                  </VStack>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Hủy
                  </Button>
                  <Button type="submit" colorScheme="blue">
                    {editMode ? "Cập nhật" : "Thêm"}
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </form>
          )}
        />
      </Drawer>
    </>
  );
}

export default AddClassCategoryForm;
