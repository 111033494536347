import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useToken,
} from "@chakra-ui/react";
import { forwardRef, PropsWithoutRef } from "react";
import { useField } from "react-final-form";
import Select, { StylesConfig } from "react-select";

export interface Option {
  value: any;
  label?: string;
}

export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  options: Option[];
  defaultValue?: any;
}

export const LabeledSelectField = forwardRef<
  HTMLTextAreaElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      placeholder,
      helperText,
      disabled,
      options,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const {
      input: { onChange, value },
      meta: { touched, error, submitError },
    } = useField(name);

    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    const customStyles = useSelectStyles();

    return (
      <FormControl color="gray.100" isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb="1.5">
          {label}
        </FormLabel>
        <Select
          value={options.find((item) => item.value === value)}
          onChange={(value) => onChange(value?.value)}
          styles={customStyles}
          options={options}
          defaultValue={defaultValue}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export function useSelectStyles() {
  const [gray100, gray200, gray500, gray600, whiteAlpha300] = useToken(
    "colors",
    ["gray.100", "gray.200", "gray.500", "gray.600", "whiteAlpha.100"]
  );
  const [shadowMd] = useToken("shadows", ["md"]);

  const styles = React.useMemo<StylesConfig>(
    () => ({
      menu: (provided, state) => ({
        ...provided,
        width: "100%",
        color: gray100,
        padding: 6,
        borderRadius: 10,
        border: `1px solid ${gray500}`,
        outline: "none",
        boxShadow: shadowMd,
        background: gray600,
      }),

      menuPortal: (provided, state) => ({ ...provided, border: "none" }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused && gray500,
          color: gray100,
          borderRadius: 6,
        };
      },

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        width: "100%",
        display: "flex",
        border: "none",
        backgroundColor: whiteAlpha300,
        height: "32px",
        borderRadius: 6,
        color: gray100,
      }),

      container: (provided, state) => ({
        ...provided,
        width: "100%",
        fontSize: "14px",
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition, color: gray200 };
      },
      input: (provided, state) => ({
        ...provided,
        color: gray100
      }),
    }),
    [shadowMd, gray100, gray600, gray200, gray500, whiteAlpha300]
  );
  return styles;
}

export default LabeledSelectField;
