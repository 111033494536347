import { Spinner, Stack, Text, Link, HStack, Avatar } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { useStudents, StudentDocument } from "src/api/student";
import { CellProps, Column } from "react-table";
import { Link as RouterLink } from "react-router-dom";
import Table from "src/components/Table";

function Component() {
  const { data: students } = useStudents({ remainingSessions: 12, limit: 100 });
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Buổi",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "ID",
        accessor: "number",
      },
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<StudentDocument>) => (
          <Link
            as={RouterLink}
            to={`/student/${row.original._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Avatar size="sm" name={row.original.name} src={row.original.photoUrl} />
              <Text>{row.original.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Phụ huynh",
        accessor: "motherName",
        Cell: ({ row }: CellProps<StudentDocument>) => {
          return (
            <Stack>
              <Text>{row.original.motherName}</Text>
              <Text>{row.original.fatherName}</Text>
            </Stack>
          );
        },
      },
      {
        Header: "Số buổi còn lại",
        accessor: "remainingSessions",
        Cell: ({ value }) => (
          <Text
            py={2}
            fontSize="md"
            fontWeight="bold"
            color={value > 0 ? "teal.300" : "red.300"}
          >
            {value}
          </Text>
        ),
      },
      {
        Header: "Take care",
        accessor: "takeCareBy",
        Cell: ({ value }) => (
          <Link color="blue.300" to={`/user/${value?._id}`} as={RouterLink}>
            <HStack>
              <Avatar size="xs" name={value?.name} />
              <Text>{value?.name}</Text>
            </HStack>
          </Link>
        ),
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <Stack spacing={4}>
      <Table columns={columns} data={students?.docs} />
    </Stack>
  );
}

function Tasks() {
  return (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );
}

export default Tasks;
