import axios from "src/config/axios";
import { useQuery } from "react-query";
import { PaginateDocument } from "../types/PaginateDocument";
import { ObjectId } from "../types/ObjectId";
import { TimeStamp } from "../types/TimeStamp";
import generateQueryKey from "src/utils/queryKeyFactory";

export interface Role {
  value: string;
  label: string;
  color: string;
}

export type RoleDocument = Role & ObjectId & TimeStamp;

export const getRoles = async () => {
  const { data } = await axios.get<PaginateDocument<RoleDocument>>(
    "/roles"
  );
  return data;
};

// hooks
export const leadStatusKeys = generateQueryKey("role");

export const useRoles = () => {
  return useQuery(leadStatusKeys.all, () => getRoles(), {
    staleTime: Infinity,
  });
};
