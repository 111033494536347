import * as React from "react";
import RouteWithSubRoutes from "./components/RouteWithSubRoutes";
import routes from "./routes";
import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Button, VStack, Text, HStack, Container } from "@chakra-ui/react";
import { Switch } from "react-router-dom";
import Login from "./containers/login";

export const App = () => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

function ErrorFallback({ resetErrorBoundary, error }: FallbackProps) {
  const isAuthenticationError = error.message.includes("401");
  return (
    <>
      <Login
        resetErrorBoundary={resetErrorBoundary}
        isOpen={isAuthenticationError}
      />
      {!isAuthenticationError && 
      <Container>
        <VStack mt="52">
          <HStack>
            <Text>There was an error!</Text>
            <Button ml="4" onClick={() => resetErrorBoundary()}>
              Try again
            </Button>
          </HStack>
          <Text>{error.message}</Text>
        </VStack>
      </Container>
      }
    </>
  );
}
