import React, { useEffect } from "react";
import Table from "src/components/Table";
import { abilities } from "src/constants/abilities";
import { keys } from "lodash";
import {
  Box,
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Checkbox,
  useDisclosure,
  Stack,
  CheckboxGroup,
  Avatar,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { AbilityDocument } from "src/api/ability";
import { useAbilities } from "../../api/ability";
import { useParams } from "react-router-dom";
import AbilityScoreNote from "./AbilityScoreNote";
import AbilityModalEdit from "./AbilityModalEdit";
import { IconButton, Icon } from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { drop, map } from "lodash";

const deviations = [
  // "spinalDeviation",
  // "shoulderDeviation",
  // "headDeviation",
  // "hipDeviation",
  // "kneeDeviation",
  // "flatFeet",
];

const isTitle = (value) => {
  const titles = [abilities.title1.label, abilities.title2.label];
  return titles.includes(value);
};

function TableHeader({
  ability,
  onClick,
}: {
  ability: AbilityDocument;
  onClick: () => void;
}) {
  return (
    <>
      <Text
        // onClick={() => onClick?.()}
        _hover={{ color: "blue.400", textDecor: "underline" }}
        cursor="pointer"
      >
        {dayjs(ability.time).format("D/MM/YY")}
      </Text>
      <Text fontWeight="bold">HLV: {ability.createdBy?.name}</Text>
    </>
  );
}

function AbilityTable({ type }: { type: string }) {
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const { id } = useParams<{ id: string }>();
  const { data: abilityData } = useAbilities({
    [type]: id,
    sortBy: "time",
    orderBy: "1",
  });

  const [editingData, setEditingData] = React.useState<AbilityDocument>();
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();

  const firstColumn = {
    Header: "",
    accessor: "ability",
    Cell: ({ value, row }) => {
      const ability = abilities?.[row.original.abilityKey];

      return isTitle(value) ? (
        <Text fontWeight="semibold" fontSize="lg" py={4}>
          {value}
        </Text>
      ) : (
        <HStack>
          <Text py={2}>{value}</Text>
          {ability.scores && (
            <AbilityScoreNote title={ability.label} scores={ability.scores} />
          )}
        </HStack>
      );
    },
  };

  const columns = abilityData?.docs?.map((item) => ({
    Header: () => (
      <TableHeader
        onClick={() => {
          setEditingData(item);
          onOpenEdit();
        }}
        ability={item}
      />
    ),
    accessor: item.time,
  })) || [];

  const rows = keys(abilities).map((ability) => {
    let obj: any = {};
    columns.forEach((column, index) => {
      if (index > 0 && !deviations.includes(ability)) {
        obj[column.accessor] = abilityData?.docs?.[index][ability];
        const difference =
          parseInt(abilityData?.docs?.[index][ability]) -
          parseInt(abilityData?.docs?.[index - 1][ability]);
        if (difference < 0) {
          obj[column.accessor] += ` (${difference})`;
        } else if (difference > 0) {
          obj[column.accessor] += ` (+${difference})`;
        }
      } else {
        obj[column.accessor] =
          abilityData?.docs?.[index][ability] ||
          (deviations.includes(ability) ? "Không" : "");
      }
    });
    obj.ability = abilities[ability].label;
    obj.abilityKey = abilities[ability].key;
    return obj;
  });

  const allColumns = [firstColumn, ...columns];
  const filteredColumns = allColumns.filter((item, index) => {
    if (index === 0) {
      return item;
    }
    return selectedColumns.includes(item.accessor);
  });

  useEffect(() => {
    setSelectedColumns(map(columns, "accessor"));
  }, []);

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <IconButton
            ml={3}
            colorScheme="gray"
            aria-label="filter"
            icon={<Icon as={FiFilter} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Bộ lọc</PopoverHeader>
          <PopoverBody>
            <Stack spacing={3} py={2}>
              <CheckboxGroup
                value={selectedColumns}
                onChange={(v) => setSelectedColumns(v)}
              >
                {drop(allColumns).map((item) => (
                  <Checkbox value={item.accessor} key={item.accessor}>
                    {dayjs(item.accessor).format("D/MM/YY")}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Box my={6} bg="gray.800" p={4} borderRadius="md">
        <AbilityModalEdit
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          editingData={editingData}
          type={type}
        />
        <Table data={rows} columns={filteredColumns} />
      </Box>
    </>
  );
}

export default AbilityTable;
