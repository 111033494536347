import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { Stack, Button } from "@chakra-ui/react";
import { UpdateSkillDto } from "../../api/skill";

interface SkillCategoryFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateSkillDto;
  submitText?: string;
}

function SkillCategoryForm({
  onSubmit,
  formId,
  initialValues,
  submitText = "Thêm",
}: SkillCategoryFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField name="name" label="Tên" required />
            <Button type="submit">{submitText}</Button>
          </Stack>
        </form>
      )}
    />
  );
}

export default SkillCategoryForm;
