import { GlobalStyle, LightMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useTransaction } from "../../api/transaction";
import dayjs from "dayjs";
import { PaymentMethod } from "src/types/PaymentMethod";
import { formatMoney } from "../../utils/format";

function Receipt() {
  const { id } = useParams<{ id: string }>();
  const transaction = useTransaction(id).data;

  useEffect(() => {
    if (transaction) {
      // window.print();
    }
  }, [transaction]);

  return (
    <div style={{ padding: "40px", color: "#000", fontFamily: "sans-serif" }}>
      <Helmet>
        <title>Phiếu thu</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <div>
          <h1 style={{ fontWeight: 600, fontSize: "18px" }}>CEC - CANADIAN ENGLISH COUNCIL </h1>
          Hội đồng Anh ngữ Canada
          <p> www.cec.com.vn</p>
        </div>
        <img src="/cec.png" alt="image" style={{ height: "100px" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "20px" }}>CEC NINJA KIDS</h1>
          <h2
            style={{
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            <p> Trung tâm thể dục dụng cụ dành cho trẻ em</p>
          </h2>
        </div>
        {/* <img style={{ width: "50px" }} src="/ninjakid.png" alt="logo" /> */}
      </div>
      <hr style={{ margin: "15px 0 15px", borderTop: "1px solid #ccc" }} />
      <h2 style={{ fontSize: "24px", textAlign: "center", fontWeight: 600 }}>
        PHIẾU THU
      </h2>
      <hr style={{ margin: "15px 0 8px", borderTop: "1px solid #ccc" }} />
      <table style={{ width: "100%" }} className="padding">
        <tr>
          <td>Học viên</td>
          <td style={{ fontWeight: 600 }}>{transaction?.student?.name}</td>
        </tr>
        <tr>
          <td>Cơ sở</td>
          <td>171 Trần Phú - Hà Đông</td>
        </tr>
        <tr>
          <td>Ngày thanh toán</td>
          <td>{dayjs(transaction?.date).format("DD MMMM, YYYY")}</td>
        </tr>
        <tr>
          <td>Ngày tạo phiếu</td>
          <td>{dayjs().format("HH:mm, DD MMMM, YYYY")}</td>
        </tr>
        <tr>
          <td>Trung tâm</td>
          <td>{transaction?.center?.name}</td>
        </tr>
        <tr>
          <td>Nội dung</td>
          <td>{transaction?.note}</td>
        </tr>
        <tr style={{ borderTop: "1px solid #ccc" }}>
          <td>Hình thức</td>
          <td>
            {transaction?.method === PaymentMethod.Cash
              ? "Tiền mặt"
              : "Chuyển khoản"}
          </td>
        </tr>
        <tr>
          <td>Số tiền thu</td>
          <td style={{ fontWeight: 600, fontSize: "20px" }}>
            {formatMoney(transaction?.amount || 0)}
          </td>
        </tr>
      </table>
      <hr style={{ margin: "5px 0 15px", borderTop: "1px solid #ccc" }} />
      <div
        style={{
          fontSize: "14px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p>CS1: Tầng 6, số 12 Khúc Thừa Dụ, Cầu Giấy, Hà Nội</p>
          <p>CS2: Tầng 4, số 171 Trần Phú, Hà Đông </p>
        </div>
        <div>
          <p>SĐT & Zalo (Phan): 0819.020.796 </p>
          <p>Trực lễ tân: 0357.374.171 </p>
        </div>
      </div>
      <div
        style={{
          fontSize: "14px",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
          marginBottom: "100px",
          paddingRight: "100px"
        }}
      >
        <div style={{
          textAlign: "center"
        }}>
          <p>NGƯỜI LẬP PHIẾU</p>
          <p>(kí, ghi rõ họ tên)</p>
        </div>

      </div>
      <div
        style={{
          fontSize: "12px",
        }} >
        <b>Quyền lợi về đảm bảo buổi tập của học viên: </b>
        <br />
        * Trong trường hợp học viên không đi tập, phụ huynh vui lòng báo với HLV trước buổi tập diễn ra để được miễn điểm danh, không tính buổi tập của học viên.
        <br />
      </div>
    </div>
  );
}

function ReceiptWrapper() {
  return (
    <React.Suspense fallback="...loading">
      <Receipt />
    </React.Suspense>
  );
}

function TransactionReceipt() {
  return (
    <LightMode>
      <GlobalStyle />
      <ReceiptWrapper />
    </LightMode>
  );
}

export default TransactionReceipt;
