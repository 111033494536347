import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
  IconButton,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import FilterClearButton from "src/components/FilterClearButton";
import TimeSheetTable from "./TimeSheetTable";
import TimeSheetPaging from "./TimeSheetPaging";
import FilterByUser from "src/components/FilterByUser";
import FilterByTime from "src/components/FilterByTime";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import { CSVLink } from "react-csv";
import useSearchParams from "../../utils/useSearchParams";
import { useTimeSheets } from "../../api/timeSheet";
import dayjs from "dayjs";
import { DownloadIcon } from "@chakra-ui/icons";

function TimeSheet() {
  const { params } = useSearchParams();
  const { data } = useTimeSheets({
    ...params,
    orderBy: "-1",
    page: 1,
    limit: 10000,
  });
  let csvData = [[]] as any;
  if (data?.docs) {
    csvData = data?.docs.map((d) => {
      const obj = {} as any;
      obj.name = d.user.name;
      obj.checkIn = dayjs(d.checkIn).format("LT");
      obj.checkOut = d.checkOut ? dayjs(d.checkOut).format("LT") : "";
      obj.date = dayjs(d.dayOfMonth).format("LL");
      obj.device = d.device?.secret_code;
      return obj;
    });
  }
  console.log(csvData);

  return (
    <>
      {/* <TimeSheetModalAdd isOpen={isOpen} onClose={onClose} /> */}
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Chấm công
          </Heading>
          <Spacer />
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByUser name="user" label="Nhân viên" />
                <FilterByTime name="dayOfMonth" label="Thời gian" />
                <FilterByCenterWrapper />
                <FilterClearButton />
              </HStack>
              <Spacer />
              <CSVLink data={csvData} filename={"time-sheet.csv"}>
                <IconButton
                  mr={8}
                  colorScheme="blue"
                  aria-label="Search database"
                  icon={<DownloadIcon />}
                />
              </CSVLink>

              <TimeSheetPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <TimeSheetTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

function TimeSheetWrapper() {
  return (
    <Suspense fallback="loading...">
      <TimeSheet />
    </Suspense>
  );
}

export default TimeSheetWrapper;
