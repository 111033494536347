import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { Stack } from "@chakra-ui/react";
import { UpdateTransactionDto } from "../../api/transaction";
import LabeledSelectField from "../../components/LabeledSelectField";
import LabeledSelectUser from "../../components/LabeledSelectUser";
import { PaymentMethod } from "src/types/PaymentMethod";
import LabeledTextareaField from "src/components/LabeledTextareaField";
import { formatMoney } from "../../utils/format";

interface TransactionUpdateFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateTransactionDto;
}

function TransactionUpdateForm({
  onSubmit,
  formId,
  initialValues,
}: TransactionUpdateFormProps) {
  const methods = [
    { value: PaymentMethod.Bank, label: PaymentMethod.Bank },
    { value: PaymentMethod.Cash, label: PaymentMethod.Cash },
  ];

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField
              autoComplete="off"
              type="number"
              name="commission"
              label="Commission"
              helperText={formatMoney(values.commission || 0)}
            />
            <LabeledTextField
              autoComplete="off"
              name="amount"
              label="Số tiền (VNĐ)"
              required
              type="number"
              helperText={formatMoney(values.amount || 0)}
            />
            <LabeledTextField name="date" label="Ngày giao dịch" type="date" />
            <LabeledSelectUser name="sale" label="Sale" />
            <LabeledSelectField
              options={methods}
              name="method"
              label="Phương thức thanh toán"
            />
            <LabeledTextareaField
              name="note"
              label="Ghi chú"
              placeholder="Ghi rõ nội dung đóng tiền"
              required
            />
          </Stack>
        </form>
      )}
    />
  );
}

export default TransactionUpdateForm;
