import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import EventForm from "./EventForm";
import {
  CreateEventDto,
  eventKeys,
  updateEvent,
  UpdateEventDto,
  useCancelEvent,
} from "../../api/event";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { EventDocument } from "../../api/event";
import dayjs from "dayjs";
import { pick } from "lodash";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-event";

interface EventModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: EventDocument;
  setInitialFormValues?: (data: UpdateEventDto) => void;
  onOpenAddForm?: () => void;
}

function EventModalEdit({
  isOpen,
  onClose,
  editingData,
  setInitialFormValues,
  onOpenAddForm,
}: EventModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const cancelEvent = useCancelEvent(onClose);
  const initialValues: CreateEventDto =
    editingData &&
    pick(
      {
        ...editingData,
        inCharge: editingData?.inCharge?._id,
        center: editingData?.center?._id,
        category: editingData?.category?._id,
      },
      [
        "name",
        "description",
        "start",
        "end",
        "inCharge",
        "center",
        "category",
        "isDisable",
      ]
    );

  const onSubmit = async (values: CreateEventDto) => {
    try {
      await updateEvent(editingData._id, values);
      onClose();
      toast({ status: "success", description: "Sửa thành công" });
      queryClient.invalidateQueries(eventKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  const duplicateEvent = () => {
    onClose();
    onOpenAddForm?.();
    setInitialFormValues?.({
      ...initialValues,
      start: dayjs(initialValues.start).add(7, "d").toISOString(),
      end: dayjs(initialValues.end).add(7, "d").toISOString(),
      isDisable: false,
    });
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EventForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
          {/* <Button
            onClick={() => archiveEvent(editingData)}
            mt={4}
            colorScheme="red"
            variant="outline"
            mr={3}
          >
            Xóa sự kiện
          </Button> */}
          {initialValues?.isDisable ? (
            <Button
              mt={4}
              colorScheme="yellow"
              variant="outline"
              mr={3}
              disabled
            >
              Đã hủy
            </Button>
          ) : (
            <Button
              isLoading={cancelEvent.isLoading}
              onClick={() => {
                if (
                  window.confirm(
                    `Bạn có chắc muốn hủy sự kiện ${editingData.name} không?`
                  )
                ) {
                  cancelEvent.mutate(editingData._id);
                }
              }}
              mt={4}
              colorScheme="yellow"
              variant="outline"
              mr={3}
            >
              Hủy sự kiện
            </Button>
          )}
          <Button
            onClick={duplicateEvent}
            mt={4}
            colorScheme="teal"
            variant="outline"
            mr={3}
          >
            Tạo bản sao
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EventModalEdit;
