import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React from "react";
import FilterBySearchKey from "src/components/FilterBySearchKey";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import FilterByUpdated from "src/components/FilterByUpdated";
import FilterClearButton from "src/components/FilterClearButton";
import { AddIcon } from "@chakra-ui/icons";
import StudentTable from "./StudentTable";
import StudentModalAdd from "./StudentModalAdd";
import StudentPaging from "./StudentPaging";
import FilterByStatus from "src/components/FilterByStatus";
import FilterByUser from "src/components/FilterByUser";
import SortByCreatedTime from "src/components/SortByCreatedTime";
import { useStudents } from "src/api/student";
import useSearchParams from "../../utils/useSearchParams";
import { formatMoney } from "../../utils/format";
import FilterByPageSize from "src/components/FilterByPageSize";
import StudentDownload from "./StudentDownload";
function Student() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let { params } = useSearchParams();
  // console.log("🚀 ~ file: index.tsx:32 ~ Student ~ params:", params)
  const { data } = useStudents(params);

  return (
    <>
      <StudentModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Danh sách học viên
          </Heading>
          <Spacer />
          <Text
            py={2}
            px={4}
            fontSize="sm"
            bg="gray.800"
            borderRadius="md"
            color="teal.200"
          >
            Total balance:{" "}
            <Text as="b">{formatMoney(data.totalBalance || 0)}</Text>
          </Text>
          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Thêm học viên
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByStatus />
                <FilterBySearchKey placeholder="Tên hoặc mã học viên" />
                <FilterByCenterWrapper />
                <FilterByUpdated />
                <FilterByUser />
                <SortByCreatedTime />
                <FilterClearButton />
              </HStack>
              <Spacer />
              <StudentDownload/>
              <FilterByPageSize />
              <StudentPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <StudentTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export function StudentWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <Student />
    </React.Suspense>
  );
}

export default StudentWrapper;
