import React from "react";

import { HStack } from "@chakra-ui/react";
import FilterByLeadStatus from "./FilterByLeadStatus";
import FilterBySearchKey from "src/components/FilterBySearchKey";
import FilterByUpdated from "src/components/FilterByUpdated";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import FilterClearButton from "src/components/FilterClearButton";
import FilterByStatus from "src/components/FilterByStatus";
import FilterByUser from "src/components/FilterByUser";
import SortByCreatedTime from "src/components/SortByCreatedTime";

function FilterLead() {
  return (
    <HStack>
      <FilterByStatus />
      <FilterBySearchKey placeholder="Tên hoặc số điện thoại" />
      <FilterByUser name="takeCareBy" label="Take Care" />
      <FilterByLeadStatus />
      <FilterByCenterWrapper />
      <FilterByUpdated />
      <SortByCreatedTime />

      <FilterClearButton />
    </HStack>
  );
}

export default FilterLead;
