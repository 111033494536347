import {
  HStack,
  useCheckboxGroup,
  Center,
  useCheckbox,
  Box,
  FormLabel,
} from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";
import { useField } from "react-final-form";

function CheckboxCard(props: any) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const { onChange, ...input } = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input
        type="number"
        onChange={(e) => onChange?.(parseInt(e.target.value))}
        {...input}
      />
      <Center
        {...checkbox}
        _checked={{
          bg: "blue.700",
          color: "white",
          borderColor: "blue.700",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        bg="whiteAlpha.100"
        borderRadius="full"
        fontSize="xs"
        minW={8}
        minH={8}
        cursor="pointer"
      >
        {props.children}
      </Center>
    </Box>
  );
}

interface LabeledWeekdaysSelectProps {
  name: string;
  label: string;
}

function LabeledWeekdaysSelect({ name, label }: LabeledWeekdaysSelectProps) {
  const { input } = useField(name);

  const { getCheckboxProps } = useCheckboxGroup({
    value: input.value || [],
    onChange: input.onChange,
  });

  const options = dayjs.weekdaysShort();

  return (
    <Box>
      <FormLabel fontSize="sm" mb="1.5">
        {label}
      </FormLabel>
      <HStack>
        {options.map((option, index) => {
          const value = index;
          const checkbox = getCheckboxProps({ value });
          return (
            <CheckboxCard key={value} {...checkbox}>
              {option}
            </CheckboxCard>
          );
        })}
      </HStack>
    </Box>
  );
}

export default LabeledWeekdaysSelect;
