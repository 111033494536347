import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { Stack } from "@chakra-ui/react";
import { UpdateSkillDto } from "../../api/skill";
import LabeledNumberField from "../../components/LabeledNumberField";
import LabeledSelectSkillCategory from "../../components/LabeledSelectSkillCategory";

interface SkillFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateSkillDto;
}

function SkillForm({ onSubmit, formId, initialValues }: SkillFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField name="name" label="Tên" required />
            <LabeledSelectSkillCategory name="category" label="Loại" required />
            <LabeledNumberField
              name="star"
              label="Sao (1-5)"
              required
              isShowStepper
              min={1}
              max={5}
            />
            <LabeledTextField
              name="video"
              label="Video (Link youtube)"
              required
            />
          </Stack>
        </form>
      )}
    />
  );
}

export default SkillForm;
