import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import LabeledSelectField from "../../components/LabeledSelectField";
import { useCenters } from "../../api/center";
import { Stack } from "@chakra-ui/react";
import { UpdateClassroomDto } from "../../api/classroom";
import LabeledSelectUser from "../../components/LabeledSelectUser";
import { formatMoney } from "src/utils/format";

interface ClassroomFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateClassroomDto;
}

function ClassroomForm({
  onSubmit,
  formId,
  initialValues,
}: ClassroomFormProps) {
  const { data: centers } = useCenters();
  const centerOptions = (centers?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField required name="name" label="Tên" />
            <LabeledSelectField
              options={centerOptions}
              name="center"
              label="Trung tâm"
            />
            <LabeledSelectUser name="coach" label="Coach" />
            <LabeledTextField
              name="salary"
              type="number"
              label="Lương dạy"
              helperText={formatMoney(values.salary)}
            />
          </Stack>
        </form>
      )}
    />
  );
}

export default ClassroomForm;
