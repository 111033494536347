import React, { Suspense, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useStudents } from "../../api/student";
import StudentItem from "./StudentItem";
import { useParams } from "react-router-dom";
import { useClassroom } from "src/api/classroom";

function Students({ search }: { search: string }) {
  const { data: students } = useStudents({ limit: 10, search: search });
  return (
    <Stack spacing={0} minH="32">
      {students?.docs?.map((student) => (
        <StudentItem student={student} key={student._id} />
      ))}
    </Stack>
  );
}

function StudentAddModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const [search, setSearch] = useState("");
  const { id } = useParams<{ id: string }>();
  const { data: classroom } = useClassroom(id);

  return (
    <>
      <Button
        w="full"
        size="md"
        fontSize="sm"
        mb={1}
        onClick={onOpen}
        justifyContent="start"
        leftIcon={<AddIcon />}
        variant={"ghost"}
      >
        Thêm học viên
      </Button>
      <Modal
        scrollBehavior="inside"
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thêm học viên vào {classroom?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              ref={initialRef}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              size="lg"
              mb={2.5}
              placeholder="Tìm theo mã học viên hoặc tên"
            />
            <Suspense fallback={<Spinner />}>
              <Students search={search} />
            </Suspense>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default StudentAddModal;
