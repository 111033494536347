import { Route } from "react-router-dom";
import React from "react";
import { RouteConfigItem } from "src/routes";

function RouteWithSubRoutes(route: RouteConfigItem) {
  return (
    <Route
      path={route.path}
      render={(props) => {
        return (
          <route.layout>
            <route.component {...props} routes={route.routes} />
          </route.layout>
        );
      }}
    />
  );
}

export default RouteWithSubRoutes;
