import axios from "src/config/axios";
import { useQuery } from "react-query";
import { ObjectId } from "src/types/ObjectId";
import { Status } from "src/types/Status";
import { TimeStamp } from "src/types/TimeStamp";
import generateQueryKey from "src/utils/queryKeyFactory";
import { PaginateDocument } from "../types/PaginateDocument";

export const classCategoryKeys = generateQueryKey("classCategory")
export interface ClassCategoryDto {
  _id?: string;
  name?: string;
  price?: number;
  salaryByLesson?: number;
  numberOfLesson?: number;
  status?: Status;
}

export type ClassCategoryDocument = ClassCategoryDto & TimeStamp & ObjectId;
export interface GetClassCategory {
  status?: Status
}

export const createClassCategory = async (createData: ClassCategoryDto) => {
  const { data } = await axios.post("/class-category", createData);
  return data;
};

export const getClassCategories = async (params?: GetClassCategory) => {
  const { data } = await axios.get("/class-category", { params });
  return data;
};

export const updateClassCategory = async (id: string, updateData: ClassCategoryDto) => {
  const { data } = await axios.patch(`/class-category/${id || ""}`, updateData);
  return data;
};

export const useClassCategories = (params?: GetClassCategory) => {
  return useQuery<PaginateDocument<ClassCategoryDocument>>(classCategoryKeys.list(params), () =>
    getClassCategories(params)
  );
};
