import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import { forwardRef, PropsWithoutRef } from "react";
import { useField } from "react-final-form";
export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number";
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  autoFocus?: boolean;
}

export const LabeledTextareaField = forwardRef<
  HTMLTextAreaElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      placeholder,
      type,
      helperText,
      disabled,
      autoFocus,
      ...props
    },
    ref
  ) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name, {
      parse: type === "number" ? Number : undefined,
    });
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb="1.5">
          {label}
        </FormLabel>
        <Textarea
          autoFocus={autoFocus}
          fontWeight="medium"
          fontSize="sm"
          {...input}
          disabled={submitting || disabled}
          ref={ref}
          placeholder={placeholder}
          {...props}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default LabeledTextareaField;
