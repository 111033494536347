import React, { Suspense, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Icon,
  Tooltip,
  HStack,
  Button,
  Text,
  Badge,
  useToast,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import {
  useUsers,
  UserDocument,
  updateUser,
  UserDto,
  createUser,
  userKeys,
} from "../../api/user";
import Table from "src/components/Table";
import { CellProps, Column } from "react-table";
import ModalEditUser from "./ModalEditUser";
import dayjs from "dayjs";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdRestore } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import UserModal from "./UserModal";
import { useQueryClient } from "react-query";
import { Status } from "src/types/Status";
import { Link as RouterLink } from "react-router-dom";
import queryString from "query-string";

const thisMonth = {
  startDate: dayjs().startOf("month").toISOString(),
  endDate: dayjs().endOf("month").toISOString(),
  dateKey: "createdAt",
};

function Users() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const usersData = useUsers();
  const users = usersData?.data?.docs || [];
  const [editingData, setEditingData] = useState<UserDocument>();
  const {
    isOpen: isOpenAdd,
    onClose: onCloseAdd,
    onOpen: onOpenAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();

  const editUser = React.useCallback(
    (user: UserDocument) => {
      onOpenEdit();
      const updateUserDto = {
        ...user,
      };
      setEditingData(updateUserDto);
    },
    [onOpenEdit]
  );

  const creatUser = async (values: UserDto) => {
    try {
      await createUser({ ...values, roles: [values.roles] });
      console.log("values: ", values);
      toast({ status: "success", description: "Thành công!", position: "top" });
      queryClient.invalidateQueries(userKeys.all);
      // onClose()
    } catch (error) {
      toast({ status: "error", description: "Lỗi!", position: "top" });
    }
  };

  const archiveUser = async (user: UserDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${user.name}`)) {
        await updateUser(user._id, { status: Status.Inactive });
      }
    } catch (error) {}
  };

  const restoreUser = async (user: UserDocument) => {
    try {
      if (window.confirm(`Khôi phục ${user.name}`)) {
        await updateUser(user._id, { status: Status.Active });
      }
    } catch (error) {}
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<UserDocument>) => (
          <Link
            as={RouterLink}
            to={`/user/${row.original._id}?${queryString.stringify(thisMonth)}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {row.original.name}
          </Link>
        ),
      },
      { Header: "Username", accessor: "username" },
      { Header: "Phone", accessor: "phone" },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Vai trò",
        accessor: "roles",
        Cell: ({ value }) => (
          <Badge colorScheme={value?.color}>{value[0]?.label}</Badge>
        ),
      },
      {
        Header: "Cập nhật",
        accessor: "updatedAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<UserDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveUser(row.original);
                    } else {
                      restoreUser(row.original);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editUser(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ],
    [editUser]
  );

  return (
    <Flex direction="column" p={6} h="full">
      <UserModal isOpen={isOpenAdd} onClose={onCloseAdd} onSubmit={creatUser} />
      <ModalEditUser
        initialValues={editingData}
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
      />
      <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
        Nhân viên
      </Heading>
      <Flex mb={4}>
        <InputGroup w="xs">
          <InputLeftElement
            pointerEvents="none"
            children={<BiSearch color="gray.400" />}
          />
          <Input bg="gray.800" type="text" placeholder="Tìm kiếm lớp học" />
        </InputGroup>
        <Spacer />
        <Button onClick={onOpenAdd}>Thêm người dùng</Button>
      </Flex>
      <Box overflow="auto" flex={1} px={5} borderRadius="md" bg="gray.800">
        <Table columns={columns} data={users} />
      </Box>
    </Flex>
  );
}

function UsersWrapper() {
  return (
    <Suspense fallback="loading...">
      <Users />
    </Suspense>
  );
}

export default UsersWrapper;
