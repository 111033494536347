import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import TransactionForm from "./TransactionForm";
import {
  CreateTransactionDto,
  createTransaction,
  transactionKeys,
} from "../../api/transaction";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { PaymentMethod } from "src/types/PaymentMethod";
import { useParams } from "react-router";
import { useStudent } from "../../api/student";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-transaction";

interface TransactionModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function TransactionModalAdd({ isOpen, onClose }: TransactionModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { data: student } = useStudent(id);

  const onSubmit = async (
    values: CreateTransactionDto & { mark: "string" }
  ) => {
    try {
      onClose();
      await createTransaction({
        ...values,
        student: id,
        center: student?.center?._id || "",
        amount: parseInt((values.mark || "") + values.amount),
      });
      toast({ status: "success", description: "Thêm giao dịch thành công" });
      queryClient.invalidateQueries(transactionKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thêm giao dịch</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TransactionForm
            initialValues={{ method: PaymentMethod.Bank }}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Thêm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TransactionModalAdd;
