import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import {
  Tooltip,
  Text,
  Link,
  HStack,
  Avatar,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { formatMoney } from "../../utils/format";
import { Link as RouterLink } from "react-router-dom";
import {
  useTransactions,
  TransactionDocument,
  useDeleteTransaction,
} from "../../api/transaction";
import { RiDeleteBinLine } from "react-icons/ri";
import { useCurrentUser } from "../../api/auth";
import { Role } from "src/types/Role";
import { map } from "lodash";
import { FiEdit } from "react-icons/fi";
import { useDisclosure } from "@chakra-ui/react";
import TransactionUpdateModal from "./TransactionUpdateModal";

function TransactionTable() {
  const [editingData, setEditingData] = useState<TransactionDocument>();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { params } = useSearchParams();
  const { data } = useTransactions({ ...params, orderBy: "-1" });
  const deleteTransaction = useDeleteTransaction();
  const userRoles = map(useCurrentUser().data?.roles || [], "value");

  const editTransaction = (v: TransactionDocument) => {
    setEditingData(v);
    onOpen();
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Số tiền",
        accessor: "amount",
        Cell: ({ value }) => (
          <Text
            py={2}
            fontSize="md"
            fontWeight="bold"
            color={value >= 0 ? "teal.300" : "red.300"}
          >
            {value >= 0 && "+"}
            {formatMoney(value)}
          </Text>
        ),
      },
      {
        Header: "Học viên",
        accessor: "student",
        Cell: ({ value }: CellProps<TransactionDocument>) => (
          <Link
            as={RouterLink}
            to={`/student/${value?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {value?.name}
          </Link>
        ),
      },
      {
        Header: "Sale",
        accessor: "sale",
        Cell: ({ value }) => (
          <Link
            color="blue.300"
            to={`/user/${value?._id}`}
            as={RouterLink}
          >
            <HStack>
              <Avatar size="xs" name={value?.name} />
              <Text>{value?.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Commission",
        accessor: "commission",
        Cell: ({ value }) => (value ? formatMoney(value) : ""),
      },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Thanh toán",
        accessor: "method",
      },
      {
        Header: "Ghi chú",
        accessor: "note",
      },
      {
        Header: "Ngày giao dịch",
        accessor: "date",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).format("L")}
          </Tooltip>
        ),
      },
      {
        Header: "Ngày tạo",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).format("L")}
          </Tooltip>
        ),
      },
      {
        id: "action",
        Header: userRoles.includes(Role.SuperAdmin) && "Hành động",
        Cell: ({ row }: CellProps<TransactionDocument>) => {
          return userRoles.includes(Role.SuperAdmin) ? (
            <HStack spacing={4}>
              <Tooltip label="Xóa">
                <IconButton
                  icon={<Icon boxSize={4} as={RiDeleteBinLine} />}
                  variant="ghost"
                  colorScheme="red"
                  aria-label="delete"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Bạn có chắc muốn xóa giao dịch [${row.original?.student?.name
                        }: ${formatMoney(row.original.amount)}]`
                      )
                    ) {
                      deleteTransaction.mutate(row.original?._id);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="edit"
                  onClick={() => editTransaction(row.original)}
                />
              </Tooltip>
            </HStack>
          ) : null;
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      {editingData && (
        <TransactionUpdateModal
          isOpen={isOpen}
          onClose={onClose}
          editingData={editingData}
        />
      )}
      <Table columns={columns} data={data?.docs || []} />
    </>
  );
}

function TransactionTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <TransactionTable />
    </Suspense>
  );
}

export default TransactionTableWrapper;
