import axios from "src/config/axios";
import { dropRight, takeRight } from "lodash";
import { useMutation } from "react-query";
import slugify from "slugify";
import { nanoid } from "nanoid";
import rawAxios from "axios";

interface PresignedResponse {
  url: string;
  signedRequest: string;
}

const rawAxiosInstance = rawAxios.create({
  baseURL: process.env.REACT_APP_API,
});

const handleUpload = async (file: File) => {
  let fileParts = file.name.split(".");
  let fileName = slugify(dropRight(fileParts).join(".")) + nanoid();
  let fileType = takeRight(fileParts)[0];

  const res = await axios.post<PresignedResponse>("/aws/presigned-url", {
    fileName,
    fileType,
  });
  const url = res.data.url;
  const signedRequest = res.data.signedRequest;

  await rawAxiosInstance.put(signedRequest, file, {
    headers: {
      "content-type": fileType,
    },
  });
  return url;
};

export function useUploadImage() {
  return useMutation(handleUpload);
}
