import React, { useState } from "react";
import {
  Container,
  Button,
  Avatar,
  VStack,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  ListItem,
  UnorderedList,
  Input,
  Spinner,
  Center,
  AvatarProps,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useField } from "react-final-form";
import { useUploadImage } from "src/api/aws";

export interface LabeledAvatarFieldProps {
  name: string;
  label?: string;
  avatarProps?: AvatarProps;
  submitOnClose?: boolean;
}

function LabeledAvatarField({
  name,
  avatarProps,
  submitOnClose,
}: LabeledAvatarFieldProps) {
  const ref = React.useRef(null);
  const {
    input,
    meta: { touched, error, submitError, submitting },
  } = useField(name);
  const normalizedError = Array.isArray(error)
    ? error.join(", ")
    : error || submitError;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModal = () => {
    input.onBlur();
    onClose();
  };

  const uploadImage = useUploadImage();

  const [file, setFile] = useState<File>();
  const previewImage = file ? URL.createObjectURL(file) : "";
  const cropperRef = React.useRef<HTMLImageElement>(null);

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e?.target?.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSaveImage = async () => {
    try {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;

      cropper.getCroppedCanvas().toBlob(async (blob: Blob) => {
        try {
          const uploadFile = new File([blob], file.name);
          console.log("🚀 ~ file: LabeledAvatarField.tsx:78 ~ cropper.getCroppedCanvas ~ uploadFile", uploadFile)
          const url = await uploadImage.mutateAsync(uploadFile);
          input.onChange(url);
          closeModal();
          if (submitOnClose) {
            ref?.current?.click();
          }
        } catch (error) {}
      });
    } catch (error) {}
  };

  return (
    <>
      <Button display="none" type="submit" ref={ref}>
        Hidden Button
      </Button>
      <VStack spacing={1} px={6}>
        <Avatar mb={4} size="2xl" src={input.value || ""} {...avatarProps} />
        <Button
          onClick={onOpen}
          leftIcon={<AddIcon />}
          size="sm"
          variant="outline"
          mb={2}
          isDisabled={submitting}
        >
          {input.value ? "Edit Photo" : "Upload Photo"}
        </Button>
        {touched && normalizedError && (
          <Text color="red.500" fontSize="sm">
            {normalizedError}
          </Text>
        )}
      </VStack>
      <Modal
        preserveScrollBarGap
        size="xl"
        isOpen={isOpen}
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody pos="relative">
            <Center
              pos="absolute"
              zIndex={10}
              bg="whiteAlpha.800"
              w="full"
              h="full"
              left={0}
              top={0}
              display={uploadImage.isLoading ? "flex" : "none"}
            >
              <Spinner size="lg" />
            </Center>
            <Box bg="gray.100" mb={6} height="350px">
              <Cropper
                src={!uploadImage.isLoading && previewImage}
                style={{ height: 350, width: "100%" }}
                initialAspectRatio={1}
                aspectRatio={1}
                viewMode={1}
                guides={false}
                ref={cropperRef}
                zoomable={false}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={0.8}
              />
            </Box>
            {uploadImage.isError && (
              <Text color="red.500" textAlign="center" mb={5}>
                Error!
              </Text>
            )}
            <Container maxW="360px">
              <VStack spacing={10}>
                <Button
                  htmlFor="select-image"
                  as="label"
                  w="full"
                  variant="outline"
                >
                  Select file
                </Button>
                <Input
                  display="none"
                  id="select-image"
                  type="file"
                  onChange={handleSelectFile}
                  accept="image/*"
                />
                <Box w="full">
                  <Text fontWeight="bold" mb={10}>
                    Your photo should:
                  </Text>
                  <UnorderedList>
                    <ListItem>Be a close-up of your face</ListItem>
                    <ListItem>Show your face clearly - no sunglasses!</ListItem>
                    <ListItem>Be clear and crisp</ListItem>
                    <ListItem>Have a neutral background</ListItem>
                  </UnorderedList>
                </Box>
              </VStack>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeModal}>
              Cancel
            </Button>
            <Button colorScheme="blackAlpha" onClick={handleSaveImage}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LabeledAvatarField;
