import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import { SkillDocument, useSkills } from "../../api/skill";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import {
  Tooltip,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { FiEdit } from "react-icons/fi";
import SkillModalEdit from "./SkillModalEdit";

function SkillTable() {
  const { params } = useSearchParams();
  const { data } = useSkills(params);
  const [editingData, setEditingData] = useState<SkillDocument>({});

  const { isOpen, onClose, onOpen } = useDisclosure();

  const editSkill = (skill: SkillDocument) => {
    onOpen();
    setEditingData(skill);
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        accessor: "name",
      },
      {
        Header: "Sao",
        accessor: "star",
      },
      {
        Header: "Loại",
        accessor: "category.name",
      },
      {
        Header: "Video",
        Cell: ({ row }: CellProps<SkillDocument>) => {
          return (
            <Link color="blue.500" target="_blank" href={row.original.video}>
              Video
            </Link>
          );
        },
      },
      {
        Header: "Cập nhật",
        accessor: "updatedAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Tạo",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<SkillDocument>) => {
          return (
            <HStack spacing={4}>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editSkill(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      <SkillModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
      />
      <Table columns={columns} data={data?.docs || []} />
    </>
  );
}

function SkillTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SkillTable />
    </Suspense>
  );
}

export default SkillTableWrapper;
