import React from "react";
import {
  HStack,
  Stack,
  Avatar,
  Text,
  IconButton,
  Spacer,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  PopoverArrow,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { useParams } from "react-router";
import {
  AddStudentDto,
  ClassStudentDocument,
  useAddClassroomStudent,
  useDeleteClassroomStudent,
  useClassroomStudents,
} from "../../api/classroom";
import { Form } from "react-final-form";
import LabeledTextField from "src/components/LabeledTextField";
import { formatMoney } from "src/utils/format";
import StudentItemEditModal from "./StudentItemEditModal";
import { Link as RouterLink } from "react-router-dom";

export function StudentItem({
  student,
  isShowDelete,
}: {
  student: ClassStudentDocument;
  isShowDelete?: boolean;
}) {
  const { id } = useParams<{ id: string }>();
  const addClassroomStudent = useAddClassroomStudent(id);
  const deleteClassroomStudent = useDeleteClassroomStudent(id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: students } = useClassroomStudents(id, { limit: 10000 });
  const isAdded =students?.docs?.find((item) => item._id === student._id) || false;

  return (
    <HStack
      fontSize="sm"
      _hover={{ bg: "whiteAlpha.100" }}
      p={2.5}
      borderRadius="md"
    >
      <Link as={RouterLink} to={`/student/${student._id}`}>
        <Avatar size="sm" name={student.name} src={student?.photoUrl} />
      </Link>

      <Box>
        <HStack>
          <Text as="b">{student.number}. {student.name}</Text>
        </HStack>
        <HStack>
          <Text color="blue.500" fontSize="xs">
            {formatMoney(student?.feePerSession || 0)}
          </Text>
          <Text
            py={2}
            fontSize="xs"
            color={student.remainingSessions > 8 ? "teal.300" : "red.300"}
          >
            {student.remainingSessions} buổi
          </Text>

        </HStack>

      </Box>
      <Spacer />
      {isShowDelete ? (
        <HStack>
          <StudentItemEditModal initialValues={student} />
          <IconButton
            onClick={() => {
              if (
                window.confirm(
                  `Bạn có chắc muốn xóa học viên ${student.name} không?`
                )
              ) {
                deleteClassroomStudent.mutate({ studentId: student._id });
              }
            }}
            icon={<DeleteIcon />}
            colorScheme="gray"
            variant="ghost"
            aria-label="add"
          />
        </HStack>
      ) : isAdded ? (
        <Box px={2}>
          <CheckIcon color="green.300" />
        </Box>
      ) : (
        <Popover
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
        >
          <PopoverTrigger>
            <IconButton
              onClick={onOpen}
              icon={<AddIcon />}
              colorScheme="gray"
              aria-label="add"
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Form
                initialValues={{ feePerSession: student.feePerSession }}
                onSubmit={(e: AddStudentDto) => {
                  addClassroomStudent.mutate({
                    student: student._id,
                    feePerSession: e.feePerSession,
                  });
                }}
                render={({ handleSubmit, values }) => (
                  <Stack py={2} as="form" onSubmit={handleSubmit}>
                    <LabeledTextField
                      min={0}
                      type="number"
                      name="feePerSession"
                      label="Học phí mỗi buổi"
                      helperText={formatMoney(values.feePerSession || 0)}
                    />
                    <Button type="submit" isLoading={addClassroomStudent.isLoading} >Thêm</Button>
                  </Stack>
                )}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
}

export default StudentItem;
