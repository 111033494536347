import { isNumber } from "lodash";

export const formatMoney = (value: number) => {
  if(typeof value === "string") {
    value = parseFloat(value);
  }
  if (!isNumber(value)) return "Invalid";

  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(value);
};
