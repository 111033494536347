import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import SkillForm from "./SkillForm";
import { CreateSkillDto, skillKeys, updateSkill } from "../../api/skill";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { SkillDocument } from "../../api/skill";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-skill";

interface SkillModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: SkillDocument;
}

function SkillModalEdit({ isOpen, onClose, editingData }: SkillModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateSkillDto) => {
    try {
      await updateSkill(editingData._id, {
        ...values,
      });
      onClose();
      toast({ status: "success", description: "Sửa thành công" });
      queryClient.invalidateQueries(skillKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SkillForm
            initialValues={
              editingData && {
                ...editingData,
                category: editingData?.category?._id,
              }
            }
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SkillModalEdit;
