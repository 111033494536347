import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import {
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { UpdateAbilityDto } from "../../api/ability";
import LabeledNumberField from "../../components/LabeledNumberField";
import LabeledTextareaField from "src/components/LabeledTextareaField";
import LabeledSelectUser from "src/components/LabeledSelectUser";

interface AbilityFormProps {
  onSubmit: (e: any) => void;
  formId?: string;
  initialValues?: UpdateAbilityDto;
}

function AbilityForm({ onSubmit, formId, initialValues }: AbilityFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <SimpleGrid columns={1} spacing={8}>
              <Stack spacing={3}>
                <LabeledTextField
                  required
                  name="time"
                  label="Ngày đo *"
                  type="date"
                />
                <LabeledNumberField required name="height" label="Chiều cao (cm)" />
                <LabeledNumberField required name="weight" label="Cân nặng (kg)" />
                <LabeledSelectUser  name="createdBy" label="Người tạo" />
                <LabeledTextareaField required minLength={30} name="review" label="Nhận xét"  placeholder="Viết nhận xét để báo cáo cho phụ huynh "/>
                <LabeledTextareaField required minLength={30} name="goal" label="Mục tiêu 45 ngày tới" placeholder="Mục tiêu mà HLV và học viên hướng tới " />
              </Stack>
            </SimpleGrid>
          </Stack>
        </form>
      )}
    />
  );
}

export default AbilityForm;
