import React, { Suspense } from "react";
import { useClassroom } from "../../api/classroom";
import {
  Container,
  Heading,
  HStack,
  Stack,
  Avatar,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Spinner,
  Breadcrumb,
  Button,
  BreadcrumbItem,
  BreadcrumbLink,
  useDisclosure,
  Text,
  Spacer,
  GridItem,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import ClassroomModalEdit from "../classroom/ClassroomModalEdit";
import StudentList from "./StudentList";
import Schedule from "./Schedule";
import Sessions from "./Sessions";
import { SimpleGrid } from "@chakra-ui/react";

function ClassroomDetail() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { id } = useParams<{ id: string }>();

  const { data } = useClassroom(id);

  return (
    <Container maxW="container.xl">
      <Breadcrumb
        my={8}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/classroom?page=1">
            Danh sách lớp
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{data?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack spacing={5} mb={5} align="start">
        <Avatar size="xl" name={data?.name} />
        <Stack align="start" spacing={1}>
          <Heading size="md">{data?.name}</Heading>
          <Text color="gray.500">{data?.description}</Text>
          <Button onClick={onOpen} variant="outline" size="xs">
            Sửa thông tin
          </Button>
          {data && (
            <ClassroomModalEdit
              isOpen={isOpen}
              onClose={onClose}
              editingData={data}
            />
          )}
        </Stack>
        <Spacer />
      </HStack>
      <Tabs isLazy>
        <TabList>
          <Tab>Học viên</Tab>
          <Tab>Lịch dạy</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <StudentList />
          </TabPanel>
          <TabPanel px={0}>
            <SimpleGrid spacing={6} columns={4}>
              <Schedule />
              <GridItem colSpan={3}>
                <Sessions />
              </GridItem>
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

function ClassroomDetailWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <ClassroomDetail />
    </Suspense>
  );
}

export default ClassroomDetailWrapper;
