import React from "react";
import {
  useDisclosure,
  Button,
  Stack,
  Text,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
} from "@chakra-ui/react";
import SkillCategoryForm from "./SkillCategoryForm";
import { Spacer } from "@chakra-ui/react";
import {
  useCreateSkillCategory,
  useSkillCategories,
} from "../../api/skill-category";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useUpdateSkillCategory,
  useDeleteSkillCategory,
} from "../../api/skill-category";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "skill-category";

function SkillCategoryModalAdd() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createSkillCategory = useCreateSkillCategory();
  const skillCategories = useSkillCategories();
  const updateSkillCategory = useUpdateSkillCategory();
  const deleteSkillCategory = useDeleteSkillCategory();

  return (
    <>
      <Button onClick={onOpen} variant="outline">
        Thêm loại
      </Button>

      <Modal  {...useResponsiveModalValue()} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thêm loại</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <SkillCategoryForm
                formId={FORM_ID}
                onSubmit={async (v) => {
                  try {
                    await createSkillCategory.mutateAsync(v);
                  } catch (error) {}
                }}
              />
              <Divider />
              <Stack>
                {skillCategories.data &&
                  skillCategories.data?.docs?.map((item) => (
                    <HStack
                      cursor="pointer"
                      p={2}
                      borderRadius="md"
                      _hover={{ bg: "gray.600" }}
                    >
                      <Text>{item.name}</Text>
                      <Spacer />
                      <Popover>
                        <PopoverTrigger>
                          <IconButton
                            colorScheme="gray"
                            aria-label="Edit"
                            icon={<EditIcon />}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Sửa</PopoverHeader>
                          <PopoverBody>
                            <SkillCategoryForm
                              initialValues={{ name: item?.name }}
                              submitText="Cập nhật"
                              formId={FORM_ID}
                              onSubmit={async (v) => {
                                await updateSkillCategory.mutateAsync({
                                  id: item._id,
                                  data: v,
                                });
                                try {
                                } catch (error) {}
                              }}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                      <IconButton
                        colorScheme="gray"
                        aria-label="Edit"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          if (
                            window.confirm(`Bạn có chắc muỗn xóa ${item.name}?`)
                          ) {
                            deleteSkillCategory.mutate(item._id);
                          }
                        }}
                      />
                    </HStack>
                  ))}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost" mr={3}>
              Đóng
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function SkillCategoryModalAddWrapper() {
  return (
    <React.Suspense fallback="loading...">
      <SkillCategoryModalAdd />
    </React.Suspense>
  );
}

export default SkillCategoryModalAddWrapper;
