import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import FilterBySearchKey from "src/components/FilterBySearchKey";
import { AddIcon } from "@chakra-ui/icons";
import SkillTable from "./SkillTable";
import SkillModalAdd from "./SkillModalAdd";
import SkillPaging from "./SkillPaging";
import SkillCategoryModalAdd from "./SkillCategoryModalAdd";
import FilterBySkillCategoryWrapper from "src/components/FilterBySkillCategory";

function Skill() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SkillModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Danh sách kĩ năng
          </Heading>
          <Spacer />
          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Thêm kĩ năng
          </Button>
          <SkillCategoryModalAdd />
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterBySearchKey placeholder="Tên, số điện thoại, email" />
                <FilterBySkillCategoryWrapper />
              </HStack>
              <Spacer />
              <SkillPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <SkillTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default Skill;
