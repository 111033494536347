import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import EventForm from "./EventForm";
import {
  CreateEventDto,
  createEvent,
  eventKeys,
} from "../../api/event";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-event";

interface EventModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: CreateEventDto
}

function EventModalAdd({ isOpen, onClose, initialValues }: EventModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateEventDto) => {
    try {
      await createEvent(values);
      onClose()
      toast({ status: "success", description: "Thêm sự kiện thành công" });
      queryClient.invalidateQueries(eventKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thêm sự kiện</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EventForm initialValues={initialValues} onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Thêm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EventModalAdd;
