import React from "react";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from "../constants/pagination";

function FilterByPageSize() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [currentPageSize, setCurrentPageSize] = useQueryParam(
    "limit",
    withDefault(NumberParam, DEFAULT_PAGE_SIZE)
  );

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          color={
            currentPageSize && currentPageSize !== DEFAULT_PAGE_SIZE
              ? "blue.500"
              : undefined
          }
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          variant="ghost"
          onClick={onOpen}
        >
          {currentPageSize} / page
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>{currentPageSize}</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {PAGE_SIZES.map((pageSize) => (
              <HStack
                onClick={() => {
                  setCurrentPageSize(pageSize);
                  onClose();
                }}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={pageSize}
                p={2}
              >
                <Text fontSize="sm" fontWeight="semibold">
                  {pageSize}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              onClick={() => {
                setCurrentPageSize(null);
                onClose();
              }}
            >
              Mặc định
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default FilterByPageSize;
