import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import FilterBySearchKey from "src/components/FilterBySearchKey";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import FilterByUpdated from "src/components/FilterByUpdated";
import FilterClearButton from "src/components/FilterClearButton";
import { AddIcon } from "@chakra-ui/icons";
import FilterByStatus from "src/components/FilterByStatus";
import ClassroomModalAdd from "./ClassroomModalAdd";
import ClassroomTableWrapper from "./ClassroomTable";
import FilterByUser from "src/components/FilterByUser";
import ClassroomPagingWrapper from "./ClassroomPaging";
import FilterByPageSize from "src/components/FilterByPageSize";

function Classroom() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ClassroomModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Danh sách lớp
          </Heading>
          <Spacer />
          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Thêm lớp
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByStatus />
                <FilterBySearchKey placeholder="Tìm kiếm theo tên" />
                <FilterByCenterWrapper />
                <FilterByUpdated />
                <FilterByUser label="Phụ trách" name="coach" />
                <FilterClearButton />
              </HStack>
              <Spacer />
              <FilterByPageSize />
              <ClassroomPagingWrapper />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <ClassroomTableWrapper />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default Classroom;
