import React, { Suspense } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useLeads } from "src/api/lead";
import { HStack } from "@chakra-ui/react";
import FilterByPage from "src/components/FilterByPage";

function LeadPagination() {
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { data: leads } = useLeads(params);

  return (
    <HStack spacing={4}>
      <FilterByPage
        totalDocs={leads?.totalDocs || 0}
        totalPages={leads?.totalPages || 1}
      />
    </HStack>
  );
}

function LeadPaginationWrapper() {
  return (
    <Suspense fallback="">
      <LeadPagination />
    </Suspense>
  );
}

export default LeadPaginationWrapper;
