import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import AbilityForm from "./AbilityForm";
import { CreateAbilityDto } from "../../api/ability";
import { useToast } from "@chakra-ui/react";
import { AbilityDocument } from "../../api/ability";
import { useUpdateAbility } from "../../api/ability";
import { omit } from "lodash";

const FORM_ID = "edit-ability";

interface AbilityModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData?: AbilityDocument;
  type: string;
}

function AbilityModalEdit({
  isOpen,
  onClose,
  editingData,
  type,
}: AbilityModalAddProps) {
  const toast = useToast({ position: "top" });
  const updateAbility = useUpdateAbility();

  const onSubmit = async (values: CreateAbilityDto) => {
    console.log("🚀 ~ onSubmit ~ values", values);
    try {
      await updateAbility.mutateAsync({
        id: editingData?._id,
        updateAbilityDto: values,
      });
      toast({ status: "success", description: "Cập nhật thành công" });
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal
      size="4xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AbilityForm
            initialValues={omit(editingData, [type, "_id"])}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Đóng
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AbilityModalEdit;
