import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useClassrooms } from "../../api/classroom";
import FilterByPage from "src/components/FilterByPage";

function ClassroomPaging() {
  const { params } = useSearchParams();
  const { data } = useClassrooms(params);

  return (
    <FilterByPage totalDocs={data?.totalDocs} totalPages={data?.totalPages} />
  );
}

function ClassroomPagingWrapper() {
  return (
    <Suspense fallback="">
      <ClassroomPaging />
    </Suspense>
  );
}

export default ClassroomPagingWrapper;
