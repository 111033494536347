import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import LabeledSelectField from "./LabeledSelectField";
import { useCenters } from "../api/center";

interface LabeledSelectCenterProps {
  name: string;
  label: string;
}

function Select({ name, label }: LabeledSelectCenterProps) {
  const { data: centers } = useCenters();
  const centerOptions = (centers?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  return <LabeledSelectField options={centerOptions} name={name} label={label} />;
}

function LabeledSelectCenter(props: LabeledSelectCenterProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <Select {...props} />
    </Suspense>
  );
}

export default LabeledSelectCenter;
