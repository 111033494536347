import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button, Stack } from "@chakra-ui/react";
import {
  CreateEventDto,
  eventKeys,
  updateEvent,
  UpdateEventDto,
} from "../../api/event";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { EventDocument, useCancelEvent } from "../../api/event";
import { pick } from "lodash";
import { Form } from "react-final-form";
import LabeledTextareaField from "src/components/LabeledTextareaField";
import LabeledTimePicker from "src/components/LabeledTimePicker";
import LabeledSelectUser from "src/components/LabeledSelectUser";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";
const FORM_ID = "add-event";

interface EventModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData?: EventDocument;
  setInitialFormValues?: (data: UpdateEventDto) => void;
  onOpenAddForm?: () => void;
  numOfSession?: number;
}

interface eventFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateEventDto;
}

function SessionModalEdit({
  isOpen,
  onClose,
  editingData,
  setInitialFormValues,
  onOpenAddForm,
  numOfSession,
}: EventModalAddProps) {
  const cancelEvent = useCancelEvent(onClose);
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const initialValues: CreateEventDto =
    editingData &&
    pick(
      {
        ...editingData,
        inCharge: editingData?.inCharge?._id,
        center: editingData?.center?._id,
        category: editingData?.category?._id,
      },
      [
        "name",
        "description",
        "start",
        "end",
        "inCharge",
        "center",
        "category",
        "isDisable",
      ]
    );

  const disableFormStyle = editingData?.isDisable
    ? { pointerEvents: "none", opacity: 0.8 }
    : {};

  const onSubmit = async (values: CreateEventDto) => {
    try {
      await updateEvent(editingData._id, values);
      onClose();
      toast({ status: "success", description: "Sửa thành công" });
      queryClient.invalidateQueries(eventKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };
  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Buổi {numOfSession}</ModalHeader>
        <ModalCloseButton />
        <ModalBody {...disableFormStyle}>
          <EventForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
          {initialValues?.isDisable ? (
            <Button
              mt={4}
              colorScheme="yellow"
              variant="outline"
              mr={3}
              disabled
            >
              Đã hủy
            </Button>
          ) : (
            <Button
              isLoading={cancelEvent.isLoading}
              onClick={() => {
                if (window.confirm(`Bạn có chắc muốn hủy buổi học này không`)) {
                  cancelEvent.mutate(editingData?._id);
                }
              }}
              mt={4}
              colorScheme="yellow"
              variant="outline"
              mr={3}
            >
              Hủy buổi học
            </Button>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Đóng
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function EventForm({ onSubmit, formId, initialValues }: eventFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextareaField name="description" label="Nội dung" />
            <LabeledTimePicker name="start" label="Bắt đầu" />
            <LabeledTimePicker name="end" label="Kết thúc" />
            <LabeledSelectUser name="inCharge" label="Phụ trách" />
          </Stack>
        </form>
      )}
    />
  );
}

export default SessionModalEdit;
