import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import LabeledSelectField from "./LabeledSelectField";
import { useUsers } from "../api/user";

interface LabeledSelectUserProps {
  name: string;
  label: string;
}

function Select({ name, label }: LabeledSelectUserProps) {
  const { data: users } = useUsers();
  const userOptions = (users?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  return <LabeledSelectField options={userOptions} name={name} label={label} />;
}

function LabeledSelectUser(props: LabeledSelectUserProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <Select {...props} />
    </Suspense>
  );
}

export default LabeledSelectUser;
