import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { StudentDocument } from "./student";
import { EventDocument } from "./event";

export interface CreateStudentEventDto {
  student: string;
  event: string;
  spend: number;
}

export interface GetStudentEventDto extends Partial<CreateStudentEventDto> {}

export interface UpdateStudentEventDto extends Partial<CreateStudentEventDto> {
  status?: Status;
}

export interface StudentEventDocument extends TimeStamp, ObjectId {
  student: StudentDocument;
  event: EventDocument;
  spend: number;
}

// api
export const createStudentEvent = async (
  createStudentEventDto: CreateStudentEventDto
) => {
  const { data } = await axios.post("/student-event", createStudentEventDto);
  return data;
};

export const getStudentEvent = async (id: string) => {
  const { data } = await axios.get<StudentEventDocument>(
    `/student-event/${id}`
  );
  return data;
};

export const getStudentEvents = async (
  params?: QueryOptionParams & GetStudentEventDto
) => {
  const { data } = await axios.get<PaginateDocument<StudentEventDocument>>(
    "/student-event",
    {
      params: { page: 1, ...params },
    }
  );
  return data;
};

const deleteStudentEvent = async (id: string) => {
  const { data } = await axios.delete(`/student-event/${id}`);
  return data;
};

export const getTotalAmount = async (id: string) => {
  const { data } = await axios.get<{ totalAmount: number }>(
    `/student-event/${id}/total`
  );
  return data;
};

// hooks
export const studentEventKeys = {
  ...generateQueryKey("student-event"),
  total: ["student-event", "total"],
  totalSpend: ["student-event", "total-spend"],
};

export const useStudentEvents = (
  params?: QueryOptionParams & GetStudentEventDto
) => {
  console.log("🚀 ~ params:", params)
  return useQuery(studentEventKeys.list(params), () =>
    getStudentEvents(params)
  );
};

export const useStudentEvent = (id: string) => {
  return useQuery(studentEventKeys.detail(id), () => getStudentEvent(id));
};

export const useCreateStudentEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(createStudentEvent, {
    onSuccess: () => queryClient.invalidateQueries(studentEventKeys.lists()),
  });
};

export const useDeleteStudentEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteStudentEvent, {
    onSuccess: () => queryClient.invalidateQueries(studentEventKeys.lists()),
  });
};

export const useTotalAmount = (id: string) => {
  return useQuery(studentEventKeys.total, () => getTotalAmount(id));
}; // Deprecated

export const useStudentTotalSpend = () => {
  return useQuery(studentEventKeys.totalSpend, () =>
    axios
      .get<{ sum: number }[]>("/student-event/total-spend")
      .then((res) => res.data)
  );
};
