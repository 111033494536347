import { useTotalAmount } from "src/api/transaction";
import { useStudentEvents } from "../../../api/studentEvent";
import { sumBy } from "lodash";
export const useStudentMoney = (id: string) => {
  const { data: total } = useTotalAmount(id);
  const studentEvents =
    useStudentEvents({ student: id, limit: 100000 }).data?.docs || [];
  const spend = sumBy(studentEvents, "spend");
  return {
    total: total?.totalAmount || 0,
    spend,
  };
};
