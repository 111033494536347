import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import StudentForm from "./StudentForm";
import {
  CreateStudentDto,
  studentKeys,
  updateStudent,
} from "../../api/student";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { StudentDocument } from "../../api/student";
import dayjs from "dayjs";
import { useResponsiveModalValue } from "src/utils/useResponsiveModal";

const FORM_ID = "add-student";

interface StudentModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: StudentDocument;
}

function StudentModalEdit({
  isOpen,
  onClose,
  editingData,
}: StudentModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateStudentDto) => {
    try {
      await updateStudent(editingData._id, {
        ...values,
        birthDay: values.birthDay && dayjs(values.birthDay).toISOString(),
      });
      onClose();
      toast({ status: "success", description: "Sửa thành công" });
      queryClient.invalidateQueries(studentKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StudentForm
            initialValues={
              editingData && {
                ...editingData,
                center: editingData?.center?._id,
                takeCareBy: editingData?.takeCareBy?._id,
                birthDay: editingData.birthDay
                  ? dayjs(editingData.birthDay).format("YYYY-MM-DD")
                  : undefined,
                startCourseDate: editingData.startCourseDate
                  ? dayjs(editingData.startCourseDate).format("YYYY-MM-DD")
                  : undefined,
              }
            }
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default StudentModalEdit;
