import React, { Suspense, useState } from "react";
import { useSkills } from "../../api/skill";
import { HStack, IconButton, SimpleGrid, Spacer } from "@chakra-ui/react";
import { GridItem, Text, Input, Stack, Box } from "@chakra-ui/react";
import { range } from "lodash";
import {
  useCreateStudentSkill,
  useStudentSkills,
} from "../../api/student-skill";
import { useParams } from "react-router-dom";
import { useDeleteStudentSkill } from "../../api/student-skill";
import {
  ArrowRightIcon,
  DeleteIcon,
  SearchIcon,
  StarIcon,
} from "@chakra-ui/icons";

interface SkillItemProps {
  onDelete?: () => void;
  onAdd?: () => void;
  title: string;
  category: string;
  star: number;
}

function SkillItem({ title, category, star, onDelete, onAdd }: SkillItemProps) {
  return (
    <HStack _hover={{ bg: "gray.700" }} p={2.5} borderRadius="md">
      <Box>
        <Text>
          {title}
          <Text ml={2} fontSize="sm" color="gray.500" as="span">
            - {category}
          </Text>
        </Text>
        {range(0, star).map(() => (
          <StarIcon mr={1} boxSize={2.5} color="yellow.500" />
        ))}
      </Box>
      <Spacer />
      {onDelete && (
        <IconButton
          variant="outline"
          aria-label="Delete"
          colorScheme="red"
          icon={<DeleteIcon />}
          onClick={onDelete}
        />
      )}
      {onAdd && (
        <IconButton
          variant="outline"
          aria-label="Delete"
          icon={<ArrowRightIcon />}
          onClick={onAdd}
        />
      )}
    </HStack>
  );
}

function SkillTable() {
  const studentId = useParams().id;

  const createStudentSkill = useCreateStudentSkill();
  const deleteStudentSkill = useDeleteStudentSkill();

  const [allInput, setAllInput] = useState("");
  const [searchAll, setSearchAll] = useState("");

  const allSkills = useSkills({ search: searchAll });
  const studentSkills = useStudentSkills({
    student: studentId,
  });

  return (
    <SimpleGrid columns={2} spacing={20} mb={10}>
      <GridItem>
        <Text textTransform="uppercase" fontSize="lg" mb={4}>
          Tất cả kĩ năng ({allSkills.data?.totalDocs})
        </Text>
        <HStack
          mb={4}
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            setSearchAll(allInput);
          }}
        >
          <Input
            value={allInput}
            onChange={(e) => setAllInput(e.target.value)}
            placeholder="Tìm kiếm..."
            size="md"
            bg="gray.800"
          />
          <IconButton
            type="submit"
            aria-label="search"
            colorScheme="gray"
            size="md"
            icon={<SearchIcon />}
          />
        </HStack>
        <Stack minH="500px" maxH="700px" bg="gray.800" borderRadius="lg" p={4}>
          {allSkills.data?.docs?.map((item) => (
            <SkillItem
              title={item.name}
              category={item?.category?.name}
              star={item.star}
              onAdd={() =>
                createStudentSkill.mutate({
                  skill: item._id,
                  student: studentId,
                })
              }
            />
          ))}
        </Stack>
      </GridItem>
      <GridItem>
        <Text textTransform="uppercase" fontSize="lg" mb={4}>
          Kĩ năng đã học ({studentSkills.data?.totalDocs})
        </Text>
        <Stack minH="500px" maxH="700px" bg="gray.800" borderRadius="lg" p={4}>
          {studentSkills.data?.docs?.map((item) => (
            <SkillItem
              title={item?.skill?.name}
              category={item?.skill?.category?.name}
              star={item?.skill?.star}
              onDelete={() => {
                if (
                  window.confirm(`Bạn có chắc muốn xóa ${item?.skill?.name}`)
                ) {
                  deleteStudentSkill.mutate(item._id);
                }
              }}
            />
          ))}
        </Stack>
      </GridItem>
    </SimpleGrid>
  );
}

function SkillTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SkillTable />
    </Suspense>
  );
}

export default SkillTableWrapper;
