import React from "react";
import { useQueryParam, StringParam } from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { useSkillCategories } from "../api/skill-category";

function FilterBySkillCategory() {
  const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [currentSkillCategory, setCurrentSkillCategory] = useQueryParam(
    "category",
    StringParam
  );

  const { data: skillCategories } = useSkillCategories();

  const selectedSkillCategory = (skillCategories?.docs || []).find(
    (item) => item._id === currentSkillCategory
  );

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentSkillCategory ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedSkillCategory?.name || "Loại"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Loại</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {skillCategories?.docs?.map((skillCategory) => (
              <HStack
                onClick={() => {
                  setCurrentSkillCategory(skillCategory._id);
                  onClose();
                }}
                color={
                  skillCategory._id === currentSkillCategory
                    ? "blue.500"
                    : undefined
                }
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={skillCategory._id}
                p={2}
              >
                <Text fontSize="sm" fontWeight="semibold">
                  {skillCategory.name}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setCurrentSkillCategory("");
                onClose();
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterBySkillCategoryWrapper() {
  return (
    <Suspense
      fallback={
        <Button variant="ghost" isLoading>
          Trung Tâm
        </Button>
      }
    >
      <FilterBySkillCategory />
    </Suspense>
  );
}

export default FilterBySkillCategoryWrapper;
