import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import FilterClearButton from "src/components/FilterClearButton";
import TransactionPaging from "./TransactionTable";
import TimeSheetPaging from "./TransactionPaging";
import FilterByUser from "src/components/FilterByUser";
import FilterByTime from "src/components/FilterByTime";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import FilterByPageSize from "src/components/FilterByPageSize";
import TransactionDownload from "./TransactionDownload";
function Transactions() {
  return (
    <>
      {/* <TimeSheetModalAdd isOpen={isOpen} onClose={onClose} /> */}
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Giao dịch tiền
          </Heading>
          <Spacer />
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByUser name="sale" label="Nhân viên" />
                <FilterByTime name="date" label="Ngày giao dịch" />
                {/* <FilterByTime name="createdAt" label="Ngày tạo" /> */}
                <FilterByCenterWrapper />
                <FilterClearButton />
              </HStack>
              <Spacer />
              <TransactionDownload />
              <FilterByPageSize />
              <TimeSheetPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <TransactionPaging />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default Transactions;
