import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import { useParams } from "react-router";
import { useEvents } from "../../api/event";
import Table from "src/components/Table";
import { Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Column } from "react-table";

function Component() {
  const { id } = useParams<{ id: string }>();
  const events = useEvents({ inCharge: id }).data?.docs || [];

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        accessor: "name",
      },
      {
        Header: "Mô tả",
        accessor: "description",
      },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Bắt đầu",
        accessor: "start",
        Cell: ({ value }) => dayjs(value).format("lll"),
      },
      {
        Header: "Kết thúc",
        accessor: "end",
        Cell: ({ value }) => dayjs(value).format("lll"),
      },
    ], // eslint-disable-next-line
    []
  );

  return <Table columns={columns} data={events} />;
}

function UserClassesTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );
}

export default UserClassesTable;
