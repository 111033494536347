import React from "react";
import {
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  SimpleGrid,
  GridItem,
  FormHelperText,
} from "@chakra-ui/react";
import { forwardRef, PropsWithoutRef } from "react";
import { useField } from "react-final-form";
import { css } from "@emotion/react";
import dayjs from "dayjs";

interface TimePickerProps {
  value?: string;
  onChange: (value: any) => void;
}

const TimePicker = ({
  value = dayjs().startOf("d").toISOString(),
  onChange,
}: TimePickerProps) => {
  const changeDate = (e: React.FormEvent<HTMLInputElement>) => {
    const currentDate = dayjs(value);
    const dateValue = dayjs(e.currentTarget.value);
    const newDate = currentDate
      .year(dateValue.year())
      .month(dateValue.month())
      .date(dateValue.date());

    onChange?.(newDate.toISOString());
  };

  const changeTime = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value.split(":");
    const valueHour = parseInt(inputValue[0]);
    const valueMinute = parseInt(inputValue[1]);

    const currentDate = dayjs(value);
    const newDate = currentDate.hour(valueHour).minute(valueMinute);

    onChange?.(newDate.toISOString());
  };

  return (
    <SimpleGrid w="full" columns={3} spacing={4}>
      <GridItem colSpan={2}>
        <Input
          value={dayjs(value).format("YYYY-MM-DD")}
          onChange={changeDate}
          type="date"
          css={css`
            ::-webkit-calendar-picker-indicator {
              filter: invert(80%);
            }
          `}
        />
      </GridItem>
      <Input
        value={dayjs(value).format("HH:mm")}
        onChange={changeTime}
        type="time"
        css={css`
          ::-webkit-calendar-picker-indicator {
            filter: invert(80%);
          }
        `}
      />
    </SimpleGrid>
  );
};

export interface LabeledTimePickerProps
  extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  name: string;
  label: string;
  required?: boolean;
  autoComplete?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
}

export const LabeledTimePicker = forwardRef<
  HTMLInputElement,
  LabeledTimePickerProps
>(
  (
    {
      name,
      label,
      outerProps,
      placeholder,
      type,
      helperText,
      disabled,
      required,
      autoComplete,
      ...props
    },
    ref
  ) => {
    const {
      input,
      meta: { touched, error, submitError },
    } = useField(name, {
      defaultValue: dayjs().startOf("d").toISOString()
    });
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb="1.5">
          {label}
        </FormLabel>
        <InputGroup>
          <TimePicker
            value={input.value || undefined}
            onChange={(value) => input.onChange(value)}
          />
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default LabeledTimePicker;
