import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useTransactions } from "../../api/transaction";
import FilterByPage from "src/components/FilterByPage";
import { useParams } from "react-router";

function TransactionPaging() {
  const { params } = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { data } = useTransactions({ student: id, ...params });

  return (
    <FilterByPage totalDocs={data?.totalDocs} totalPages={data?.totalPages} />
  );
}

function TransactionPagingWrapper() {
  return (
    <Suspense fallback="">
      <TransactionPaging />
    </Suspense>
  );
}

export default TransactionPagingWrapper;
