import React from "react";
import { Button, Tooltip } from "@chakra-ui/react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { omit, pickBy, isEmpty } from "lodash";

function FilterClearButton() {
  const { pathname, search } = useLocation();

  const params = queryString.parse(search);
  const paramsWithoutPage = pickBy(omit(params, "page"));
  const isEmptyParams = isEmpty(paramsWithoutPage);

  return (
    <Tooltip label="Xóa tất cả bộ lọc">
      <Button
        disabled={isEmptyParams}
        onClick={() => window.location.replace(`${pathname}?page=1`)}
        colorScheme="blue"
        variant="ghost"
      >
        Xóa bộ lọc
      </Button>
    </Tooltip>
  );
}

export default FilterClearButton;
