import React from "react";
import { Button, Stack, SimpleGrid } from "@chakra-ui/react";
import { Form } from "react-final-form";
import LabeledTextField from "src/components/LabeledTextField";
import LabeledWeekdaysSelect from "src/components/LabeledWeekdaysSelect";
import { useParams } from "react-router-dom";
import { useClassroom, useCreateClassroomSchedule } from "../../api/classroom";
import dayjs from "dayjs";

function Schedule() {
  const { id } = useParams<{ id: string }>();
  const createSchedule = useCreateClassroomSchedule(id);
  const { data: classroom } = useClassroom(id);
  const options = dayjs.weekdaysShort();

  return (
    <Form
      initialValues={{
        ...classroom?.schedule,
        category: classroom?.schedule?.category?._id,
      }}
      onSubmit={(e) => {
        return createSchedule.mutate(e);
      }}
      render={({ handleSubmit, values }) => (
        <Stack spacing={5} as="form" onSubmit={handleSubmit}>
          <Stack position={"sticky"} top={10} spacing={4}>
            <LabeledWeekdaysSelect name="repeat" label="Ngày dạy" />
            {values.repeat?.map((date: number, i: number) => (
              <SimpleGrid columns={2} spacing={4} key={i}>
                <LabeledTextField
                  required
                  name={`start${date}`}
                  label={`${options[date]} - Bắt đầu`}
                  type="time"
                />
                <LabeledTextField
                  required
                  name={`end${date}`}
                  label="Kêt thúc"
                  type="time"
                />
              </SimpleGrid>
            ))}
            <LabeledTextField
              type="date"
              name="fromDate"
              label="Ngày bắt đầu"
              required
            />
            <Button
              isLoading={createSchedule.isLoading}
              variant={"outline"}
              type="submit"
            >
              Lưu
            </Button>
          </Stack>
        </Stack>
      )}
    />
  );
}

export default Schedule;
