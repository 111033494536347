import React from "react";
import { AbilityModalAdd } from "./AbilityModalAdd";
import AbilityTable from "./AbilityTable";

interface AbilityProps {
  type?: "student" | "lead";
}

function Ability({ type = "student" }: AbilityProps) {
  return (
    <>
      <AbilityTable type={type} />
    </>
  );
}

export default Ability;
