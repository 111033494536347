import {
  Box,
  Heading,
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Icon,
  Tooltip,
  HStack,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import React, { Suspense } from "react";
import Table from "src/components/Table";
import { BiSearch } from "react-icons/bi";
import { MdRestore } from "react-icons/md";
import AddForm from "./AddForm";
import { CellProps, Column } from "react-table";
import {
  ClassCategoryDocument,
  updateClassCategory,
  useClassCategories,
} from "src/api/classCategory";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Status } from "src/types/Status";

function ClassCategory() {
  const centers = useClassCategories();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editData, setEditData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const archiveClassCategory = async (classCategory: ClassCategoryDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${classCategory.name}`)) {
        await updateClassCategory(classCategory._id, { status: Status.Inactive });
      }
    } catch (error) {}
  };

  const restoreClassCategory = async (classCategory: ClassCategoryDocument) => {
    try {
      if (window.confirm(`Khôi phục ${classCategory.name}`)) {
        await updateClassCategory(classCategory._id, { status: Status.Active });
      }
    } catch (error) {}
  };

  const openEditModal = React.useCallback(
    (classCategory: ClassCategoryDocument) => {
      setEditData(classCategory);
      setEditMode(true);
      onOpen();
    },
    [onOpen]
  );

  const columns = React.useMemo<Column[]>(
    () => [
      { Header: "Tên khóa học", accessor: "name" },
      { Header: "Giá tiền", accessor: "price" },
      { Header: "Số buổi", accessor: "numberOfLesson" },
      { Header: "Lương dạy", accessor: "salaryByLesson" },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<ClassCategoryDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveClassCategory(row.original);
                    } else {
                      restoreClassCategory(row.original);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => openEditModal(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ],
    [openEditModal]
  );

  return (
    <Flex direction="column" p={6} h="full">
      <Heading size="md" textTransform="uppercase" color="gray.600" mb={4}>
        Khóa học
      </Heading>
      <Flex mb={4}>
        <InputGroup w="xs">
          <InputLeftElement
            pointerEvents="none"
            children={<BiSearch color="gray.400" />}
          />
          <Input type="text" placeholder="Tìm khóa học" />
        </InputGroup>
        <Spacer />
        <Button
          onClick={() => {
            setEditMode(false);
            onOpen();
          }}
          leftIcon={<AddIcon />}
        >
          Thêm khóa học
        </Button>
        <AddForm
          isOpen={isOpen}
          onClose={onClose}
          data={editData}
          editMode={editMode}
        />
      </Flex>
      <Box
        overflow="auto"
        flex={1}
        px={5}
        borderRadius="md"
        bg="gray.800"
      >
        <Table columns={columns} data={centers?.data?.docs || []} />
      </Box>
    </Flex>
  );
}

const ClassCategoryWrapper = () => {
  return (
    <Suspense fallback="Loading...">
      <ClassCategory />
    </Suspense>
  );
};

export default ClassCategoryWrapper;
