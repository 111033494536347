import React from "react";
import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  useDisclosure,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import ModalAddLead from "./ModalAddLead";
import FilterLead from "./FilterLead";
import LeadTable from "./LeadTable";
import LeadPagination from "./LeadPagination";
import FilterByPageSize from "src/components/FilterByPageSize";

function Lead() {
  const {
    isOpen: isOpenAdd,
    onClose: onCloseAdd,
    onOpen: onOpenAdd,
  } = useDisclosure();

  return (
    <Flex direction="column" p={6} h="full">
      <ModalAddLead isOpen={isOpenAdd} onClose={onCloseAdd} />
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Lead
        </Heading>
        <Spacer />
        <Button onClick={onOpenAdd}>Thêm Lead</Button>
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor={"gray.700"}>
          <Flex mb={2}>
            <FilterLead />
            <Spacer />
            <FilterByPageSize />

            <LeadPagination />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <LeadTable />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export default Lead;
