import {
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Box,
  Text,
  Tooltip,
  Stack,
  Link,
} from "@chakra-ui/react";
import React from "react";
import Table from "src/components/Table";
import {
  ClassroomDocument,
} from "../../api/classroom";
import { getStudentEvents, studentEventKeys } from "../../api/studentEvent";
import { Suspense } from "react";
import { userEventKeys, getUserEvents } from "src/api/userEvent";
import { formatMoney } from "../../utils/format";
import FilterByTime from "src/components/FilterByTime";
import useSearchParams from "src/utils/useSearchParams";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import { sumBy, orderBy } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { useQueries } from 'react-query';
function Profit() {
  const { params } = useSearchParams();
  const results = useQueries([
    {
      queryKey: studentEventKeys.list(params),
      queryFn: () => getStudentEvents(params),
    },
    {
      queryKey: userEventKeys.list(params),
      queryFn: () => getUserEvents(params),
    },
  ]);
  const studentEvents = results[0].data?.docs || [];
  const userEvents = results[1].data?.docs || [];

  let events = [...studentEvents, ...userEvents];
  events = orderBy(events, ["event.start", "event.classroom.name"], ["desc"])
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tiền",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.earn && (
                <Text color="red.300" fontWeight={"bold"}>
                  -{formatMoney(row.original.earn)}
                </Text>
              )}
              {row.original.spend && (
                <Text color="teal.200" fontWeight={"bold"}>
                  +{formatMoney(row.original.spend)}
                </Text>
              )}
            </>
          );
        },
      },
      {
        Header: "Loại",
        Cell: ({ row }) => {
          if (row.original.student) {
            return (
              <Stack spacing={1}>

                <Text fontSize="xs" color="gray.500">
                  Học phí của
                </Text>
                <Link as={RouterLink} to={`/student/${row.original.student?._id}`}>
                  <Text>{row.original.student?.name}</Text>
                </Link>
              </Stack>
            );
          }
          return (
            <Stack spacing={1}>
              <Text fontSize="xs" color="gray.500">
                Trả lương cho
              </Text>
              {/* <Text>{row.original.user?.name}</Text> */}
              <Link to={`/user/${row.original.user?._id}`} as={RouterLink}>
                <HStack>
                  <Text>{row.original.user?.name}</Text>
                </HStack>
              </Link>
            </Stack>
          );
        },
      },
      {
        Header: "Lớp",
        accessor: "event.classroom",
        Cell: ({ value }: CellProps<ClassroomDocument>) => (
          <Link
            to={`/classroom/${value._id}`}
            as={RouterLink}
          >
            {value.name}
          </Link>
        ),
      },
      {
        Header: "Ngày học",
        accessor: "event.start",
        Cell: ({ value }) => {
          return (
            <Tooltip label={dayjs(value).format("LLLL")}>
              {dayjs(value).format("L")}
            </Tooltip>
          );
        },
      },
      {
        Header: "Ngày tạo",
        accessor: "createdAt",
        Cell: ({ value, row }) => {
          const isSameDate = dayjs(value).isSame(dayjs(row.original?.event?.start), 'day');
          return (
            <Tooltip label={dayjs(value).format("LLLL")}>
              <Text color={!isSameDate ? "red.300" : ""}>
                {dayjs(value).format("L")}
              </Text>
            </Tooltip>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Lợi nhuận
        </Heading>
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterByTime name="createdAt" label="Ngày" />
              <HStack bg="gray.700" px={4} py={1} borderRadius="md">
                <Text>Lợi nhuận: </Text>
                <Text color="teal.200">
                  ({studentEvents.length}) {formatMoney(sumBy(studentEvents, "spend"))}
                </Text>
                <Text>-</Text>
                <Text color="red.300">
                ({userEvents.length}) {formatMoney(sumBy(userEvents, "earn"))}
                </Text>
                <Text>=</Text>
                <Text color="teal.200" fontWeight={"bold"}>
                  {formatMoney(
                    sumBy(studentEvents, "spend") - sumBy(userEvents, "earn")
                  )}
                </Text>
              </HStack>
            </HStack>
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <Table columns={columns} data={events} />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}


function ProfitWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <Profit />
    </Suspense>
  );
}

export default ProfitWrapper;
