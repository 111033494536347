import axios from "src/config/axios";
import { useQuery } from "react-query";
import { PaginateDocument } from "../types/PaginateDocument";
import { ObjectId } from "../types/ObjectId";
import { TimeStamp } from "../types/TimeStamp";
import generateQueryKey from "src/utils/queryKeyFactory";

export interface LeadStatus {
  value: string;
  label: string;
  color: string;
}

export type LeadStatusDocument = LeadStatus & ObjectId & TimeStamp;

export const getLeads = async () => {
  const { data } = await axios.get<PaginateDocument<LeadStatusDocument>>(
    "/lead-status"
  );
  return data;
};

// hooks
export const leadStatusKeys = generateQueryKey("lead-status");

export const useLeadStatus = () => {
  return useQuery(leadStatusKeys.all, () => getLeads(), {
    staleTime: Infinity,
  });
};
