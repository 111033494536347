import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ObjectId } from "src/types/ObjectId";
import { Status } from "src/types/Status";
import { TimeStamp } from "src/types/TimeStamp";
import generateQueryKey from "src/utils/queryKeyFactory";
import { PaginateDocument } from "../types/PaginateDocument";

export const centerKeys = generateQueryKey("center");
export interface CenterDto {
  name?: string;
  address?: string;
  status?: Status;
  latitude?: number;
  longitude?: number;
}

export type CenterDocument = CenterDto & TimeStamp & ObjectId;
export interface GetCenter {
  status?: Status;
}

export const createCenter = async (createData: CenterDto) => {
  const { data } = await axios.post("/center", createData);
  return data;
};

export const getCenters = async (params?: GetCenter) => {
  const { data } = await axios.get("/center", { params });
  return data;
};

export const updateCenter = async (id: string, updateData: CenterDto) => {
  const { data } = await axios.patch(`/center/${id || ""}`, updateData);
  return data;
};

export const useCenters = (params?: GetCenter) => {
  return useQuery<PaginateDocument<CenterDocument>>(
    centerKeys.list(params),
    () => getCenters(params),
    { staleTime: Infinity }
  );
};

export const useCenter = (id: string) => {
  return useQuery(centerKeys.detail(id), () =>
    axios.get(`/center/${id}`).then((res) => res.data)
  );
};

export const useUpdateCenter = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation((updateData: CenterDto) => updateCenter(id, updateData), {
    onSuccess: () => {
      queryClient.invalidateQueries(centerKeys.detail(id));
    },
  });
};
