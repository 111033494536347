import React from "react";
import { AbilityScore } from "src/constants/abilities";
import {
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Stack,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

interface AbilityScoreNoteProps {
  title: string;
  scores: AbilityScore[];
}

function AbilityScoreNote({ title, scores }: AbilityScoreNoteProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          size="sm"
          color="gray.400"
          aria-label="?"
          icon={<QuestionOutlineIcon boxSize={5} />}
          variant="unstyled"
          _focus={{ boxShadow: "none" }}
        />
      </PopoverTrigger>
      <PopoverContent w="max-content">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>
          <Stack p={2}>
            {scores.map((score) => (
              <HStack>
                <Text>
                  <Text as="strong" color="white">
                    {score.value}
                  </Text>
                  {` - ${score.label}`}
                </Text>
              </HStack>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default AbilityScoreNote;
