import {
  Box,
  Divider,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  Link,
  Tooltip
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router";
import { useUserEvents } from "../../api/userEvent";
import dayjs from "dayjs";
import { Column } from "react-table";
import { Suspense } from "react";
import Table from "src/components/Table";
import { formatMoney } from "../../utils/format";
import FilterByTime from "src/components/FilterByTime";
import useSearchParams from "src/utils/useSearchParams";
import { sumBy, sortBy } from "lodash";
import { Link as RouterLink } from "react-router-dom";

function SalaryTable() {
  const { id } = useParams<{ id: string }>();
  const { params } = useSearchParams();
  const userEvents =
    useUserEvents({ user: id, limit: 100000 }).data?.docs || [];

  let filteredUserEvents = userEvents.filter((item) => {
    const start = params.startDate;
    const end = params.endDate;

    if (start && end) {
      const eventStart = dayjs(item.event.start);
      return eventStart.isBetween(start, dayjs(end).endOf("d"));
    }

    return true;
  });

  const totalSalary = sumBy(filteredUserEvents, "earn");
  let attendanceFailed = 0;
  filteredUserEvents.forEach(e => {
    if (!dayjs(e.createdAt).isSame(dayjs(e.event?.start), 'day')) {
      attendanceFailed++
    }
  })

  filteredUserEvents = sortBy(filteredUserEvents, (o) =>  o.event.start).reverse()
  const getTotalSalaryText = () => {
    const startDate = params?.startDate;
    const endDate = params?.endDate;
    if (startDate && endDate) {
      return `Tổng lương (${dayjs(startDate).format("L")} - ${dayjs(
        endDate
      ).format("L")}): `;
    }
    return `Tổng lương:`;
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Buổi",
        Cell: ({ row }) => (filteredUserEvents.length || 0) - row.index,
      },
      {
        Header: "Ngày học",
        accessor: "event.start",
        Cell: ({ value }) =>
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).format("L")}
          </Tooltip>
      },
      {
        Header: "Ngày điểm danh",
        accessor: "createdAt",
        Cell: ({ value, row }) => {
          const isSameDate = dayjs(value).isSame(dayjs(row.original?.event?.start), 'day');
          return (
            <Tooltip label={dayjs(value).format("LLLL")}>
              <Text color={!isSameDate ? "red.300" : ""}>
                {dayjs(value).format("L")}  {!isSameDate && "- Late"}
              </Text>
            </Tooltip>)
        }
      },
      {
        Header: "Giờ",
        Cell: ({ row }) => {
          const event = row.original?.event;
          const start = dayjs(event?.start).format("HH:mm");
          const end = dayjs(event?.end).format("HH:mm");
          return (
            <Stack py={1}>
              <Text fontWeight="bold">{`${start} - ${end}`}</Text>
            </Stack>
          );
        },
      },
      {
        Header: "Lớp",
        accessor: "event.classroom",
        Cell: ({ value }) => (
          <Link
            as={RouterLink}
            to={`/classroom/${value._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {value.name}
          </Link>
        ),
      },
      {
        Header: () => (
          <Text mt={4} textAlign={"right"}>
            Lương mỗi buổi
          </Text>
        ),
        accessor: "earn",
        Cell: ({ value }) => (
          <Text
            py={2}
            fontSize="md"
            fontWeight="bold"
            color={value >= 0 ? "teal.300" : "red.300"}
            textAlign="right"
          >
            {value >= 0 && "+"}
            {formatMoney(value)}
          </Text>
        ),
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <Box bg="gray.800" p={4} borderRadius="md">
      <Flex mb={4}>
        <HStack>
          <FilterByTime name="createdAt" label="Thời gian" />
        </HStack>
        <Spacer />
        <HStack bg="gray.700" px={3} borderRadius="md" mr={2}>
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            Số ca dạy:
          </Text>
          <Text color="green.200">{filteredUserEvents.length}</Text>
        </HStack>
        <HStack bg="gray.700" px={3} borderRadius="md" mr={2}>
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            Lỗi:
          </Text>
          <Text color="red.300">{attendanceFailed}</Text>
        </HStack>
        <HStack bg="gray.700" px={3} borderRadius="md">
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            {getTotalSalaryText()}
          </Text>
          <Text color="green.200">{formatMoney(totalSalary)}</Text>
        </HStack>
      </Flex>
      <Divider />
      <Table columns={columns} data={filteredUserEvents} />
    </Box>
  );
}

function SalaryTableWrapper() {
  return (
    <Suspense fallback="loading">
      <SalaryTable />
    </Suspense>
  );
}

export default SalaryTableWrapper;
