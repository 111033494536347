import React, { Suspense } from "react";
import { Form } from "react-final-form";
import { VStack } from "@chakra-ui/react";
import LabeledTextField from "src/components/LabeledTextField";
import { useCenters } from "../../api/center";
import { useUsers } from "src/api/user";
// import LabeledTextareaField from "../../components/LabeledTextareaField";
import LabeledSelectField from "src/components/LabeledSelectField";
import { useLeadStatus } from "../../api/leadStatus";
import { LeadDto, LeadUpdateDto } from "src/api/lead";

interface FormLeadProps {
  onSubmit: (props: any) => void;
  initialValues?: LeadDto | LeadUpdateDto;
  formId: string;
}

function FormLead({ onSubmit, initialValues, formId }: FormLeadProps) {
  const { data: centers } = useCenters();
  const { data: users } = useUsers();
  const { data: leadStatus } = useLeadStatus();

  const userOptions = (users?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const centerOptions = (centers?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const statusOptions = (leadStatus?.docs || []).map((item) => ({
    value: item._id,
    label: item.label,
  }));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <VStack id={formId} spacing={4} onSubmit={handleSubmit} as="form">
          <LabeledTextField required name="name" label="Tên" />
          <LabeledTextField required name="phone" label="Số điện thoại" />
          <LabeledTextField name="facebook" label="Facebook" />
          <LabeledSelectField
            options={centerOptions}
            name="center"
            label="Trung tâm"
          />
          <LabeledSelectField
            options={userOptions}
            name="takeCareBy"
            label="Take Care"
          />
          <LabeledSelectField
            options={statusOptions}
            name="leadStatus"
            label="Trạng thái"
          />
          {/* <LabeledTextareaField name="note" label="Ghi chú" /> */}
        </VStack>
      )}
    />
  );
}

function FormLeadWrapper(props: FormLeadProps) {
  return (
    <Suspense fallback="loading...">
      <FormLead {...props} />
    </Suspense>
  );
}

export default FormLeadWrapper;
