import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import { StudentDocument, useStudents, studentKeys } from "../../api/student";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import {
  Tooltip,
  Text,
  HStack,
  Stack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
  Avatar,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { FiEdit } from "react-icons/fi";
import StudentModalEdit from "./StudentModalEdit";
import { Status } from "src/types/Status";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdRestore } from "react-icons/md";
import { updateStudent } from "../../api/student";
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { formatMoney } from "../../utils/format";
import StudentTableNote from "./StudentTableNote";
function StudentTable() {
  const queryClient = useQueryClient();
  const { params } = useSearchParams();
  const { data } = useStudents(params);
  const [editingData, setEditingData] = useState<StudentDocument>({});

  const { isOpen, onClose, onOpen } = useDisclosure();

  const editStudent = (student: StudentDocument) => {
    onOpen();
    setEditingData(student);
  };

  const archiveStudent = async (student: StudentDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${student?.name}`)) {
        await updateStudent(student?._id, { status: Status.Inactive });
        queryClient.invalidateQueries(studentKeys.all);
      }
    } catch (error) { }
  };

  const restoreStudent = async (student: StudentDocument) => {
    try {
      if (window.confirm(`Khôi phục ${student?.name}`)) {
        await updateStudent(student?._id, { status: Status.Active });
        queryClient.invalidateQueries(studentKeys.all);
      }
    } catch (error) { }
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Note",
        Cell: StudentTableNote,
      },
      {
        Header: "ID",
        accessor: "number",
      },
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<StudentDocument>) => (
          <Link
            as={RouterLink}
            to={`/student/${row.original?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Avatar
                size="xs"
                name={row.original.name}
                src={row.original.photoUrl}
              />
              <Text>{row.original.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Tên mẹ/ tên bố",
        accessor: "motherName",
        Cell: ({ row }: CellProps<StudentDocument>) => {
          return (
            <Stack>
              <Text>{row.original.motherName}</Text>
              <Text>{row.original.fatherName}</Text>
            </Stack>
          );
        },
      },
      {
        Header: "Số điện thoại",
        accessor: "phone",
      },
      {
        Header: "Số buổi tập",
        accessor: "numberOfSessions",
      },
      {
        Header: "Số buổi còn lại",
        accessor: "remainingSessions",
      },
      {
        Header: "Balance",
        accessor: "feePerSession",
        Cell: ({ value }) => {
          return (
            <Text
              py={2}
              fontSize="md"
              fontWeight="bold"
              color="teal.300"
            >
              {formatMoney(value)}
            </Text>
          );
        },
      },
      {
        Header: "Sinh nhật",
        accessor: "birthDay",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LL")}>
            {value ? dayjs(value).format("L") : ""}
          </Tooltip>
        ),
      },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Take care",
        accessor: "takeCareBy",
        Cell: ({ value }) => (
          <Link color="blue.300" to={`/user/${value?._id}`} as={RouterLink}>
            <HStack>
              <Avatar size="xs" name={value?.name} />
              <Text>{value?.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Note",
        accessor: "updatedNoteAt",
        Cell: ({ value }) => {
          return value ? (
            <Tooltip label={dayjs(value).format("LLLL")}>
              {dayjs(value).fromNow()}
            </Tooltip>
          ) : (
            <>Chưa note</>
          );
        },
      },
      {
        Header: "Tạo",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<StudentDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveStudent(row.original);
                    } else {
                      restoreStudent(row.original);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editStudent(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      <StudentModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
      />
      <Table columns={columns} data={data?.docs || []} />
    </>
  );
}

function StudentTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <StudentTable />
    </Suspense>
  );
}

export default StudentTableWrapper;
