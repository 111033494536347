import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  GridItem,
  Heading,
  Icon,
  IconButton,
  SimpleGrid,
  useDisclosure,
  Text
} from "@chakra-ui/react";
import { BiCalendarCheck } from "react-icons/bi";
import { EventDocument } from "src/api/event";
import { useParams } from "react-router";
import { useClassroom, useClassroomStudents } from "src/api/classroom";
import { differenceBy, map } from "lodash";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select
} from "@chakra-ui/react";
import SessionAttendeeItem from "./SessionAttendeeItem";
import {
  useCreateStudentEvent,
  useStudentEvents,
} from "../../api/studentEvent";
import { useDeleteStudentEvent } from "../../api/studentEvent";
import { CloseIcon } from "@chakra-ui/icons";
import { useDeleteUserEvent } from "../../api/userEvent";
import { useCreateUserEvent, useUserEvents } from "../../api/userEvent";
import { updateStudentBalanceById } from "../../api/student";
import { useResponsiveModalValue } from "src/utils/useResponsiveModal";
import { useForm } from "react-hook-form";
import { formatMoney } from "src/utils/format";
import SessionAttendeeStudent from "./SessionAttendeeStudent";
interface SessionAttendees {
  event: EventDocument;
  numOfSession?: number;
}

function Attendees({ event }: SessionAttendees) {
  const { id } = useParams<{ id: string }>();
  const classRoom = useClassroom(id);

  const createStudentEvent = useCreateStudentEvent();
  const deleteStudentEvent = useDeleteStudentEvent();
  const { data: students } = useClassroomStudents(id, { limit: 10000 });
  const { data: studentEvents } = useStudentEvents({
    event: event._id,
    limit: 1000,
  });

  const createUserEvent = useCreateUserEvent();
  const deleteUserEvent = useDeleteUserEvent();
  const userEvents = useUserEvents({ event: event._id });

  const absentStudents = differenceBy(
    students?.docs || [],
    map(studentEvents?.docs, "student") || [],
    "_id"
  );


  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCreateStudentEvent = async (e: any) => {
    const studentId = e._id;
    const eventId = event._id;
    const spend = e.feePerSession;
    await createStudentEvent.mutateAsync({ spend, student: studentId, event: eventId });
    updateStudentBalanceById(studentId)
  };

  // const handleCreateUserEvent = () => {
  //   if (classRoom.data?.salary) {
  //     createUserEvent.mutate({
  //       earn: classRoom.data?.salary,
  //       user: event.inCharge._id,
  //       event: event._id,
  //     });
  //   }
  // };

  const { register, handleSubmit, watch } = useForm();
  const handleCreateUserEvent = (data: any) => {
    const salary = parseInt(data.presentStudent) === 0 ? (classRoom.data?.salary || 0) : 70000 + 10000 * parseInt(data.presentStudent)
    createUserEvent.mutate({
      earn: salary,
      user: event.inCharge._id,
      event: event._id,
    });
    onClose();
  };


  return (
    <SimpleGrid columns={2} spacing={14}>
      <GridItem>
        <Heading mb={1} size="md" py={4} color="gray.300">
          VẮNG
        </Heading>
        <Heading mb={2} size="xs" color="gray.400">
          Huấn luyện viên
        </Heading>
        <Divider />
        <Box mb={4}>
          {!userEvents.data?.docs?.length && (
            <SessionAttendeeItem
              onClick={() => onOpen()}
              name={event.inCharge.name}
            />
          )}
        </Box>
        <Heading mb={2} size="xs" color="gray.400">
          Học viên
        </Heading>
        <Divider />
        <Box py={2}>
          {absentStudents?.map((item) => (
            <SessionAttendeeStudent
              onClick={() => handleCreateStudentEvent(item)}
              key={item._id}
              student={item}
            />
          ))}
        </Box>
      </GridItem>
      <GridItem>
        <Heading mb={1} size="md" py={4} color="gray.300">
          CÓ MẶT
        </Heading>
        <Heading mb={2} size="xs" color="gray.400">
          Huấn luyện viên
        </Heading>
        <Divider />
        <Box mb={4}>
          {!!userEvents.data?.docs?.length && (
            <SessionAttendeeItem
              name={userEvents.data.docs[0]?.user?.name}
              photoUrl={userEvents.data.docs[0]?.user?.photoUrl}
              colorScheme="gray"
              icon={<CloseIcon boxSize={2.5} />}
              onClick={() =>
                deleteUserEvent.mutate(userEvents.data.docs[0]?._id)
              }
            />
          )}
        </Box>
        <Heading mb={2} size="xs" color="gray.400">
          Học viên
        </Heading>
        <Divider />
        <Box py={2}>
          {studentEvents?.docs?.map((item) => (
            <SessionAttendeeItem
              onClick={async () => {
                await deleteStudentEvent.mutateAsync(item._id)
                updateStudentBalanceById(item.student._id)
              }}
              key={item._id}
              name={item.student.name}
              photoUrl={item.student.photoUrl}
              colorScheme="gray"
              icon={<CloseIcon boxSize={2.5} />}
            />
          ))}
        </Box>
      </GridItem>
      <Modal  {...useResponsiveModalValue()} size="sm" isOpen={isOpen} onClose={onClose} isCentered>
        <form onSubmit={handleSubmit(handleCreateUserEvent)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Xác nhận số lượng học viên</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              <Select {...register("presentStudent")}>
                <option value={0}>Mặc định</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </Select>

              <Text mt={4} fontWeight="bold" color="teal.200">
                Lương: {formatMoney((!watch("presentStudent") || parseInt(watch("presentStudent")) === 0) ?
                  (classRoom.data?.salary || 0) : 70000 + 10000 * watch("presentStudent"))}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={handleSubmit(handleCreateUserEvent)}>
                Xác nhận
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </SimpleGrid>
  );
}

function SessionAttendees({ event, numOfSession }: SessionAttendees) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        icon={<Icon boxSize={5} as={BiCalendarCheck} />}
        variant="ghost"
        colorScheme="teal"
        aria-label="delete"
        pointerEvents={event.isDisable ? "none" : "auto"}
        onClick={() => {
          if (event.isDisable) {
            return;
          }
          onOpen();
        }}
      />
      <Modal  {...useResponsiveModalValue()} size="3xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Điểm danh (Buổi {numOfSession})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Attendees event={event} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Đóng
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SessionAttendees;
