import React, { Suspense } from "react";
import { Form } from "react-final-form";
import { VStack } from "@chakra-ui/react";
import LabeledTextField from "src/components/LabeledTextField";
import { useCenters } from "../../api/center";
import { useRoles } from "../../api/role";
import LabeledSelectField from "src/components/LabeledSelectField";
import { UpdateUserDto } from "src/api/user";

interface FormLeadProps {
  onSubmit: (props: any) => void;
  initialValues?: UpdateUserDto;
  formId: string;
}

function FormUser({ onSubmit, initialValues, formId }: FormLeadProps) {
  const { data: centers } = useCenters();

  const centerOptions = (centers?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const { data: roles } = useRoles();

  const rolesOptions = (roles?.docs || []).map((item) => ({
    value: item._id,
    label: item.label,
  }));


  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <VStack id={formId} spacing={4} onSubmit={handleSubmit} as="form">
          <LabeledTextField name="name" label="Tên" required />
          <LabeledTextField name="username" type="text" label="Tên đăng nhập" required autoComplete="off" disabled />
          <LabeledTextField name="phone" type="text" label="Số điện thoại" />
          <LabeledSelectField
            options={rolesOptions}
            name="roles"
            label="Vai trò"
          />
          <LabeledTextField name="bankName" type="text" label="Ngân hàng" />
          <LabeledTextField name="bankAccount" type="text" label="Số tài khoản" />
          <LabeledTextField name="salary" type="number" label="Mức lương" />
          <LabeledTextField name="rate" type="number" label="Hệ số lương" />
          <LabeledSelectField
            options={centerOptions}
            name="center"
            label="Trung tâm"
          />

        </VStack>
      )}
    />
  );
}

function FormUserWrapper(props: FormLeadProps) {
  return (
    <Suspense fallback="loading...">
      <FormUser {...props} />
    </Suspense>
  );
}

export default FormUserWrapper;


