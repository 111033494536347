import React from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useToast,
} from "@chakra-ui/react";
import FormLead from "./FormLead";
import { createLead, LeadDto, leadKeys } from "src/api/lead";
import { useQueryClient } from "react-query";

interface LeadFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ModalAddLead({ isOpen, onClose }: LeadFormModalProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (values: LeadDto) => {
    try {
      await createLead(values);
      toast({ status: "success", description: "Thành công!", position: "top" });
      queryClient.invalidateQueries(leadKeys.all)
      onClose()
    } catch (error) {
      toast({ status: "error", description: "Lỗi!", position: "top" });
    }
  };

  return (
    <Drawer
      closeOnOverlayClick={false}
      size="md"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Thêm lead</DrawerHeader>
        <DrawerBody>
          <FormLead formId="add-lead" onSubmit={handleSubmit} />
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Hủy
          </Button>
          <Button type="submit" form="add-lead" colorScheme="blue">
            Thêm
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default ModalAddLead;
