import {
  Button,
  SimpleGrid,
  useDisclosure,
  Flex,
  HStack,
  Heading,
  Spacer,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import EventTable from "./EventTable";
import EventModalAdd from "./EventModalAdd";
import FilterByCenterWrapper from "../../components/FilterByCenter";
import FilterByEventCategory from "src/components/FilterByEventCategory";
import FilterClearButton from "src/components/FilterClearButton";
import FilterByUser from "src/components/FilterByUser";

function Event() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialFormValues, setInitialFormValues] = useState();

  return (
    <>
      <EventModalAdd
        initialValues={initialFormValues}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Lịch
          </Heading>
          <Spacer />
          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Thêm sự kiện
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByCenterWrapper />
                <FilterByEventCategory />
                <FilterByUser name="inCharge" label="Phụ trách" />
                <FilterClearButton />
              </HStack>
              <Spacer />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1}>
            <EventTable
              onOpenAddForm={onOpen}
              setInitialFormValues={setInitialFormValues}
            />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default Event;
