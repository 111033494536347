import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useStudents } from "../../api/student";
import FilterByPage from "src/components/FilterByPage";

function StudentPaging() {
  const { params } = useSearchParams();
  const { data } = useStudents(params);

  return (
    <FilterByPage totalDocs={data?.totalDocs} totalPages={data?.totalPages} />
  );
}

function StudentPagingWrapper() {
  return (
    <Suspense fallback="">
      <StudentPaging />
    </Suspense>
  );
}

export default StudentPagingWrapper;
