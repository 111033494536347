import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  useDisclosure,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Form } from "react-final-form";
import LabeledTextField from "src/components/LabeledTextField";
import { CenterDto, createCenter, centerKeys } from "../../api/center";
import { useQueryClient } from "react-query";

function AddCenterForm() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CenterDto) => {
    try {
      await createCenter(values);
      onClose();
      queryClient.invalidateQueries(centerKeys.all);
      toast({
        description: "Thành công",
        status: "success",
      });
    } catch (error) {
      toast({
        description: "Lỗi",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen} leftIcon={<AddIcon />}>
        Thêm Trung Tâm
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        closeOnOverlayClick={false}
        size="md"
      >
        <DrawerOverlay />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Thêm Trung Tâm</DrawerHeader>
                <DrawerBody>
                  <VStack spacing={4}>
                    <LabeledTextField name="name" label="Tên" />
                    <LabeledTextField name="address" label="Địa chỉ" />
                  </VStack>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Hủy
                  </Button>
                  <Button type="submit" colorScheme="blue">
                    Thêm
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </form>
          )}
        />
      </Drawer>
    </>
  );
}

export default AddCenterForm;
