import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { UserDocument } from "./user";
import { CenterDocument } from "./center";
import { EventCategoryDocument } from "./eventCategory";

export interface CreateEventDto {
  name: string;
  description?: string;
  start?: string;
  end?: string;
  inCharge?: string;
  center?: string;
  category?: string;
  isDisable?: boolean;
  classroom?: string;
}

export interface GetEventDto extends Partial<CreateEventDto> {
  startDate?: string;
  endDate?: string;
  dateKey?: string;
}

export interface UpdateEventDto extends Partial<CreateEventDto> {
  status?: Status;
  isDisable?: boolean;
}

export interface EventDocument extends TimeStamp, ObjectId {
  status: Status;
  name: string;
  description?: string;
  start: string;
  end: string;
  inCharge: UserDocument;
  center: CenterDocument;
  category: EventCategoryDocument;
  isDisable?: boolean;
}

interface EventStatus {
  activeEventCount: number;
  disableEventCount: number;
}

// api
export const createEvent = async (createEventDto: CreateEventDto) => {
  const { data } = await axios.post("/event", createEventDto);
  return data;
};

export const createManyEvent = async (createEventsDto: CreateEventDto[]) => {
  const { data } = await axios.post("/event/create-many", createEventsDto);
  return data;
};

export const getEvents = async (params?: QueryOptionParams & GetEventDto) => {
  const { data } = await axios.get<
    PaginateDocument<EventDocument> & EventStatus
  >("/event", {
    params: { ...params },
  });
  return data;
};

export const updateEvent = async (
  id: string,
  updateEventDto: UpdateEventDto
) => {
  const { data } = await axios.patch(`/event/${id || ""}`, updateEventDto);
  return data;
};

// hooks
export const eventKeys = generateQueryKey("event");

export const useEvents = (
  params?: QueryOptionParams & GetEventDto,
  enabled?: boolean
) => {
  return useQuery(eventKeys.list(params), () => getEvents(params), {
    enabled,
    staleTime: Infinity,
  });
};

export const useAddSessions = (classRoomId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (numberOfSessions: number) =>
      axios.post("/event/sessions", {
        classRoomId,
        numberOfSessions,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(eventKeys.all),
    }
  );
};

export const useCreateManyEvents = () => {
  const queryClient = useQueryClient();
  return useMutation(createManyEvent, {
    onSuccess: () => queryClient.invalidateQueries(eventKeys.all),
  });
};

export const useCancelEvent = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return axios.patch(`/event/cancel/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(eventKeys.all);
        onSuccess?.();
      },
    }
  );
};

interface useRemoveEventProps {
  onSuccess?: () => void;
}

export const useRemoveEvent = ({ onSuccess }: useRemoveEventProps = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => {
      return axios.delete(`/event/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(eventKeys.all);
        onSuccess?.();
      },
    }
  );
};
