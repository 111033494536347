import React from "react";
import { Suspense } from "react";
import { useCurrentUser } from "../api/auth";
import { intersection } from "lodash";
import { Icon, VStack, Button, Tooltip } from "@chakra-ui/react";
import {
  HiOutlineLibrary,
  HiOutlineUser,
  HiOutlineHome,
} from "react-icons/hi";
import { MdChildCare } from "react-icons/md";
import { AiOutlineLineChart, AiOutlineTeam } from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { Role } from "../types/Role";
import { map } from "lodash";
import { BiTable, BiMoney } from "react-icons/bi";
import dayjs from "dayjs";
import queryString from "query-string";
import { GiBodyHeight } from "react-icons/gi";

const thisDayProfit = {
  startDate: dayjs().startOf("day").toISOString(),
  endDate: dayjs().endOf("day").toISOString(),
  dateKey: "createdAt"
};

const thisMonthTransactions = {
  startDate: dayjs().startOf("month").toISOString(),
  endDate: dayjs().endOf("month").toISOString(),
  dateKey: "date",
};

interface NavLink {
  pathname: string;
  label: string;
  icon: React.ReactNode;
  roles?: Role[];
  search?: string;
}

const links: NavLink[] = [
  {
    pathname: "/",
    label: "Trang chủ",
    icon: HiOutlineHome,
    roles: [Role.User, Role.Admin, Role.SuperAdmin],
  },
  {
    pathname: "/profit",
    label: "Doanh thu",
    icon: AiOutlineLineChart,
    roles: [Role.Admin, Role.SuperAdmin],
    search: `?${queryString.stringify(thisDayProfit)}`,
  },
  {
    pathname: "/take-care",
    label: "Take care HV",
    icon: GiBodyHeight,
    roles: [Role.User, Role.Admin, Role.SuperAdmin],
    search: "",
  },


  // {
  //   pathname: "/calendar",
  //   label: "Lịch",
  //   icon: HiOutlineCalendar,
  //   roles: [Role.User, Role.Admin, Role.SuperAdmin],
  // },
  {
    pathname: "/student",
    label: "Học viên",
    icon: MdChildCare,
    roles: [Role.User, Role.Admin, Role.SuperAdmin],
    search: "?page=1",
  },

  // {
  //   pathname: "/skill",
  //   label: "Kĩ năng",
  //   icon: HiOutlineStar,
  //   roles: [Role.Admin, Role.SuperAdmin],
  //   search: "?page=1",
  // },
  {
    pathname: "/classroom",
    label: "Lớp học",
    icon: IoSchoolOutline,
    roles: [Role.User, Role.Admin, Role.SuperAdmin],
  },
  {
    pathname: "/lead",
    label: "Lead",
    icon: AiOutlineTeam,
    roles: [Role.User, Role.Admin, Role.SuperAdmin],
    search: `?page=1&orderBy=-1&sortBy=updatedAt`,
  },
  {
    pathname: "/user",
    label: "Nhân viên",
    icon: HiOutlineUser,
    roles: [Role.Admin, Role.SuperAdmin],
  },
  // {
  //   pathname: "/time-sheets",
  //   label: "Chấm công",
  //   icon: BiTable,
  //   roles: [Role.User, Role.Admin, Role.SuperAdmin],
  //   search: `?${queryString.stringify(thisMonthTimeSheet)}`,
  // },
  {
    pathname: "/transactions",
    label: "Giao dịch",
    icon: BiMoney,
    roles: [Role.Admin, Role.SuperAdmin],
    search: `?${queryString.stringify(thisMonthTransactions)}`,
  },

  {
    pathname: "/center",
    label: "Trung tâm",
    icon: HiOutlineLibrary,
    roles: [Role.Admin, Role.SuperAdmin],
  },
  // {
  //   pathname: "/voucher",
  //   label: "Voucher",
  //   icon: HiOutlineTicket,
  //   roles: [Role.User, Role.Admin, Role.SuperAdmin],
  // },

  // {
  //   pathname: "/class-category",
  //   label: "Môn học",
  //   icon: HiOutlineBookOpen,
  //   roles: [Role.User, Role.Admin, Role.SuperAdmin],
  // },
  // {
  //   pathname: "/apply",
  //   label: "Đăng kí",
  //   icon: HiDocumentText,
  //   roles: [Role.User, Role.Admin, Role.SuperAdmin],
  // },
];

interface SideBarProps {
  isOpen: boolean;
}

const isActive = (locationPath: string, sideBarItem: string) => {
  if (sideBarItem === "/") return locationPath === sideBarItem;
  return locationPath.includes(sideBarItem);
};

function SideBar({ isOpen }: SideBarProps) {
  const { data: user } = useCurrentUser();
  const userRoles = user?.roles || [];
  const location = useLocation();

  const authorizedLinks = links.filter(
    (link) => intersection(map(userRoles, "value"), link.roles).length
  );

  return (
    <VStack spacing={0} align="stretch">
      {authorizedLinks.map((item, i) => (
        <Link
          key={i}
          style={{ width: "100%" }}
          to={{ pathname: item.pathname, search: item.search }}
        >
          <Tooltip placement="right" label={item.label}>
            <Button
              size="md"
              leftIcon={<Icon boxSize={6} as={item.icon} />}
              variant="sidebar"
              w="full"
              state={isActive(location.pathname, item.pathname) ? "active" : ""}
            >
              {isOpen ? item.label : ""}
            </Button>
          </Tooltip>
        </Link>
      ))}
    </VStack>
  );
}

function SideBarWrapper(props: SideBarProps) {
  return (
    <Suspense fallback="">
      <SideBar {...props} />
    </Suspense>
  );
}

export default SideBarWrapper;
