import React, { Suspense, useState } from "react";
import { EventDocument, useEvents } from "../../api/event";
import {
  Box,
  useDisclosure,
  useToken,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import EventModalEdit from "./EventModalEdit";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import colors from "src/theme/src/foundations/colors";

function renderEventContent(eventInfo) {
  const isTimeGrid = eventInfo.view.type.includes("time");
  const color = eventInfo.backgroundColor;
  const isDisable = eventInfo.event.extendedProps?.isDisable;
  return (
    <Flex
      flexDir={isTimeGrid ? "column" : "row"}
      justify="start"
      align={isTimeGrid ? "start" : "center"}
      w="full"
      textOverflow="ellipsis"
      color="gray.200"
      borderRadius="sm"
      px={1}
      textDecoration={isDisable ? "line-through" : "none"}
      opacity={isDisable ? "0.6" : "1"}
    >
      {!isTimeGrid && (
        <Box
          mr={2}
          flexShrink={0}
          w={2.5}
          h={2.5}
          bg={color}
          borderRadius="full"
        />
      )}
      <Text mr={2}>{eventInfo.timeText}</Text>
      <Text
        textOverflow="ellipsis"
        overflow="hidden"
        flex={1}
        fontWeight="bold"
      >
        {eventInfo.event.title}
      </Text>
    </Flex>
  );
}

interface EventTableProps {
  setInitialFormValues?: (data: any) => void;
  onOpenAddForm?: () => void;
}

function EventTable(props: EventTableProps) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { params } = useSearchParams();
  const { data } = useEvents(
    { startDate, endDate, dateKey: "start", ...params },
    !!(startDate && endDate)
  );
  const [editingData, setEditingData] = useState<EventDocument>({});
  const [gray700] = useToken("colors", ["gray.700"]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const events = React.useMemo(() => {
    return data?.docs.map((item) => ({
      ...item,
      title: item.name,
      id: item._id,
      extendedProps: item,
      backgroundColor: colors?.[item?.category?.color]?.[600],
    }));
  }, [data]);

  const editEvent = (event: EventDocument) => {
    onOpen();
    setEditingData(event);
  };

  return (
    <>
      <EventModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
        {...props}
      />
      <Box
        sx={{
          "--fc-border-color": gray700,
        }}
        h="100%"
        bg="gray.800"
        p={5}
        pt={3}
        borderRadius="md"
        d="flex"
        flexDir="column"
      >
        <HStack pb={2} fontSize="sm" spacing={6}>
          <Text>
            Active Events:{" "}
            <Text color="teal.300" as="b">
              {data?.activeEventCount}
            </Text>
          </Text>
          <Text>
            Disable Events:{" "}
            <Text color="red.300" as="b">
              {data?.disableEventCount}
            </Text>
          </Text>
        </HStack>
        <Box flex={1}>
          <FullCalendar
            headerToolbar={{
              left: "prev,next,today",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay",
            }}
            firstDay={1}
            buttonText={{
              today: "Hôm nay",
              month: "Tháng",
              week: "Tuần",
              day: "Ngày",
            }}
            plugins={[dayGridPlugin]}
            initialView="dayGridWeek"
            locale="vi"
            height="100%"
            events={events}
            eventContent={renderEventContent}
            eventClick={(e) => {
              e.jsEvent.preventDefault();
              const data: EventDocument = e.event.extendedProps;
              editEvent(data);
            }}
            datesSet={(e) => {
              setStartDate(e.startStr);
              setEndDate(e.endStr);
            }}
          />
        </Box>
      </Box>
    </>
  );
}

function EventTableWrapper(props: EventTableProps) {
  return (
    <Suspense fallback={<Box flex={1} />}>
      <EventTable {...props} />
    </Suspense>
  );
}

export default EventTableWrapper;
