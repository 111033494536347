import {
  Avatar,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";

interface SessionAttendeeItemProps {
  name?: string;
  photoUrl?: string;
  onClick?: () => void;
  colorScheme?: string;
  icon?: React.ReactNode;
}

function SessionAttendeeItem({
  name = "Name",
  photoUrl = "",
  onClick,
  icon,
  colorScheme,
}: SessionAttendeeItemProps) {
  return (
    <HStack p={2} _hover={{ bg: "whiteAlpha.100" }} borderRadius="md">
      <Avatar name={name} size="sm" src={photoUrl} />
      <Text fontWeight={600} fontSize="sm">
        {name}
      </Text>
      <Spacer />
      <IconButton
        aria-label="move"
        icon={icon || <Icon boxSize={5} as={BiRightArrowAlt} />}
        variant="outline"
        size="xs"
        onClick={onClick}
        colorScheme={colorScheme}
      />
    </HStack>
  );
}

export default SessionAttendeeItem;
