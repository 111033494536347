import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { Column } from "react-table";
import FilterByTime from "src/components/FilterByTime";
import Table from "src/components/Table";
import { formatMoney } from "src/utils/format";
import { Link as RouterLink, useParams } from "react-router-dom";
import useSearchParams from "src/utils/useSearchParams";
import { useStudentEvents } from "src/api/studentEvent";
import { sortBy, reverse } from "lodash";
import { useStudent } from "src/api/student";

function History() {
  const { id } = useParams<{ id: string }>();
  const { params } = useSearchParams();
  const { data } = useStudent(id);
  const studentEvents =
    useStudentEvents({ student: id, limit: 100000 }).data?.docs || [];

  const filteredStudentEvents = useMemo(() => {
    const data = studentEvents.filter((item) => {
      const start = params.startDate;
      const end = params.endDate;

      if (start && end) {
        const eventStart = dayjs(item.event.start);
        return eventStart.isBetween(start, dayjs(end).endOf("d"));
      }

      return true;
    });

    return reverse(sortBy(data, "event.start"));


  }, [studentEvents, params.startDate, params.endDate]);

  // const totalSpend = sumBy(filteredStudentEvents, "spend");

  // const getTotalSpendText = () => {
  //   const startDate = params?.startDate;
  //   const endDate = params?.endDate;
  //   if (startDate && endDate) {
  //     return `Tổng chi: (${dayjs(startDate).format("L")} - ${dayjs(
  //       endDate
  //     ).format("L")}): `;
  //   }
  //   return `Tổng chi:`;
  // };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Buổi",
        Cell: ({ row }) => (filteredStudentEvents?.length || 0) - row.index,
      },
      {
        Header: "Ngày",
        accessor: "event.start",
        Cell: ({ value }) => dayjs(value).format("D-MM-YYYY (ddd)"),
      },
      {
        Header: "Giờ",
        Cell: ({ row }) => {
          const event = row.original?.event;
          const start = dayjs(event?.start).format("HH:mm");
          const end = dayjs(event?.end).format("HH:mm");
          return (
            <Stack py={1}>
              <Text fontWeight="bold">{`${start} - ${end}`}</Text>
            </Stack>
          );
        },
      },
      {
        Header: "Lớp",
        accessor: "event.classroom",
        Cell: ({ value }) => (
          <Link
            as={RouterLink}
            to={`/classroom/${value?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {value?.name}
          </Link>
        ),
      },
      {
        Header: "HLV",
        accessor: "event.inCharge",
        Cell: ({ value }) => (
          <Link
            as={RouterLink}
            to={`/user/${value?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {value?.name}
          </Link>
        ),
      },
      {
        Header: () => (
          <Text mt={4} textAlign={"right"}>
            Học phí
          </Text>
        ),
        accessor: "spend",
        Cell: ({ value }) => (
          <Text
            py={2}
            fontSize="md"
            fontWeight="bold"
            color={"red.200"}
            textAlign="right"
          >
            {formatMoney(value)}
          </Text>
        ),
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <Box bg="gray.800" p={4} borderRadius="md">
      <Flex mb={4}>
        <HStack>
          <FilterByTime name="createdAt" label="Thời gian" />
        </HStack>
        <Spacer />
        <HStack bg="gray.700" px={3} borderRadius="md" mr={4}>
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            Số buổi còn lại:
          </Text>
          <Text color="green.200">{data?.remainingSessions}</Text>
        </HStack>
        <HStack bg="gray.700" px={3} borderRadius="md" fontWeight="bold" fontSize="sm" mr={4}>
          <Text ml={2}>
            Chi tiết:
          </Text>
          <Text color="red.200">
            {studentEvents.length}
            {' '}
          </Text>
          <Text color="green.200">
            /
            {' '}
            {data?.numberOfSessions}
          </Text>
        </HStack>
        {/* <HStack bg="gray.700" px={3} borderRadius="md">
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            {getTotalSpendText()}
          </Text>
          <Text color="red.200">{formatMoney(totalSpend)}</Text>
        </HStack> */}
      </Flex>
      <Divider />
      <Table columns={columns} data={filteredStudentEvents} />
    </Box>
  );
}

export default History;
