import React from "react";
import { useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverFooter,
  ButtonGroup,
  RadioGroup,
  SimpleGrid,
  Radio,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { DateKey } from "src/types/DateKey";
import { Sort } from "src/types/Sort";

const OPTIONS = [
  {
    orderBy: Sort.Desc,
    sortBy: DateKey.CreatedAt,
    text: "Tạo mới nhất ",
  },
  {
    orderBy: Sort.Asc,
    sortBy: DateKey.CreatedAt,
    text: "Tạo cũ nhất ",
  },
  {
    orderBy: Sort.Desc,
    sortBy: DateKey.UpdatedAt,
    text: "Cập nhật mới nhất ",
  },
  {
    orderBy: Sort.Asc,
    sortBy: DateKey.UpdatedAt,
    text: "Cập nhật cũ nhất ",
  },
  {
    orderBy: Sort.Desc,
    sortBy: DateKey.UpdatedNoteAt,
    text: "Note mới nhất ",
  },
  {
    orderBy: Sort.Asc,
    sortBy: DateKey.UpdatedNoteAt,
    text: "Note cũ nhất ",
  },
];

function SortByCreatedTime() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [orderBy, setOrderBy] = useQueryParam("orderBy", StringParam);
  const [sortBy, setSortBy] = useQueryParam("sortBy", StringParam);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setOption = (value: string) => {
    setSelectedIndex(parseInt(value));
    setOrderBy(OPTIONS[parseInt(value)].orderBy);
    setSortBy(OPTIONS[parseInt(value)].sortBy);
  };

  useEffect(() => {
    if (orderBy && sortBy) {
      const selectedIndex = OPTIONS.findIndex(
        (option) => option.orderBy === orderBy && option.sortBy === sortBy
      );
      setSelectedIndex(selectedIndex);
    }
  }, [orderBy,sortBy ]);

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      // initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={selectedIndex !== -1 ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedIndex !== -1 ? OPTIONS[selectedIndex].text : "Sắp xếp"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Sắp xếp theo </PopoverHeader>
        <PopoverBody>
          <RadioGroup onChange={setOption} value={selectedIndex}>
            <SimpleGrid borderWidth="1px" columns={1} border="none">
              {OPTIONS.map((option, index) => (
                <Radio
                  size="sm"
                  cursor="pointer"
                  my={2}
                  value={index}
                  key={index}
                >
                  {option.text}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setSelectedIndex(-1);
                // searchParams.delete("orderBy");
                // searchParams.delete("sort");
                // setSearchParams(searchParams);
                setOrderBy("");
                setSortBy("");
                onClose();
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default SortByCreatedTime;
