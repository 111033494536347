import Event from "./containers/event";
import ClassRoom from "./containers/classroom";
import ClassRoomDetail from "./containers/classRoomDetail";
import Center from "src/containers/center";
import ClassCategory from "src/containers/classCategory";
import Users from "./containers/users/index";
import Lead from "./containers/lead/index";
import Student from "./containers/student/index";
import TakeCare from "./containers/takeCare/index";
import StudentDetail from "./containers/studentDetail/index";
import UserDetailWrapper from "./containers/userDetail";
import TimeSheets from "./containers/timeSheets";
import Transactions from "./containers/transactions";
import Home from "./containers/home";
import Profit from "./containers/profit/index";
import CenterDetail from "./containers/centerDetail";
import TransactionReceipt from "./containers/transactionReceipt";
import DefaultLayout from "./layouts/DefaultLayout";
import LeadDetail from "./containers/leadDetail/index";
import Skill from "./containers/skill/index";

export interface RouteConfigItem {
  path: string;
  component: any;
  exact?: boolean;
  routes?: Array<RouteConfigItem>;
  layout?: React.ReactNode;
}

const routes: RouteConfigItem[] = [
  {
    path: "/take-care",
    component: TakeCare,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/calendar",
    component: Event,
    layout: DefaultLayout,
  },
  {
    path: "/class-category",
    component: ClassCategory,
    layout: DefaultLayout,
  },
  {
    path: "/",
    component: Home,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/student",
    component: Student,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/skill",
    component: Skill,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/student/:id",
    component: StudentDetail,
    layout: DefaultLayout,
  },
  {
    path: "/classroom",
    component: ClassRoom,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/classroom/:id",
    component: ClassRoomDetail,
    layout: DefaultLayout,
  },
  {
    path: "/center",
    component: Center,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/center/:id",
    component: CenterDetail,
    layout: DefaultLayout,
  },
  {
    path: "/user",
    component: Users,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/user/:id",
    component: UserDetailWrapper,
    layout: DefaultLayout,
  },
  {
    path: "/lead",
    component: Lead,
    layout: DefaultLayout,
    exact: true,
  },
  {
    path: "/lead/:id",
    component: LeadDetail,
    layout: DefaultLayout,
  },
  {
    path: "/time-sheets",
    component: TimeSheets,
    layout: DefaultLayout,
  },
  {
    path: "/transactions",
    component: Transactions,
    exact: true,
    layout: DefaultLayout,
  },
  {
    path: "/transactions/:id/receipt",
    component: TransactionReceipt,
    layout: ({ children }) => children,
  },
  {
    path: "/profit",
    component: Profit,
    layout: DefaultLayout,
  },
];

export default routes;
