import { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = (props) => {
  return {
    fontSize: "md",
    marginEnd: 3,
    mb: 2,
    fontWeight: "semibold",
    transitionProperty: "common",
    transitionDuration: "normal",
    opacity: 1,
    color: mode("gray.800", "gray.100")(props),
    _disabled: {
      opacity: 0.4,
    },
  };
};

export default {
  baseStyle,
};
