import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  FormHelperText,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { forwardRef, PropsWithoutRef } from "react";
import { useField } from "react-final-form";

export interface LabeledNumberFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label: string;
  /** Field label. */
  required?: boolean;
  autoComplete?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number" | "date" | "time";
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  isShowStepper?: boolean;
}

export const LabeledNumberField = forwardRef<
  HTMLInputElement,
  LabeledNumberFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      placeholder,
      type,
      helperText,
      disabled,
      required,
      autoComplete,
      isShowStepper,
      ...props
    },
    ref
  ) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name, {
      parse: type === "number" ? Number : undefined,
    });
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb="1.5">
          {label}
        </FormLabel>
        <InputGroup>
          <NumberInput
            variant="filled"
            size="sm"
            w="full"
            {...input}
            disabled={submitting || disabled}
            required={required}
            ref={ref}
            placeholder={placeholder}
            {...props}
          >
            <NumberInputField fontWeight="medium" />
            {isShowStepper && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </NumberInput>
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default LabeledNumberField;
