import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import ClassroomForm from "./ClassroomForm";
import {
  CreateClassroomDto,
  createClassroom,
  classroomKeys,
} from "../../api/classroom";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-classroom";

interface ClassroomModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function ClassroomModalAdd({ isOpen, onClose }: ClassroomModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateClassroomDto) => {
    try {
      await createClassroom(values);
      onClose()
      toast({ status: "success", description: "Thêm lớp thành công" });
      queryClient.invalidateQueries(classroomKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thêm lớp học</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ClassroomForm onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Thêm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ClassroomModalAdd;
