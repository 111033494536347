export interface AbilityScore {
  value: number;
  label: string;
}

const flexibleScores: AbilityScore[] = [
  {
    value: 5,
    label: "Ép rất sâu, có thể tập linh hoạt mức khó",
  },
  {
    value: 4,
    label: "Ép được sâu",
  },
  {
    value: 3,
    label: "Có thể thực hiện nhưng linh hoạt còn hạn chế",
  },
  {
    value: 2,
    label: "Cơ thể căng cứng, ép bị đau / khó thực hiện",
  },
  {
    value: 1,
    label: "Rất cứng, Không thể thực hiện động tác",
  },
];

export const abilities = {

  title1: { label: "1.Khả năng vận động cơ thể" },
  height: {
    key: "height",
    label: "Chiều cao",
  },
  weight: {
    key: "weight",
    label: "Cân nặng",
  },
  // belly: {
  //   key: "belly",
  //   label: "Vòng Bụng",
  // },
  // balance: {
  //   key: "balance",
  //   label: "Khả năng thăng bằng/tập trung",
  //   scores: [
  //     {
  //       value: 5,
  //       label: "Có thể thực hiện các động tác thăng bằng khó",
  //     },
  //     {
  //       value: 4,
  //       label: "Giữ thăng bằng tốt, tập trung",
  //     },
  //     {
  //       value: 3,
  //       label: "Rung khi thực hiện động tác hoặc chưa tập trung",
  //     },
  //     {
  //       value: 2,
  //       label: "Có khả năng giữ thăng bằng tuy nhiên cần sự trợ giúp",
  //     },
  //     {
  //       value: 1,
  //       label: "Dù được trợ giúp nhưng vẫn không thể giữ thăng bằng",
  //     },
  //   ],
  // },
  // jump: {
  //   key: "jump",
  //   label: "Khả năng bật nhảy, tiếp đất",
  //   scores: [
  //     {
  //       value: 5,
  //       label: "Sức bật vượt trội",
  //     },
  //     {
  //       value: 4,
  //       label: "Sức bật tốt, tiếp đất nhẹ nhàng",
  //     },
  //     {
  //       value: 3,
  //       label: "Sức bật ở mức trung bình",
  //     },
  //     {
  //       value: 2,
  //       label: "Sức bật yếu , chưa hiểu động tác , còn tách chân và chụm gối",
  //     },
  //     {
  //       value: 1,
  //       label: "Không thể bật nhảy",
  //     },
  //   ],
  // },
  // hold: {
  //   key: "hold",
  //   label: "Khả năng cầm. nắm, treo",
  //   scores: [
  //     {
  //       value: 5,
  //       label: "Nắm giữ rất tốt, kiểm soát cơ thể tốt (>30s)",
  //     },
  //     {
  //       value: 4,
  //       label: "Nắm giữ khá tốt ( 15-30s)",
  //     },
  //     {
  //       value: 3,
  //       label: "Nắm/giữ trung bình (5-15s)",
  //     },
  //     {
  //       value: 2,
  //       label: "Nắm/giữ chưa lâu, nhanh mỏi (<5s)",
  //     },
  //     {
  //       value: 1,
  //       label: "Không thể nắm/giữ",
  //     },
  //   ],
  // },
  // armMuscle: {
  //   key: "armMuscle",
  //   label: "Khả năng đẩy của cơ tay",
  //   scores: [
  //     {
  //       value: 5,
  //       label: "Có thể thực hiện các động tác đẩy nâng cao",
  //     },
  //     {
  //       value: 4,
  //       label: "Tay chắc, giữ động tác tốt và kiểm soát",
  //     },
  //     {
  //       value: 3,
  //       label: "Giữ động tác chưa kiểm soát, còn rung",
  //     },
  //     {
  //       value: 2,
  //       label: "Cần sự trợ giúp khi giữ động tác",
  //     },
  //     {
  //       value: 1,
  //       label: "Tay yếu, không có sức chống",
  //     },
  //   ],
  // },
  // endurance: {
  //   key: "endurance",
  //   label: "Sức bền/tốc độ",
  //   scores: [
  //     {
  //       value: 5,
  //       label:
  //         "Tốt độ rất nhanh, hãm lực tốt, dáng chạy thoải mái, sức bền tốt",
  //     },
  //     {
  //       value: 4,
  //       label: "Tốc độ nhanh nhưng chưa bền",
  //     },
  //     {
  //       value: 3,
  //       label: "Tốc độ chậm nhưng có thể duy trì vận động tốt",
  //     },
  //     {
  //       value: 2,
  //       label: "Chạy rất chậm, nhanh bị hụt hơi",
  //     },
  //     {
  //       value: 1,
  //       label: "Không thể chạy",
  //     },
  //   ],
  // },
  title2: { label: "2. Nhận xét" },
  // createdBy: {
  //   key: "createdBy",
  //   label: "Người tạo"
  // },
  review: {
    key: "review",
    label: "Đánh giá",
  },
  goal: {
    key: "goal",
    label: "Mục tiêu",
  },
  // wristShoulder: {
  //   key: "wristShoulder",
  //   label: "Linh hoạt cổ tay vai",
  //   scores: flexibleScores,
  // },
  // hip: {
  //   key: "hip",
  //   label: "Linh hoạt hông",
  //   scores: flexibleScores,
  // },
  // kneeAnkle: {
  //   key: "kneeAnkle",
  //   label: "Linh hoạt gối, cổ chân",
  //   scores: flexibleScores,
  // },
  // headDeviation: {
  //   key: "headDeviation",
  //   label: "Lệch đầu/cổ",
  // },
  // shoulderDeviation: {
  //   key: "shoulderDeviation",
  //   label: "Lệch vai",
  // },
  // spinalDeviation: {
  //   key: "spinalDeviation",
  //   label: "Lệch cột sống",
  // },
  // hipDeviation: {
  //   key: "hipDeviation",
  //   label: "Lệch hông",
  // },
  // kneeDeviation: {
  //   key: "kneeDeviation",
  //   label: "Đầu gối chụm/mở quá mức",
  // },
  // flatFeet: {
  //   key: "flatFeet",
  //   label: "Bàn chân bẹt",
  // },
};
