
import React, { Suspense } from "react";
import {
  IconButton,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import useSearchParams from "../../utils/useSearchParams";
import dayjs from "dayjs";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  useTransactions,

} from "../../api/transaction"
function TransactionDownload() {
  const { params } = useSearchParams();
  const { data } = useTransactions({ ...params, orderBy: "-1" });
  let csvData = [[]] as any;
  if (data?.docs) {
    let total = 0;
    csvData = data?.docs.map((d) => {
      const obj = {} as any;
      obj.student = d.student.name;
      obj.amount = d.amount;
      obj.sale = d.sale.name;
      obj.date = dayjs(d.date).format("LL");
      obj.createdAt = dayjs(d.createdAt).format("LL");
      total += d.amount;
      return obj;
    });
    csvData.push({ amount: total });
  }
  console.log(csvData);

  return (
    <CSVLink data={csvData} filename={"transaction.csv"}>
      <IconButton
        mr={8}
        colorScheme="blue"
        aria-label="Search database"
        icon={<DownloadIcon />}
      />
    </CSVLink>
  );
}

function TransactionDownloadWrapper() {
  return (
    <Suspense fallback="loading...">
      <TransactionDownload />
    </Suspense>
  );
}

export default TransactionDownloadWrapper;

