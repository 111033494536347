import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { CenterDocument } from "./center";
import { Status } from "../../../ninjakids-server/src/types";
import { StudentDocument, GetStudentDto, studentKeys } from "./student";
import { UserDocument } from "./user";
import { ScheduleDocument, UpdateScheduleDto } from "./schedule";
import { useToast } from "@chakra-ui/react";

export interface CreateClassroomDto {
  name: string;
  description: string;
  center: string;
  coach: string;
  salary: number;
  schedule?: string;
}
export interface GetClassroomDto extends Partial<CreateClassroomDto> {
  status?: Status;
}

export interface UpdateClassroomDto extends Partial<CreateClassroomDto> {
  status?: Status;
}

export interface ClassroomDocument extends TimeStamp, ObjectId {
  status: Status;
  name: string;
  description: string;
  center: CenterDocument;
  coach: UserDocument;
  salary: number;
  schedule: ScheduleDocument;
}

export interface AddStudentDto {
  student: string;
  feePerSession?: number;
}

export interface ClassStudentDocument extends StudentDocument {
  feePerSession: number;
}

// api
export const createClassroom = async (
  createClassroomDto: CreateClassroomDto
) => {
  const { data } = await axios.post("/classroom", createClassroomDto);
  return data;
};

export const getClassrooms = async (
  params?: QueryOptionParams & GetClassroomDto
) => {
  const { data } = await axios.get<PaginateDocument<ClassroomDocument>>(
    "/classroom",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

export const getClassroom = async (id: string) => {
  const { data } = await axios.get<ClassroomDocument>(`/classroom/${id}`);
  return data;
};

export const updateClassroom = async (
  id: string,
  updateClassroomDto: UpdateClassroomDto
) => {
  const { data } = await axios.patch(
    `/classroom/${id || ""}`,
    updateClassroomDto
  );
  console.log("runnnnnnn");
  return data;
};

export const getClassroomStudents = async (
  id: string,
  params?: QueryOptionParams & GetStudentDto
) => {
  const { data } = await axios.get<
    PaginateDocument<ClassStudentDocument & { feePerSession: number }>
  >(`/classroom/${id}/student`, { params });
  return data;
};

export const deleteClassroomStudent = async (id: string, studentId: string) => {
  const { data } = await axios.patch(`/classroom/${id}/student`, { studentId });
  return data;
};

// hooks
export const classroomKeys = generateQueryKey("classroom");
export const classroomStudentKeys = generateQueryKey("classroom-student");

export const useClassrooms = (params?: QueryOptionParams & GetClassroomDto) => {
  return useQuery(classroomKeys.list(params), () => getClassrooms(params));
};

export const useClassroom = (id: string) => {
  return useQuery(classroomKeys.detail(id), () => getClassroom(id));
};

export const useClassroomStudents = (
  id: string,
  params: QueryOptionParams & GetStudentDto = {}
) => {
  return useQuery(classroomStudentKeys.list({ ...params, id }), () =>
    getClassroomStudents(id, params)
  );
};

export const useAddClassroomStudent = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: AddStudentDto) =>
      axios.patch(`/classroom/${id}/student`, values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(classroomStudentKeys.all);
        queryClient.invalidateQueries(studentKeys.all);
        queryClient.invalidateQueries(classroomKeys.all);
      },
    }
  );
};

export const useDeleteClassroomStudent = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { studentId: string }) =>
      axios
        .patch(`/classroom/${id}/student/delete`, values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(classroomStudentKeys.all);
        queryClient.invalidateQueries(studentKeys.all);
        queryClient.invalidateQueries(classroomKeys.all);
      },
    }
  );
};

export const useUpdateClassroomStudent = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { student: string; feePerSession: number }) =>
      axios
        .patch(`/classroom/${id}/student/update`, values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(classroomStudentKeys.all);
        queryClient.invalidateQueries(studentKeys.all);
      },
    }
  );
};

export const useCreateClassroomSchedule = (id: string, { onSuccess } = {}) => {
  const toast = useToast({ position: "top" });

  return useMutation(
    (values: UpdateScheduleDto) =>
      axios.patch(`/classroom/${id}/schedule`, values).then((res) => res.data),
    {
      onError: () => {
        toast({ status: "error", description: "Điền đầy đủ các trường" });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Cập nhật thành công" });
        onSuccess?.();
      },
    }
  );
};
