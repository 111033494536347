import React, { Suspense } from "react";
import StudentAddModal from "./StudentAddModal";
import { GridItem, SimpleGrid, Spinner, Stack } from "@chakra-ui/react";
import { useClassroomStudents } from "src/api/classroom";
import { useParams } from "react-router";
import StudentItem from "./StudentItem";
import generateQueryKey from "src/utils/queryKeyFactory";

export const studentKeys = generateQueryKey("student");

function List() {
  const { id } = useParams<{ id: string }>();
  const { data: students } = useClassroomStudents(id, { limit: 10000 });
  // const ids = students?.docs?.map(e => e._id) || []
  // const userQueries = useQueries(
  //   ids.map(id => {
  //     return {
  //       queryKey: studentKeys.detail(id),
  //       queryFn: () => updateStudentBalanceById(id), 
  //       retry: false,
  //       staleTime: Infinity,
  //       enabled: isFetched
  //     }
  //   })
  // )

  return (
    <Stack>
      {students?.docs?.map((student) => (
        <StudentItem isShowDelete student={student} />
      ))}
    </Stack>
  );
}

function StudentList() {
  return (
    <SimpleGrid columns={2}>
      <GridItem justifyItems="stretch">
        <StudentAddModal />
        <Suspense fallback={<Spinner />}>
          <List />
        </Suspense>
      </GridItem>
      <GridItem />
    </SimpleGrid>
  );
}

export default StudentList;
