import { Box, Heading, Stack, GridItem, Grid } from "@chakra-ui/react";
import React from "react";
import CheckIn from "./CheckIn";
import Tasks from "./Tasks";
import Birthday from "./Birthday";
import RenewStudents from "./RenewStudents";
import Activities from "./Activities";
import dayjs from "dayjs";

function Home() {
  return (
    <Stack p={5} spacing={8}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {/* <Box>
          <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
            Chấm công
          </Heading>
          <Box maxW="96" p={6} bg="gray.800" borderRadius="lg">
            <CheckIn />
          </Box>
        </Box> */}
        {/* <Box w="100%">
          <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
            Tác vụ trong ngày
          </Heading>
          <Box maxW="container.sm" p={6} bg="gray.800" borderRadius="lg">
            <Tasks />
          </Box>
        </Box> */}
         {/* <GridItem colSpan={2} w="full">
          <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
            Thống kê hoạt động {dayjs().format("MMMM")}
          </Heading>
          <Box p={6} bg="gray.800" borderRadius="lg">
            <Activities />
          </Box>
        </GridItem> */}

        <GridItem colSpan={2} w="full">
          <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
            Tư vấn renew
          </Heading>
          <Box p={6} bg="gray.800" borderRadius="lg">
            <RenewStudents />
          </Box>
        </GridItem>
        {/* <Box w="100%">
          <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
            Sinh nhật tháng này
          </Heading>
          <Box maxW="container.sm" p={6} bg="gray.800" borderRadius="lg">
            <Birthday />
          </Box>
        </Box> */}
      </Grid>
    </Stack>
  );
}

export default Home;
