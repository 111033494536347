import React from "react";
import { useQueryParam, StringParam } from "use-query-params";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { useLeadStatus } from "../../api/leadStatus";

function FilterByLeadStatus() {
  const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [leadStatus, setLeadStatus] = useQueryParam("leadStatus", StringParam);

  const { data: statusList } = useLeadStatus();

  const selectedStatus = statusList?.docs?.find(
    (item) => item._id === leadStatus
  );

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={leadStatus ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedStatus?.label || "Trạng thái"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Trạng thái</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {statusList?.docs.map((status) => (
              <HStack
                onClick={() => {
                  setLeadStatus(status._id);
                  onClose();
                }}
                color={status._id === leadStatus ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={status._id}
                p={2}
              >
                <Box
                  shadow={status._id === leadStatus ? "outline" : "none"}
                  w={4}
                  h={4}
                  bg={`${status.color}.200`}
                  borderRadius="full"
                />
                <Text fontSize="sm" fontWeight="semibold">
                  {status.label}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setLeadStatus("");
                onClose();
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByLeadStatusWrapper() {
  return (
    <Suspense
      fallback={
        <Button variant="ghost" isLoading>
          Trạng thái
        </Button>
      }
    >
      <FilterByLeadStatus />
    </Suspense>
  );
}

export default FilterByLeadStatusWrapper;
