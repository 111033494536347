import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { EventDocument } from "./event";
import { UserDocument } from "./user";

export interface CreateUserEventDto {
  user: string;
  event: string;
  earn: number;
}

export interface GetUserEventDto extends Partial<CreateUserEventDto> {}

export interface UpdateUserEventDto extends Partial<CreateUserEventDto> {
  status?: Status;
}

export interface UserEventDocument extends TimeStamp, ObjectId {
  user: UserDocument;
  event: EventDocument;
  earn: number;
}

// api
export const createUserEvent = async (
  createUserEventDto: CreateUserEventDto
) => {
  const { data } = await axios.post("/user-event", createUserEventDto);
  return data;
};

export const getUserEvent = async (id: string) => {
  const { data } = await axios.get<UserEventDocument>(`/user-event/${id}`);
  return data;
};

export const getUserEvents = async (
  params?: QueryOptionParams & GetUserEventDto
) => {
  const { data } = await axios.get<PaginateDocument<UserEventDocument>>(
    "/user-event",
    {
      params: { page: 1,  ...params },
    }
  );
  return data;
};

const deleteUserEvent = async (id: string) => {
  const { data } = await axios.delete(`/user-event/${id}`);
  return data;
};

export const getTotalAmount = async (id: string) => {
  const { data } = await axios.get<{ totalAmount: number }>(
    `/user-event/${id}/total`
  );
  return data;
};

// hooks
export const userEventKeys = {
  ...generateQueryKey("user-event"),
  total: ["user-event", "total"],
  totalEarn: ["user-event", "total-earn"],
};

export const useUserEvents = (params?: QueryOptionParams & GetUserEventDto) => {
  return useQuery(userEventKeys.list(params), () => getUserEvents(params));
};

export const useUserEvent = (id: string) => {
  return useQuery(userEventKeys.detail(id), () => getUserEvent(id));
};

export const useCreateUserEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(createUserEvent, {
    onSuccess: () => queryClient.invalidateQueries(userEventKeys.lists()),
  });
};

export const useDeleteUserEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUserEvent, {
    onSuccess: () => queryClient.invalidateQueries(userEventKeys.lists()),
  });
};

export const useTotalAmount = (id: string) => {
  return useQuery(userEventKeys.total, () => getTotalAmount(id));
};

export const useUserTotalEarn = () => {
  return useQuery(userEventKeys.totalEarn, () =>
    axios
      .get<{ sum: number }[]>("/user-event/total-earn")
      .then((res) => res.data)
  );
};
