import React, { Suspense } from "react";
import Table from "src/components/Table";
import { useTransactions } from "../../api/transaction";
import { Column } from "react-table";
import dayjs from "dayjs";
import { Tooltip, Text, HStack, Avatar, Link } from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { formatMoney } from "../../utils/format";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

function TransactionTable() {
  const { params } = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { data } = useTransactions({ student: id, ...params });

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Số tiền",
        accessor: "amount",
        Cell: ({ value }) => (
          <Text
            py={2}
            fontSize="md"
            fontWeight="bold"
            color={value >= 0 ? "teal.300" : "red.300"}
          >
            {value >= 0 && "+"}
            {formatMoney(value)}
          </Text>
        ),
      },
      {
        Header: "Sale",
        accessor: "sale",
        Cell: ({ value }) => (
          <Link
          color="blue.300"
          to={`/user/${value?._id}`}
          as={RouterLink}
        >
          <HStack>
            <Avatar size="xs" name={value?.name} />
            <Text>{value?.name}</Text>
          </HStack>
        </Link>
        ),
      },
      {
        Header: "Thanh toán",
        accessor: "method",
      },
      {
        Header: "Ghi chú",
        accessor: "note",
      },
      {
        Header: "Ngày giao dịch",
        accessor: "date",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).format("L")}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }) => (
          <Link
            target={"_blank"}
            color="blue.400"
            to={`/transactions/${row.original._id}/receipt`}
            as={RouterLink}
          >
            Phiếu thu
          </Link>
        ),
      },
    ], // eslint-disable-next-line
    []
  );

  return <Table columns={columns} data={data?.docs || []} />;
}

function TransactionTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <TransactionTable />
    </Suspense>
  );
}

export default TransactionTableWrapper;
