import React from "react";
import {
  Heading,
  VStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { LoginDto, login, currentUserKey } from "../../api/auth";
import { ACCESS_TOKEN } from "../../constants/localStorage";
import { useQueryClient } from "react-query";

interface LoginProps {
  resetErrorBoundary: () => void;
  isOpen: boolean;
}

function Login({ resetErrorBoundary, isOpen }: LoginProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const onSubmit = async (values: LoginDto) => {
    try {
      const result = await login(values);
      localStorage.setItem(ACCESS_TOKEN, result?.access_token);
      queryClient.invalidateQueries(currentUserKey);
      resetErrorBoundary();
    } catch (error) {
      toast({
        status: "error",
        description: "Sai tên truy cập hoặc mật khẩu",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    // <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {}}>
    //   <ModalOverlay />
    //   <ModalContent p={8}>
        <VStack  mt="52">
          <Heading size="lg" mb={4}>
            NK
          </Heading>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <VStack onSubmit={handleSubmit} as="form" spacing={4}>
                <LabeledTextField name="username" label="Username" />
                <LabeledTextField
                  name="password"
                  label="Password"
                  type="password"
                />
                <Button type="submit" w="full">
                  Đăng nhập
                </Button>
              </VStack>
            )}
          />
        </VStack>
    //   </ModalContent>
    // </Modal>
  );
}

export default Login;
