import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { StudentDocument } from "./student";
import { SkillDocument } from "./skill";

export interface CreateStudentSkillDto {
  student: string;
  skill: string;
}

export interface GetStudentSkillDto extends Partial<CreateStudentSkillDto> {}

export interface UpdateStudentSkillDto extends Partial<CreateStudentSkillDto> {
  status?: Status;
}

export interface StudentSkillDocument extends TimeStamp, ObjectId {
  student: StudentDocument;
  skill: SkillDocument;
}

// api
export const createStudentSkill = async (
  createStudentSkillDto: CreateStudentSkillDto
) => {
  const { data } = await axios.post("/student-skill", createStudentSkillDto);
  return data;
};

export const getStudentSkill = async (id: string) => {
  const { data } = await axios.get<StudentSkillDocument>(
    `/student-skill/${id}`
  );
  return data;
};

export const getStudentSkills = async (
  params?: QueryOptionParams & GetStudentSkillDto
) => {
  const { data } = await axios.get<PaginateDocument<StudentSkillDocument>>(
    "/student-skill",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

const deleteStudentSkill = async (id: string) => {
  const { data } = await axios.delete(`/student-skill/${id}`);
  return data;
};

// hooks
export const studentStudentSkillKeys = generateQueryKey("student-kill");

export const useStudentSkills = (
  params?: QueryOptionParams & GetStudentSkillDto
) => {
  return useQuery(studentStudentSkillKeys.list(params), () =>
    getStudentSkills(params)
  );
};

export const useStudentSkill = (id: string) => {
  return useQuery(studentStudentSkillKeys.detail(id), () =>
    getStudentSkill(id)
  );
};

export const useCreateStudentSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(createStudentSkill, {
    onSuccess: () =>
      queryClient.invalidateQueries(studentStudentSkillKeys.lists()),
  });
};

export const useDeleteStudentSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteStudentSkill, {
    onSuccess: () =>
      queryClient.invalidateQueries(studentStudentSkillKeys.lists()),
  });
};
