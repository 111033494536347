import axios from "src/config/axios";
import { useQuery } from "react-query";
import { Status } from "../../../ninjakids-server/src/types";
import { QueryOptionParams } from "../types/QueryOptionParams";
import generateQueryKey from "src/utils/queryKeyFactory";
import { PaginateDocument } from "../types/PaginateDocument";
import { UserDocument } from "./user";
import { CenterDocument } from "./center";
import { TimeStamp } from "../types/TimeStamp";
import { ObjectId } from "../types/ObjectId";
import { LeadStatusDocument } from "./leadStatus";
import { NoteDocument } from "./note";

export interface LeadDto {
  name: string;
  status: Status;
  leadStatus: string;
  phone: string;
  facebook: string;
  note: string;
  takeCareBy: string;
  center: string;
}

export interface LeadUpdateDto {
  name?: string;
  status?: Status;
  leadStatus?: string;
  facebook?: string;
  phone?: string;
  note?: string;
  takeCareBy?: string;
  center?: string;
  notes?: any;
  $addToSet?: any;
  $pull?: any;
}
export interface LeadDocument extends TimeStamp, ObjectId {
  name: string;
  status: Status;
  leadStatus: LeadStatusDocument;
  phone: string;
  note: string;
  takeCareBy: UserDocument;
  center: CenterDocument;
  notes: NoteDocument[];
}

export const createLead = async (leadDto: LeadDto) => {
  const { data } = await axios.post("/lead", leadDto);
  return data;
};

export const getLeads = async (params?: QueryOptionParams & LeadUpdateDto) => {
  const { data } = await axios.get<PaginateDocument<LeadDocument>>("/lead", {
    params: { page: 1, limit: 20, ...params },
  });
  return data;
};

export const updateLead = async (id: string, updateData: LeadUpdateDto) => {
  const { data } = await axios.patch(`/lead/${id || ""}`, updateData);
  return data;
};

// hooks
export const leadKeys = generateQueryKey("lead");

export const useLeads = (params?: QueryOptionParams & LeadUpdateDto) => {
  return useQuery(leadKeys.list(params), () => getLeads(params));
};

export const useLead = (id: string) => {
  return useQuery(leadKeys.detail(id), () =>
    axios.get<LeadDocument>(`/lead/${id}`).then((res) => res.data)
  );
};
