import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  IconButton,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import AbilityForm from "./AbilityForm";
import { useCreateAbility } from "../../api/ability";
import { MdOutlineAdd } from "react-icons/md";
const FORM_ID = "add-ability";

export function AbilityModalAdd({ type, id, name }: { type: string, id: string, name: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createAbility = useCreateAbility();


  return (
    <>
      <Tooltip label="Thêm chỉ số">
        <IconButton
          icon={<Icon boxSize={4} as={MdOutlineAdd} />}
          variant="ghost"
          colorScheme="blue"
          aria-label="delete"
          onClick={onOpen}
        // onClick={() => editStudent(row.original)}
        />
      </Tooltip>
      {/* <Button onClick={onOpen}>Thêm thông số</Button> */}
      <Modal
        closeOnOverlayClick={false}
        size="4xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thêm thông số - {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AbilityForm
              formId={FORM_ID}
              onSubmit={async (values) => {
                try {
                  await createAbility.mutateAsync({
                    ...values,
                    [type]: id,
                  });
                 
                  onClose();
                } catch (error) {
                }
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Hủy
            </Button>
            <Button
              isLoading={createAbility.isLoading}
              form={FORM_ID}
              type="submit"
              colorScheme="blue"
            >
              Thêm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
