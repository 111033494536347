import {
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React, { Suspense } from "react";
import LabeledSelectField from "./LabeledSelectField";
import { useEventCategories } from "../api/eventCategory";

interface LabeledSelectEventCategoryProps {
  name: string;
  label: string;
}

function Select({ name, label }: LabeledSelectEventCategoryProps) {

  const { data: eventCategories } = useEventCategories();
  const eventCategoryOptions = (eventCategories?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }))

  return (
    <Stack align="start">
      <LabeledSelectField
        options={eventCategoryOptions}
        name={name}
        label={label}
      />
    </Stack>
  );
}

function LabeledSelectEventCategory(props: LabeledSelectEventCategoryProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <Select {...props} />
    </Suspense>
  );
}

export default LabeledSelectEventCategory;
