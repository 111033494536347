import React, { useEffect } from "react";
import { useLocation } from "react-router";
import Pagination from "src/components/Pagination";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";
import { HStack, Text, Center } from "@chakra-ui/react";
import { omit } from "lodash";
import queryString from "query-string";

interface FilterByPageProps {
  totalPages?: number;
  totalDocs?: number;
}

function FilterByPage({ totalPages = 2, totalDocs = 10 }: FilterByPageProps) {
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));

  const { search } = useLocation();
  const params = queryString.parse(search);
  const paramsWithoutPage = JSON.stringify(omit(params, "page"));

  // reset page when change filter
  useEffect(() => {
    setPage(1);
  }, [paramsWithoutPage, setPage]);

  return (
    <HStack spacing={4}>
      <Pagination
        onPageChange={(e) => setPage(e.selected + 1)}
        pageCount={totalPages}
        forcePage={page - 1}
      />
      <Center borderRadius="md" w={8} h={8} bg="gray.900">
        <Text color="blue.500" fontSize="sm" fontWeight="600">
          {totalDocs}
        </Text>
      </Center>
    </HStack>
  );
}

export default FilterByPage;
