import React, { Suspense } from "react";
import Table from "src/components/Table";
import { TimeSheetDocument, useTimeSheets } from "../../api/timeSheet";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import { Tooltip, Stack, Text, Link } from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { calcCrow } from "src/utils/cal";

function TimeSheetTable() {
  const { params } = useSearchParams();
  const { data } = useTimeSheets({ ...params, orderBy: "-1" });

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Nhân viên",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => (
          <Text>{row.original.user.name}</Text>
        ),
      },
      {
        Header: "Check In - Out",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => {
          const checkIn = row.original.checkIn
            ? dayjs(row.original.checkIn).format("LT")
            : "";
          const checkOut = row.original.checkOut
            ? dayjs(row.original.checkOut).format("LT")
            : "";
          return (
            <Text>
              <Text as="b">{checkIn}</Text>
              {" - "}
              <Text as="b">{checkOut}</Text>
            </Text>
          );
        },
      },
      {
        Header: "Ngày",
        accessor: "dayOfMonth",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).format("LL")}
          </Tooltip>
        ),
      },
      {
        Header: "Trung tâm",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => {
          const center = row.original.center;
          return <Text>{center?.name}</Text>;
        },
      },
      {
        Header: "Thiết bị",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => {
          const device = row.original.device;
          return (
            <Stack spacing={1}>
              <Text fontWeight="bold">{device?.secret_code}</Text>
              <Text fontSize="xs">{device?.simple_software_string}</Text>
              <Text fontSize="xs">{device?.operating_system}</Text>
            </Stack>
          );
        },
      },
      {
        Header: "Vị trí check in",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => {
          const location =
            row.original.location || row.original.checkInLocation;
          const latitude = location?.latitude;
          const longitude = location?.longitude;
          const centerLat = row.original.center?.latitude;
          const centerLong = row.original.center?.longitude;
          const distance = calcCrow(latitude, longitude, centerLat, centerLong);
          const roundedDistance = Math.round(distance * 100) / 100;
          return location ? (
            <Stack>
              <Link
                color="blue.300"
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
              >
                <Text fontSize="xs">{latitude}...</Text>
              </Link>
              <Text fontSize="xs" color={distance > 0.5 ? "red.300" : "white"}>
                {roundedDistance} (km)
              </Text>
            </Stack>
          ) : (
            <Text />
          );
        },
      },
      {
        Header: "Vị trí check out",
        Cell: ({ row }: CellProps<TimeSheetDocument>) => {
          const location =
            row.original.location || row.original.checkOutLocation;
          const latitude = location?.latitude;
          const longitude = location?.longitude;
          const centerLat = row.original.center?.latitude;
          const centerLong = row.original.center?.longitude;
          const distance = calcCrow(latitude, longitude, centerLat, centerLong);
          const roundedDistance = Math.round(distance * 100) / 100;
          return location ? (
            <Stack>
              <Link
                color="blue.300"
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
              >
                <Text fontSize="xs">{latitude}...</Text>
              </Link>
              <Text fontSize="xs" color={distance > 0.5 ? "red.300" : "white"}>
                {roundedDistance} (km)
              </Text>
            </Stack>
          ) : (
            <Text />
          );
        },
      },
      // {
      //   Header: "Hành động",
      //   Cell: ({ row }: CellProps<TimeSheetDocument>) => {
      //     return (
      //       <HStack spacing={4}>
      //         <Tooltip label="Chỉnh sửa">
      //           <IconButton
      //             icon={<Icon boxSize={4} as={FiEdit} />}
      //             variant="ghost"
      //             colorScheme="blue"
      //             aria-label="delete"
      //             onClick={() => editTimeSheet(row.original)}
      //           />
      //         </Tooltip>
      //       </HStack>
      //     );
      //   },
      // },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      {/* <TimeSheetModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
      /> */}
      <Table columns={columns} data={data?.docs || []} />
    </>
  );
}

function TimeSheetTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <TimeSheetTable />
    </Suspense>
  );
}

export default TimeSheetTableWrapper;
