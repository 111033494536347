import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import { StudentDocument, useStudentsBody } from "../../api/student";
import dayjs from "dayjs";
import {
  Text,
  HStack,
  Avatar,
  Flex,
  Spacer,
  Center,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";

import { useQueryParam, StringParam } from "use-query-params";
import { ERROR_DEADLINE, WARNING_DEADLINE } from "src/constants/takeCare";
function ListTakeCareByTrainer() {
  const { params } = useSearchParams();
  const { data = [] } = useStudentsBody();
  const groupedByTakeCareBy = data.reduce((acc, obj) => {
    const key = JSON.stringify(obj.takeCareBy); // Convert object to string to use it as a key
    acc[key] = acc[key] || []; // Initialize array if key doesn't exist
    acc[key].push(obj);
    return acc;
  }, {} as any);

  const [takeCareBy, setTakeCareBy] = useQueryParam("takeCareBy", StringParam);
  let totalWarning = 0, totalError = 0

  return (
    <Flex gridGap={2} flexWrap={"wrap"} mt={4}  >
      {Object.entries(groupedByTakeCareBy).map((data: any, index) => {
        const trainer = JSON.parse(data[0])
        const listTakeCare = data[1]
        let errorCount = 0
        let warningCount = 0
        for (let index = 0; index < listTakeCare.length; index++) {
          const student = listTakeCare[index];
          console.log(student)
          if (!student.bodyInfo) {
            errorCount += 1
            totalError += 1
          } else {
            const today = dayjs();
            const errorDeadline = today.subtract(ERROR_DEADLINE, 'days'); // Calculate 25 days ago
            const warningDeadline = today.subtract(WARNING_DEADLINE, 'days'); // Calculate 25 days ago
            if (!dayjs(student.bodyInfo.time).isAfter(errorDeadline)) {
              errorCount += 1
              totalError += 1
            } else if (!dayjs(student.bodyInfo.time).isAfter(warningDeadline)) {
              warningCount += 1
              totalWarning += 1
            }
          }

        }

        return <HStack p={2} borderRadius="md" key={index}
          bgColor={takeCareBy === trainer._id ? "blue.900" : "gray.700"}
          _hover={{
            cursor: "pointer",
            bg: "blue.900",
            transition: "0.3s"
          }}
          onClick={() => {
            setTakeCareBy(trainer._id);
          }}>
          <Text m={0} p={0}>
            ({listTakeCare.length})
          </Text>
          <Avatar size="xs" name={trainer.name} />

          <Text fontWeight="bold" fontSize="sm">{trainer.name}</Text>
          <Text color="red.400" fontWeight="bold" m={0} p={0}>
            {errorCount}
          </Text>
          <Text>-</Text>
          <Text color="orange.400" fontWeight="bold" m={0} p={0}>
            {warningCount}
          </Text>

        </HStack>
      })}
      <Spacer />
      <Flex justifyContent="center" alignItems="center">
        <Center borderRadius="md" bg="gray.900" px={2} mx={1}>
          <Text mx={2}>
            (Tổng)
          </Text>
          <Text color="blue.500" fontSize="sm" fontWeight="600">
            {data.length}
          </Text>
        </Center>
        <Center borderRadius="md" bg="gray.900" px={2} mx={1}>
          <Text mx={2} color="red.400">
            Quá hạn
          </Text>
          <Text  fontSize="sm" fontWeight="600">
            {totalError}
          </Text>
        </Center>
        <Center borderRadius="md" bg="gray.900" px={2} mx={1}>
          <Text mx={2} color="orange.400">
            Cần cập nhật
          </Text>
          <Text  fontSize="sm" fontWeight="600">
            {totalWarning}
          </Text>
        </Center>
      </Flex>

      {/* <Table columns={columns} data={data || []} /> */}
    </Flex>
  );
}

function ListTakeCareByTrainerWrapper() {
  return (
    <Suspense fallback="loading...">
      <ListTakeCareByTrainer />
    </Suspense>
  );
}

export default ListTakeCareByTrainerWrapper;
