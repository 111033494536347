import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import LabeledSelectField from "./LabeledSelectField";
import { useSkillCategories } from "../api/skill-category";

interface LabeledSelectSkillCategoryProps {
  name: string;
  label: string;
}

function Select({ name, label }: LabeledSelectSkillCategoryProps) {
  const { data: skillCategories } = useSkillCategories();
  const userOptions = (skillCategories?.docs || []).map((item) => ({
    value: item._id,
    label: item.name,
  }));
  return <LabeledSelectField options={userOptions} name={name} label={label} />;
}

function LabeledSelectSkillCategory(props: LabeledSelectSkillCategoryProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <Select {...props} />
    </Suspense>
  );
}

export default LabeledSelectSkillCategory;
