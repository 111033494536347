import {
  Avatar,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  Link
} from "@chakra-ui/react";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { StudentDocument } from "src/api/student";
import { Link as RouterLink } from "react-router-dom";
interface SessionAttendeeItemProps {
  student: StudentDocument
  onClick?: () => void;
  colorScheme?: string;
  icon?: React.ReactNode;
}

function SessionAttendeeStudent({
  student,
  onClick,
  icon,
  colorScheme,
}: SessionAttendeeItemProps) {
  const { name, remainingSessions, photoUrl, _id } = student
  return (
    <HStack p={2} _hover={{ bg: "whiteAlpha.100" }} borderRadius="md">
      <Link
        as={RouterLink}
        to={`/student/${_id}`}
        fontWeight="semibold"
        color="blue.300"
        fontSize="sm"
      >
        <HStack>
          <Avatar
            size="xs"
            name={name}
            src={photoUrl}
          />
          <Text size="sm">{name}</Text>
        </HStack>
      </Link>
      <Spacer />
      <Text
        py={2}
        fontSize="sm"
        color={remainingSessions > 8 ? "teal.300" : "red.300"}
      >
        {remainingSessions} buổi
      </Text>
      <IconButton
        aria-label="move"
        icon={icon || <Icon boxSize={5} as={BiRightArrowAlt} />}
        variant="outline"
        size="xs"
        onClick={onClick}
        colorScheme={colorScheme}
      />
    </HStack>
  );
}

export default SessionAttendeeStudent;
