import axios from "src/config/axios";
import { useQuery } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { Color } from "src/types/Color";

export interface CreateEventCategoryDto {
  name: string;
  color: Color;
}

export interface GetEventCategoryDto extends Partial<CreateEventCategoryDto> {}

export interface UpdateEventCategoryDto
  extends Partial<CreateEventCategoryDto> {
  status?: Status;
}

export interface EventCategoryDocument extends TimeStamp, ObjectId {
  name: string;
  color: Color;
  status: Status;
}

// api
export const createEventCategory = async (
  createEventCategoryDto: CreateEventCategoryDto
) => {
  const { data } = await axios.post("/event-category", createEventCategoryDto);
  return data;
};

export const getEventCategory = async (id: string) => {
  const { data } = await axios.get<EventCategoryDocument>(
    `/event-category/${id}`
  );
  return data;
};

export const getEventCategories = async (
  params?: QueryOptionParams & GetEventCategoryDto
) => {
  const { data } = await axios.get<PaginateDocument<EventCategoryDocument>>(
    "/event-category",
    {
      params,
    }
  );
  return data;
};

export const updateEventCategory = async (
  id: string,
  updateEventCategoryDto: UpdateEventCategoryDto
) => {
  const { data } = await axios.patch(
    `/event-category/${id || ""}`,
    updateEventCategoryDto
  );
  return data;
};

// hooks
export const eventCategoryKeys = generateQueryKey("event-category");

export const useEventCategories = (
  params?: QueryOptionParams & GetEventCategoryDto
) => {
  return useQuery(
    eventCategoryKeys.list(params),
    () => getEventCategories(params),
    { staleTime: Infinity }
  );
};

export const useEventCategory = (id: string) => {
  return useQuery(eventCategoryKeys.detail(id), () => getEventCategory(id), {
    staleTime: Infinity,
  });
};
