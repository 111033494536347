import {
  Box,
  Heading,
  Flex,
  Spacer,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Icon,
  Tooltip,
  HStack,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import React, { Suspense } from "react";
import Table from "src/components/Table";
import { BiSearch } from "react-icons/bi";
import { MdRestore } from "react-icons/md";
import AddCenterForm from "./AddCenterForm";
import { useCenters } from "../../api/center";
import { CellProps, Column } from "react-table";
import { CenterDocument, updateCenter } from "src/api/center";
import { Status } from "src/types/Status";
import { Link as RouterLink } from "react-router-dom";

function Center() {
  const centers = useCenters();

  const archiveCenter = async (center: CenterDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${center.name}`)) {
        await updateCenter(center._id, { status: Status.Inactive });
      }
    } catch (error) {}
  };

  const restoreCenter = async (center: CenterDocument) => {
    try {
      if (window.confirm(`Khôi phục ${center.name}`)) {
        await updateCenter(center._id, { status: Status.Active });
      }
    } catch (error) {}
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        Cell: ({ row }) => (
          <Link
            to={`/center/${row.original._id}`}
            as={RouterLink}
            color="blue.400"
          >
            {row.original.name}
          </Link>
        ),
      },
      { Header: "Địa chỉ", accessor: "address" },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<CenterDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveCenter(row.original);
                    } else {
                      restoreCenter(row.original);
                    }
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ],
    []
  );

  return (
    <Flex direction="column" p={6} h="full">
      <Heading size="md" textTransform="uppercase" color="gray.400" mb={4}>
        Trung Tâm
      </Heading>
      <Flex mb={4}>
        <InputGroup w="xs">
          <InputLeftElement
            pointerEvents="none"
            children={<BiSearch color="gray.400" />}
          />
          <Input
            bg={useColorModeValue("white", "gray.800")}
            type="text"
            placeholder="Tìm kiếm lớp học"
          />
        </InputGroup>
        <Spacer />
        <AddCenterForm />
      </Flex>
      <Box overflow="auto" flex={1} px={5} borderRadius="md" bg={"gray.800"}>
        <Table columns={columns} data={centers?.data?.docs || []} />
      </Box>
    </Flex>
  );
}

const CenterWrapper = () => {
  return (
    <Suspense fallback="Loading...">
      <Center />
    </Suspense>
  );
};

export default CenterWrapper;
