import React, { useState } from "react";
import {
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  HStack,
  Stack,
  Text,
  CloseButton,
  Tooltip,
} from "@chakra-ui/react";
import { CellProps } from "react-table";
import { StudentDocument, updateStudent, studentKeys } from "../../api/student";
import { BiNotepad } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import LabeledTextareaField from "src/components/LabeledTextareaField";
import { Form } from "react-final-form";
import { createNote } from "src/api/note";
import { useQueryClient } from "react-query";
import { useCurrentUser } from "src/api/auth";
import { NoteDocument, NoteType } from "../../api/note";
import dayjs from "dayjs";
import { map } from "lodash";
import { Role } from "src/types/Role";

const removeNoteObject = (noteId: string) => ({
  $pull: {
    notes: noteId,
  },
});

const addNoteObject = (nodeId: string) => ({
  updatedNoteAt: new Date(),
  $addToSet: {
    notes: nodeId,
  },
});

function StudentTableNote({ row }: CellProps<StudentDocument>) {
  const [isShowForm, setIsShowForm] = useState(false);
  const initialFocusRef = React.useRef();
  const queryClient = useQueryClient();
  const userRoles = map(useCurrentUser().data?.roles || [], "value");
  const notes = row.original?.notes;

  const showForm = () => {
    setIsShowForm(true);
  };

  const onSubmit = async (values: Record<"content", string>) => {
    try {
      const note = await createNote({
        content: values.content,
        type: NoteType.Student,
      });
      const noteId = note?._id;

      if (noteId) {
        await updateStudent(row.original._id, addNoteObject(noteId));
        queryClient.invalidateQueries(studentKeys.all);
      }
    } catch (error) {
    } finally {
      setIsShowForm(false);
    }
  };

  const removeNote = async (item: NoteDocument) => {
    try {
      if (
        window.confirm(
          `Xóa ghi chú "${(item.content || "").slice(0, 10)}..." ?`
        )
      ) {
        await updateStudent(row.original._id, removeNoteObject(item._id));
        queryClient.invalidateQueries(studentKeys.all);
      }
    } catch (error) {}
  };

  return (
    <Popover initialFocusRef={initialFocusRef} placement="right-start">
      <PopoverTrigger>
        <IconButton
          icon={<Icon boxSize={4} as={BiNotepad} />}
          colorScheme="teal"
          aria-label="delete"
          variant={row.original?.notes?.length ? "solid" : "ghost"}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Ghi chú</PopoverHeader>
        <PopoverBody>
          <Stack maxH="80" overflowY="auto" spacing={3}>
            {notes &&
              notes.length &&
              notes.map((item) => (
                <Stack
                  px={2}
                  py={3}
                  border="1px"
                  borderColor="gray.600"
                  borderRadius="md"
                  role="group"
                  key={item._id}
                  spacing={1}
                >
                  <HStack justify="space-between">
                    <Text fontWeight="semibold" fontSize="xs">
                      {item?.author?.name}
                    </Text>
                    {userRoles.includes(Role.SuperAdmin) && (
                      <CloseButton
                        size="sm"
                        visibility="hidden"
                        _groupHover={{ visibility: "visible" }}
                        onClick={() => removeNote(item)}
                      />
                    )}
                  </HStack>
                  <Text color="gray.300" lineHeight={1.4} whiteSpace="pre-line">
                    {item?.content}
                  </Text>
                  <Tooltip label={dayjs(item?.createdAt).format("LLLL")}>
                    <Text fontSize="xs" color="gray.500">
                      {dayjs(item?.createdAt).fromNow()}
                    </Text>
                  </Tooltip>
                </Stack>
              ))}
          </Stack>
          <Stack pt={3}>
            {!isShowForm && (
              <Button
                variant="ghost"
                leftIcon={<Icon boxSize={4} as={AiOutlinePlusCircle} />}
                onClick={showForm}
              >
                Thêm ghi chú
              </Button>
            )}
            {isShowForm && (
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <Stack as="form" onSubmit={handleSubmit}>
                    <LabeledTextareaField
                      ref={initialFocusRef}
                      name="content"
                      label=""
                      autoFocus
                    />
                    <HStack>
                      <Button
                        onClick={() => setIsShowForm(false)}
                        variant="ghost"
                      >
                        Hủy
                      </Button>
                      <Button type="submit">Thêm</Button>
                    </HStack>
                  </Stack>
                )}
              />
            )}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default StudentTableNote;
