
import React, { Suspense } from "react";
import {
  IconButton,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import useSearchParams from "../../utils/useSearchParams";
import dayjs from "dayjs";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  useStudents,
} from "../../api/student"
function StudentDownload() {
  const { params } = useSearchParams();
  const { data } = useStudents(params);
  let csvData = [[]] as any;
  if (data?.docs) {
    csvData = data?.docs.map((d) => {
      const obj = {} as any;
      obj.id = d.number;
      obj.name = d.name;
      obj.phone = d.phone;
      obj.takeCare = d.takeCareBy?.name;
      obj.birthDay = dayjs(d.birthDay).format("L");
      return obj;
    });
  }
  console.log(csvData);

  return (
    <CSVLink data={csvData} filename={"Student.csv"}>
      <IconButton
        mr={8}
        colorScheme="blue"
        aria-label="Search database"
        icon={<DownloadIcon />}
      />
    </CSVLink>
  );
}

function StudentDownloadWrapper() {
  return (
    <Suspense fallback="loading...">
      <StudentDownload />
    </Suspense>
  );
}

export default StudentDownloadWrapper;

