import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useTimeSheets } from "../../api/timeSheet";
import FilterByPage from "src/components/FilterByPage";

function TimeSheetPaging() {
  const { params } = useSearchParams();
  const { data } = useTimeSheets(params);

  return (
    <FilterByPage totalDocs={data?.totalDocs} totalPages={data?.totalPages} />
  );
}

function TimeSheetPagingWrapper() {
  return (
    <Suspense fallback="">
      <TimeSheetPaging />
    </Suspense>
  );
}

export default TimeSheetPagingWrapper;
