import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import { StudentDocument, useStudentsBody } from "../../api/student";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import {
  Tooltip,
  Text,
  HStack,
  Stack,
  Link,
  Avatar,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";

import { Link as RouterLink } from "react-router-dom";
import { AbilityModalAdd } from "./AbilityModalAdd";
import { getBodyIndexTextColor, getTakeCareTextColor, getTakeCareTextContent, getBodyIndexTextContent } from "src/constants/takeCare";
function TakeCareStudentTable() {
  const { params } = useSearchParams();
  const { data } = useStudentsBody(params);
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "No.",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "ID",
        accessor: "number",
      },
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<StudentDocument>) => (
          <Link
            as={RouterLink}
            to={`/student/${row.original?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Avatar
                size="xs"
                name={row.original.name}
                src={row.original.photoUrl}
              />
              <Text>{row.original.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Chỉ số",
        accessor: "bodyInfo",
        Cell: ({ row }: CellProps<any>) => {
          return (
            <HStack>
              <Tooltip label={dayjs(row.original.bodyInfo?.time).format("L")}>
                <Text color={getBodyIndexTextColor(row.original.bodyInfo?.time)} fontWeight="bold">
                  {getBodyIndexTextContent(row.original.bodyInfo?.time)}
                  <AbilityModalAdd type={"student"} id={row.original._id} name={row.original.name} />
                </Text>
              </Tooltip>
            </HStack>


          );
        },
      },
      {
        Header: "Take care",
        accessor: "takeCareDate",
        Cell: ({ row }: CellProps<any>) => {
          return (
            <HStack>
              {/* <AbilityModalAdd type={"student"} id={row.original._id} name={row.original.name} /> */}
              <Tooltip label={dayjs(row.original.takeCareDate).format("L")}>
                <Text color={getTakeCareTextColor(row.original.takeCareDate)} fontWeight="bold">
                  {getTakeCareTextContent(row.original.takeCareDate)}
                </Text>
              </Tooltip>
            </HStack>


          );
        },
      },
      {
        Header: "Số buổi tập",
        accessor: "numberOfSessions",
      },
      {
        Header: "Số buổi còn lại",
        accessor: "remainingSessions",
        Cell: ({ value }) => {
          if (value < 0) {
            return (
              <Text color="red.400">
                {value} Quá hạn
              </Text>
            )
          } else if (value < 8) {
            return (
              <Text color="orange.400">
                {value} Renew
              </Text>
            )
          }
          return (
            <Text>
              {value}
            </Text>
          );
        },
      },
      {
        Header: "PIC",
        accessor: "takeCareBy",
        Cell: ({ value }) => (
          <HStack>
            <Avatar size="xs" name={value?.name} />
            <Text>{value?.name}</Text>
          </HStack>
        ),
      },

      // {
      //   Header: "Hành động",
      //   Cell: ({ row }: CellProps<StudentDocument>) => {
      //     return (
      //       <HStack spacing={4}>
      //         <AbilityModalAdd type={"student"} id={row.original._id} name={row.original.name} />
      //       </HStack>
      //     );
      //   },
      // },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      {/* <StudentModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
      /> */}
      <Table columns={columns} data={data || []} />
    </>
  );
}

function StudentTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <TakeCareStudentTable />
    </Suspense>
  );
}

export default StudentTableWrapper;
