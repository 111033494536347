
import { ModalProps, useBreakpointValue } from '@chakra-ui/react';

export function useResponsiveModalValue(
  modalSize: string = '3xl',
): Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  const size = useBreakpointValue<ModalProps['size']>({
    base: 'full',
    lg: modalSize,
  });
  const motionPreset = useBreakpointValue<ModalProps['motionPreset']>({
    base: 'slideInBottom',
    lg: 'scale',
  });
  const scrollBehavior = useBreakpointValue<ModalProps['scrollBehavior']>({
    base: 'inside',
    lg: 'outside',
  });

  return {
    size,
    scrollBehavior,
    motionPreset,
    trapFocus: true,
    autoFocus: false,
    allowPinchZoom: true,
    blockScrollOnMount: false,
  };
}