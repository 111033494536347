import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { StudentDocument } from "./student";
import { LeadDocument } from "./lead";
import { useToast } from "@chakra-ui/react";
import { UserDocument } from "./user";

export interface CreateAbilityDto {
  student: string;
  review: string;
  goal: string;
  height: number;
  weight: number;
  belly: number;
  balance: number;
  jump: number;
  hold: number;
  armMuscle: number;
  endurance: number;
  wristShoulder: number;
  hip: number;
  kneeAnkle: number;
  spinalDeviation: string;
  shoulderDeviation: string;
  headDeviation: string;
  hipDeviation: string;
  kneeDeviation: string;
  flatFeet: string;
}

export interface GetAbilityDto extends Partial<CreateAbilityDto> { }

export interface UpdateAbilityDto extends Partial<CreateAbilityDto> {
  status?: Status;
}

export interface AbilityDocument extends TimeStamp, ObjectId {
  createdBy: UserDocument;
  time: string;
  student?: StudentDocument;
  lead?: LeadDocument;
  height: number;
  weight: number;
  belly: number;
  balance: number;
  jump: number;
  hold: number;
  armMuscle: number;
  endurance: number;
  wristShoulder: number;
  hip: number;
  kneeAnkle: number;
  spinalDeviation: string;
  shoulderDeviation: string;
  headDeviation: string;
  hipDeviation: string;
  kneeDeviation: string;
  flatFeet: string;
}

// api
export const createAbility = async (createAbilityDto: CreateAbilityDto) => {
  const { data } = await axios.post("/ability", createAbilityDto);
  return data;
};

export const updateAbility = async ({
  id,
  updateAbilityDto,
}: {
  id: string;
  updateAbilityDto: UpdateAbilityDto;
}) => {
  const { data } = await axios.patch(`/ability/${id}`, updateAbilityDto);
  return data;
};

export const getAbility = async (id: string) => {
  const { data } = await axios.get<AbilityDocument>(`/ability/${id}`);
  return data;
};

export const getAbilities = async (
  params?: QueryOptionParams & GetAbilityDto
) => {
  const { data } = await axios.get<PaginateDocument<AbilityDocument>>(
    "/ability",
    {
      params: { ...params },
    }
  );
  return data;
};

const deleteAbility = async (id: string) => {
  const { data } = await axios.delete(`/ability/${id}`);
  return data;
};

// hooks
export const abilityKeys = generateQueryKey("ability");

export const useAbilities = (params?: QueryOptionParams & GetAbilityDto) => {
  return useQuery(abilityKeys.list(params), () => getAbilities(params));
};

export const useAbility = (id: string) => {
  return useQuery(abilityKeys.detail(id), () => getAbility(id));
};

export const useCreateAbility = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createAbility, {
    onSuccess: () => {
      queryClient.invalidateQueries(abilityKeys.lists());
      toast({ status: "success", description: "Thêm thông số thành công" });
    },
    onError: () => {
      toast({ status: "error", description: "Lỗi rồi! Báo admin nhé!" });
    }
  });
};

export const useDeleteAbility = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAbility, {
    onSuccess: () => queryClient.invalidateQueries(abilityKeys.lists()),
  });
};

export const useUpdateAbility = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAbility, {
    onSuccess: () => queryClient.invalidateQueries(abilityKeys.lists()),
  });
};
