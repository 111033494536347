import { IconButton } from "@chakra-ui/button";
import React from "react";
import { Icon, Tooltip } from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useQueryParam, StringParam } from "use-query-params";
import { Status } from "src/types/Status";

function FilterByStatus() {
  const [status, setStatus] = useQueryParam("status", StringParam);

  return (
    <Tooltip label="Đã lưu trữ">
      <IconButton
        icon={<Icon boxSize={4} as={RiDeleteBinLine} />}
        colorScheme={status === Status.Inactive ? "red" : "gray"}
        aria-label="delete"
        onClick={() => {
          if (status === Status.Inactive) {
            setStatus(Status.Active);
          } else {
            setStatus(Status.Inactive);
          }
        }}
      />
    </Tooltip>
  );
}

export default FilterByStatus;
