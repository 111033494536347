import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import { Stack } from "@chakra-ui/react";
import { UpdateEventDto } from "../../api/event";
import LabeledTimePicker from "../../components/LabeledTimePicker";
import LabeledTextareaField from "../../components/LabeledTextareaField";
import LabeledSelectUser from "src/components/LabeledSelectUser";
import LabeledSelectCenter from "src/components/LabeledSelectCenter";
import LabeledSelectEventCategory from "src/components/LabeledSelectEventCategory";

interface eventFormProps {
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateEventDto;
}

function EventForm({ onSubmit, formId, initialValues }: eventFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField name="name" label="Tên" required />
            <LabeledTextareaField name="description" label="Nội dung" />
            <LabeledTimePicker name="start" label="Bắt đầu" />
            <LabeledTimePicker name="end" label="Kết thúc" />
            <LabeledSelectUser name="inCharge" label="Phụ trách" />
            <LabeledSelectCenter name="center" label="Trung tâm" />
            <LabeledSelectEventCategory name="category" label="Danh mục" />
          </Stack>
        </form>
      )}
    />
  );
}

export default EventForm;
