import React from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useToast,
} from "@chakra-ui/react";
import FormUser from "./FormUser";
import { updateUser, UserDocument, UserDto, userKeys } from "src/api/user";
import { useQueryClient } from "react-query";
interface UserFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues: UserDocument | undefined;
}

function ModalEditUser({ isOpen, onClose, initialValues }: UserFormModalProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (values: UserDto) => {
    try {
      await updateUser(initialValues?._id || "", {
        ...values,
        roles: [values.roles],
      });
      toast({ status: "success", description: "Thành công!", position: "top" });
      queryClient.invalidateQueries(userKeys.all);
      onClose();
    } catch (error) {
      toast({ status: "error", description: "Lỗi!", position: "top" });
    }
  };

  return (
    <Drawer
      closeOnOverlayClick={false}
      size="md"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Chỉnh sửa</DrawerHeader>
        <DrawerBody>
          <FormUser
            initialValues={{
              ...initialValues,
              center: initialValues?.center?._id,
              roles: initialValues?.roles?.[0]?._id
            }}
            formId="edit-user"
            onSubmit={handleSubmit}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Hủy
          </Button>
          <Button type="submit" form="edit-user" colorScheme="blue">
            Cập nhật
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default ModalEditUser;
