import React, { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { CellProps, Column } from "react-table";
import {
  EventDocument,
  useCreateManyEvents,
  useEvents,
  useRemoveEvent,
} from "src/api/event";
import Table from "src/components/Table";
import { CreateEventDto } from "../../api/event";
import { Tooltip, IconButton } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import SessionModalEdit from "./SessionModalEdit";
import SessionAttendees from "./SessionAttendees";
import { Link as RouterLink } from "react-router-dom";
import { useClassroom } from "src/api/classroom";
import { RiDeleteBinLine } from "react-icons/ri";
import { useCurrentUser } from "src/api/auth";
import { map } from "lodash";
import { Role } from "src/types/Role";

function Sessions() {
  const [num, setNum] = useState<number>(1);
  const { id } = useParams<{ id: string }>();
  const classroom = useClassroom(id).data;
  const lastSession = useEvents({
    classroom: id,
    sortBy: "start",
    orderBy: "-1",
  }).data;
  const createManyEvents = useCreateManyEvents();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [editingData, setEditingData] = useState<{
    event?: EventDocument;
    index: number;
  }>();
  const removeEvent = useRemoveEvent();
  const userRoles = map(useCurrentUser().data?.roles || [], "value");

  const events = useEvents({
    classroom: id,
    sortBy: "start",
    orderBy: "-1",
  });

  const handleAddSessions = () => {
    if (
      window.confirm(`Bạn có chắc muốn thêm ${num} buổi học`) &&
      classroom &&
      lastSession
    ) {
      const schedule = classroom?.schedule;

      const generateFromDate = lastSession.docs?.[0]
        ? dayjs(lastSession.docs?.[0].end).add(1, "d").format("YYYY-MM-DD")
        : schedule?.fromDate;

      // const generateFromDate = schedule?.fromDate;
      const repeat = schedule?.repeat || [];
      const category = schedule?.category;

      let events = [];
      let count = 0;

      while (events.length < num) {
        const date = dayjs(generateFromDate).add(count, "day");
        const weekday = date.day();

        if (repeat.includes(weekday)) {
          // const dateString = date.format('YYYY-MM-DD');
          const [startHour, startMinute] =
            schedule[`start${weekday}`].split(":");
          const [endHour, endMinute] = schedule[`end${weekday}`].split(":");
          const start = dayjs(date)
            .hour(startHour)
            .minute(startMinute)
            .toISOString();
          const end = dayjs(date).hour(endHour).minute(endMinute).toISOString();
          const eventObject: CreateEventDto = {
            classroom: classroom?._id,
            inCharge: classroom?.coach._id,
            center: classroom?.center?._id,
            name: classroom?.name || "",
            category: category?._id,
            start,
            end,
          };
          events.push(eventObject);
        }

        count++;
      }

      createManyEvents.mutate(events);
    }
  };

  const editEvent = (event: EventDocument, index: number) => {
    onOpen();
    setEditingData({ event, index });
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Buổi",
        Cell: ({ row }) => (events?.data?.docs?.length || 0) - row.index,
      },
      {
        Header: "Thứ",
        accessor: "end",
        Cell: ({ value }) => (
          <Text textTransform={"capitalize"}>{dayjs(value).format("dd")}</Text>
        ),
      },
      {
        Header: "Thời gian",
        Cell: ({ row }) => {
          const start = dayjs(row.original?.start).format("HH:mm");
          const end = dayjs(row.original?.end).format("HH:mm");
          return `${start} - ${end}`;
        },
      },
      {
        Header: "Ngày",
        accessor: "start",
        Cell: ({ value }) => dayjs(value).format("l"),
      },
      {
        Header: "HLV",
        accessor: "inCharge",
        Cell: ({ value }) => (
          <Link
            as={RouterLink}
            to={`/user/${value?._id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            {value.name}
          </Link>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<EventDocument>) => {
          return (
            <HStack spacing={4}>
              <Tooltip label="Điểm danh">
                <SessionAttendees
                  event={row.original}
                  numOfSession={row.index + 1}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editEvent(row.original, row.index)}
                />
              </Tooltip>
              {userRoles.includes(Role.SuperAdmin) && (
                <Tooltip label="Xóa">
                  <IconButton
                    icon={<Icon boxSize={4} as={RiDeleteBinLine} />}
                    variant="ghost"
                    colorScheme="red"
                    aria-label="delete"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Bạn có chắc muốn xóa buổi ${row.index} không`
                        )
                      ) {
                        removeEvent.mutate(row.original._id);
                      }
                    }}
                  />
                </Tooltip>
              )}
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <Stack spacing={4}>
      <SessionModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData?.event}
        numOfSession={(editingData?.index || 0) + 1}
      />
      <HStack>
        <NumberInput
          max={10}
          min={1}
          value={num}
          onChange={(e) => setNum(parseInt(e))}
          size="sm"
        >
          <NumberInputField fontWeight={"bold"} />
        </NumberInput>
        <Button
          isLoading={createManyEvents.isLoading}
          leftIcon={<AddIcon />}
          onClick={handleAddSessions}
        >
          Thêm buổi
        </Button>
      </HStack>
      <Box borderWidth="1px">
        <Table columns={columns} data={events?.data?.docs || []} />
      </Box>
    </Stack>
  );
}

export default Sessions;
