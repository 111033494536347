import React, { Suspense } from "react";
import { useUser } from "../../api/user";
import {
  Container,
  Heading,
  HStack,
  Stack,
  Avatar,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import UserClassesTable from "./UserClassesTable";
import SalaryTable from "./SalaryTable";

function UserDetail() {
  const { id } = useParams<{ id: string }>();

  const { data } = useUser(id);

  return (
    <Container maxW="container.xl">
      <Breadcrumb
        my={8}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/user?page=1">
            Nhân viên
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{data?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack spacing={5} mb={5}>
        <Avatar size="xl" name="Phạm Đình Hoàng" />
        <Stack align="start">
          <Heading size="md">{data?.name}</Heading>
          <Heading as="h3" size="sm" color="gray.400" fontWeight="medium">
            {data?.phone}
          </Heading>
        </Stack>
      </HStack>
      <Tabs isLazy>
        <TabList>
          <Tab>Lịch sử dạy</Tab>
          <Tab>Lớp</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <SalaryTable />
          </TabPanel>
          <TabPanel px={0}>
            <UserClassesTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

function UserDetailWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <UserDetail />
    </Suspense>
  );
}

export default UserDetailWrapper;
