import { breadcrumbAnatomy as parts } from "@chakra-ui/anatomy"
import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools"

const baseStyleLink: SystemStyleObject = {
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline",
  },
  _focus: {
    boxShadow: "outline",
  },
}

const baseStyle: PartsStyleObject<typeof parts> = {
  link: baseStyleLink,
}

const breadcrumb = {
  parts: parts.keys,
  baseStyle,
}

export default breadcrumb
