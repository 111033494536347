import React from "react";
import { Form } from "react-final-form";
import LabeledTextField from "../../components/LabeledTextField";
import {
  Heading,
  SimpleGrid,
  Stack,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { UpdateAbilityDto } from "../../api/ability";
import LabeledNumberField from "../../components/LabeledNumberField";
import { abilities } from "src/constants/abilities";
import { pick, values } from "lodash";
import AbilityScoreNote from "./AbilityScoreNote";

interface AbilityFormProps {
  onSubmit: (e: any) => void;
  formId?: string;
  initialValues?: UpdateAbilityDto;
}

function AbilityForm({ onSubmit, formId, initialValues }: AbilityFormProps) {
  const fieldGroup1 = pick(abilities, [
    "balance",
    "jump",
    "hold",
    "armMuscle",
    "endurance",
  ]);

  const fieldGroup2 = pick(abilities, ["wristShoulder", "hip", "kneeAnkle"]);
  const fieldGroup3 = pick(abilities, [
    "headDeviation",
    "shoulderDeviation",
    "spinalDeviation",
    "hipDeviation",
    "kneeDeviation",
    "flatFeet",
  ]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <SimpleGrid columns={1} spacing={8}>
              <Stack spacing={3}>
                <LabeledTextField
                  required
                  name="time"
                  label="Thời gian *"
                  type="date"
                />
                <LabeledNumberField name="height" label="Chiều cao (cm)" />
                <LabeledNumberField name="weight" label="Cân nặng (kg)" />
                <LabeledNumberField name="belly" label="Vòng bụng (cm)" />
                {/*  <Heading size="sm" mb={2} pt={4}>
                  1. Khả năng vận động cơ thể
                </Heading>
                <Divider />
                {values(fieldGroup1).map((item) => (
                  <HStack key={item.key} alignItems="flex-end">
                    <LabeledNumberField
                      min={1}
                      max={5}
                      name={item.key}
                      label={item.label + " (1-5)"}
                      isShowStepper
                    />
                    <AbilityScoreNote title={item.label} scores={item.scores} />
                  </HStack>
                ))}
              </Stack>
            <Stack spacing={3} mb={2}>
                <Heading size="sm">2. Linh hoạt cơ thể & tư thế</Heading>
                <Divider />
                {values(fieldGroup2).map((item) => (
                  <HStack key={item.key} alignItems="flex-end">
                    <LabeledNumberField
                      min={1}
                      max={5}
                      name={item.key}
                      label={item.label + " (1-5)"}
                      isShowStepper
                    />
                    <AbilityScoreNote title={item.label} scores={item.scores} />
                  </HStack>
                ))}
                <Heading size="sm" pt={2}>
                  3. Sai lệch (Bỏ trống nếu không sai lệch)
                </Heading>
                <Divider />
                {values(fieldGroup3).map((item) => (
                  <LabeledTextField name={item.key} label={item.label} />
                ))} */}
              </Stack>
            </SimpleGrid>
          </Stack>
        </form>
      )}
    />
  );
}

export default AbilityForm;
