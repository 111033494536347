import React from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useToast,
} from "@chakra-ui/react";
import FormLead from "./FormLead";
import { updateLead, leadKeys, LeadDocument, LeadUpdateDto } from "src/api/lead";
import { useQueryClient } from "react-query";

interface LeadFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  editData: LeadDocument | undefined;
}

function ModalEditLead({ isOpen, onClose, editData }: LeadFormModalProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const updateLeadDto : LeadUpdateDto = {
    ...editData,
    center: editData?.center?._id,
    leadStatus: editData?.leadStatus?._id,
    takeCareBy: editData?.takeCareBy?._id,
  };

  const handleSubmit = async (values: LeadUpdateDto) => {
    try {
      await updateLead(editData?._id || "", values);
      toast({ status: "success", description: "Thành công!", position: "top" });
      queryClient.invalidateQueries(leadKeys.all);
      onClose();
    } catch (error) {
      toast({ status: "error", description: "Lỗi!", position: "top" });
    }
  };

  return (
    <Drawer
      closeOnOverlayClick={false}
      size="md"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Chỉnh sửa</DrawerHeader>
        <DrawerBody>
          <FormLead
            initialValues={updateLeadDto}
            formId="add-lead"
            onSubmit={handleSubmit}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Hủy
          </Button>
          <Button type="submit" form="add-lead" colorScheme="blue">
            Cập nhật
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default ModalEditLead;
