import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Icon,
  IconButton,
  HStack,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { HiOutlineMenu } from "react-icons/hi";
import { MdOutlineSportsBasketball } from "react-icons/md";
import NavUserInfo from "../components/NavUserInfo";
import SideBar from "src/components/SideBar";
import { Helmet } from "react-helmet";

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("isOpenNav", JSON.stringify(!isOpen));
  };

  useEffect(() => {
    const initIsOpen = JSON.parse(localStorage.getItem("isOpenNav") || "false");
    setIsOpen(initIsOpen);
  }, []);

  return (
    <>
      <Helmet>
        <title>NINJA KIDS</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <Grid
        bg="gray.800"
        overflowY="auto"
        minH="100vh"
        gridTemplateColumns="min-content 1fr"
      >
        <GridItem
          width={isOpen ? "200px" : "66px"}
          borderRight="1px"
          borderColor={"gray.700"}
          overflow="auto"
          minH="100vh"
          h="100vh"
          position="sticky"
          top="0px"
        >
          <HStack position="sticky" top={0} px={4} h={16} spacing={1}>
            <Icon color="blue.400" boxSize={8} as={MdOutlineSportsBasketball} />
            <Heading size="md">{isOpen ? "NK" : ""}</Heading>
          </HStack>
          <SideBar isOpen={isOpen} />
        </GridItem>
        <Grid
          overflowY="auto"
          h="100vh"
          minH="100vh"
          gridTemplateRows="48px 1fr"
        >
          <HStack px={4} borderBottom="1px" borderColor={"gray.700"}>
            <IconButton
              variant="ghost"
              aria-label="Menu"
              icon={<Icon fontSize="xl" as={HiOutlineMenu} />}
              onClick={onToggle}
              colorScheme="gray"
            />
            <Spacer />
            <NavUserInfo />
          </HStack>
          <GridItem overflowY="auto" bg={"gray.900"}>
            {children}
          </GridItem>
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultLayout;
