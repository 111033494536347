import { useQuery } from "react-query";
import axios from "src/config/axios";
import { UserDocument } from "./user";

export const currentUserKey = "current-user";

export interface LoginDto {
  username: string;
  password: string;
}

export const login = async (loginDto: LoginDto) => {
  const { data } = await axios.post("/auth/login", loginDto);
  return data;
};

export const getCurrentUser = async () => {
  const { data } = await axios.get<UserDocument>("/auth/profile");
  return data;
};

// hook

export const useCurrentUser = () => {
  return useQuery(currentUserKey, getCurrentUser, { retry: 0 });
};
