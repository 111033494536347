import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import FilterByCenterWrapper from "src/components/FilterByCenter";
import FilterClearButton from "src/components/FilterClearButton";
import StudentTable from "./TakeCareStudentTable";
// import StudentPaging from "./StudentPaging";
import FilterByUser from "src/components/FilterByUser";
import FilterBySearchKey from "src/components/FilterBySearchKey";
import ListTakeCareByTrainer from "./ListTakeCareByTrainer";

function TakeCare() {
  return (
    <>
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Danh sách take care
          </Heading>
          <Spacer />
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterBySearchKey placeholder="Tìm theo tên hoặc mã số học viên" />
                {/* <FilterByCenterWrapper /> */}
                <FilterByUser />
                <FilterClearButton />
              </HStack>
              <Spacer />
              {/* <FilterByPageSize /> */}
              {/* <StudentPaging /> */}
            </Flex>
            <ListTakeCareByTrainer/>

          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <StudentTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export function TakeCareWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <TakeCare />
    </React.Suspense>
  );
}

export default TakeCareWrapper;
