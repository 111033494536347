import axios from "src/config/axios";
import { UserDocument } from "./user";
import { ObjectId } from "../types/ObjectId";
import { TimeStamp } from "../types/TimeStamp";

export enum NoteType {
  Note = "lead",
  Student = "student",
}

export interface NoteDto {
  content: string;
}

export interface NoteDocument extends ObjectId, TimeStamp {
  content: string;
  author: UserDocument;
  type: NoteType;
}

export const createNote = async (noteDto: NoteDto) => {
  const { data } = await axios.post<NoteDocument>("/note", noteDto);
  return data;
};
