import dayjs from "dayjs";

export const WARNING_DEADLINE = 36;
export const ERROR_DEADLINE = 56;

export const getBodyIndexTextColor = (time: any) => {
    const today = dayjs();
    const errorDeadline = today.subtract(ERROR_DEADLINE, 'days'); // Calculate 25 days ago
    const warningDeadline = today.subtract(WARNING_DEADLINE, 'days'); // Calculate 25 days ago
    if (!time) {
        return "red.400"
    } else if (!dayjs(time).isAfter(errorDeadline)) {
        return "red.400"
    } else if (!dayjs(time).isAfter(warningDeadline)) {
        return "orange.400"
    }
    return "green.400"
}

export const getBodyIndexTextContent = (time: any) => {
    const today = dayjs();
    const errorDeadline = today.subtract(ERROR_DEADLINE, 'days'); // Calculate 25 days ago
    const warningDeadline = today.subtract(WARNING_DEADLINE, 'days'); // Calculate 25 days ago
    if (!time) {
        return "Chưa có"
    } else if (!dayjs(time).isAfter(errorDeadline)) {
        return "Quá hạn"
    } else if (!dayjs(time).isAfter(warningDeadline)) {
        return "Cần cập nhật"
    }
    return "Đã update"
}


export const WARNING_TAKE_CARE = 21;
export const ERROR_TAKE_CARE = 35;

export const getTakeCareTextColor = (time: any) => {
    const today = dayjs();
    const errorDeadline = today.subtract(ERROR_TAKE_CARE, 'days'); // Calculate 25 days ago
    const warningDeadline = today.subtract(WARNING_TAKE_CARE, 'days'); // Calculate 25 days ago
    if (!time) {
        return "red.400"
    } else if (!dayjs(time).isAfter(errorDeadline)) {
        return "red.400"
    } else if (!dayjs(time).isAfter(warningDeadline)) {
        return "orange.400"
    }
    return "green.400"
}

export const getTakeCareTextContent = (time: any) => {
    const today = dayjs();
    const errorDeadline = today.subtract(ERROR_TAKE_CARE, 'days'); // Calculate 25 days ago
    const warningDeadline = today.subtract(WARNING_TAKE_CARE, 'days'); // Calculate 25 days ago
    if (!time) {
        return "Chưa có"
    } else if (!dayjs(time).isAfter(errorDeadline)) {
        return "Quá hạn"
    } else if (!dayjs(time).isAfter(warningDeadline)) {
        return "Cần take care"
    }
    return "Đã gửi"
}