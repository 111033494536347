import { useQuery } from "react-query";
import axios from "src/config/axios";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { PaginateDocument } from "../types/PaginateDocument";
import { TimeStamp } from "src/types/TimeStamp";
import { Status } from "src/types/Status";
import { RoleDocument } from "./role";
import { CenterDocument } from './center';
export const classUserKeys = generateQueryKey("user");

export interface UserDto {
  name?: string;
  roles?: string[];
  username?: string;
  password?: string;
  phone?: string;
  bankAccount?: string;
  bankNumber?: string;
  salary?: number;
  rate?: number;
  center?: string;
  status?: Status;
}
export interface GetClassUser {
  status?: Status;
}

export interface UpdateUserDto {
  name?: string;
  roles?: string[];
  username?: string;
  password?: string;
  phone?: string;
  bankAccount?: string;
  bankNumber?: string;
  salary?: number;
  rate?: number;
  center?: string;
  status?: Status;
}

export interface UserDocument extends ObjectId, TimeStamp {
  name?: string;
  roles: RoleDocument[];
  username?: string;
  password?: string;
  phone?: string;
  bankAccount?: string;
  bankNumber?: string;
  salary?: number;
  rate?: number;
  center?: CenterDocument;
  status?: Status;
}

export const createUser = async (createUserDto: UserDto) => {
  console.log("createUserDto: ", createUserDto);
  const { data } = await axios.post("/user", createUserDto);
  return data;
};

export const updateUser = async (id: string, updateData: UpdateUserDto) => {
  const { data } = await axios.patch(`/user/${id || ""}`, updateData);
  return data;
};

export const getUser = async (id: string) => {
  const { data } = await axios.get<UserDocument>(`/user/${id}`);
  return data;
};

export const getUsers = async (params?: any) => {
  const { data } = await axios.get<PaginateDocument<UserDocument>>("/user", {
    params,
  });
  return data;
};

// hooks
export const userKeys = generateQueryKey("user");

export const useUsers = () => {
  return useQuery(userKeys.lists(), () => getUsers(), { staleTime: Infinity });
};

export const useUser = (id: string) => {
  return useQuery(userKeys.detail(id), () => getUser(id), {
    staleTime: Infinity,
  });
};

