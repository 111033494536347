import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Form } from "react-final-form";
import LabeledTextField from "src/components/LabeledTextField";
import {
  ClassStudentDocument,
  useUpdateClassroomStudent,
} from "../../api/classroom";
import { useParams } from "react-router";
import { formatMoney } from '../../utils/format';
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

function StudentItemEditModal({
  initialValues,
}: {
  initialValues: ClassStudentDocument;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams<{ id: string }>();
  const updateClassroomStudent = useUpdateClassroomStudent(id);

  return (
    <>
      <IconButton
        onClick={onOpen}
        icon={<EditIcon />}
        colorScheme="gray"
        variant="ghost"
        aria-label="add"
      />
      <Modal  {...useResponsiveModalValue()} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Chỉnh sửa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form
              initialValues={initialValues}
              onSubmit={async (e: ClassStudentDocument) => {
                await updateClassroomStudent.mutate({
                  student: initialValues._id,
                  feePerSession: e.feePerSession,
                });
                onClose()
              }}
              render={({ handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                  <Stack mb={5}>
                    <LabeledTextField
                      required
                      type="number"
                      name="feePerSession"
                      label="Học phí mỗi buổi"
                      helperText={formatMoney(values.feePerSession || 0)}
                    />
                    <Button type="submit"> Cập nhật</Button>
                  </Stack>
                </form>
              )}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default StudentItemEditModal;
