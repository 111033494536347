import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import StudentForm from "./StudentForm";
import {
  CreateStudentDto,
  createStudent,
  studentKeys,
} from "../../api/student";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-student";

interface StudentModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function StudentModalAdd({ isOpen, onClose }: StudentModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateStudentDto) => {
    try {
      await createStudent({
        ...values,
        birthDay: values.birthDay && dayjs(values.birthDay).toISOString(),
      });
      onClose();
      toast({ status: "success", description: "Thêm học viên thành công" });
      queryClient.invalidateQueries(studentKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Lỗi" });
    }
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thêm học viên</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StudentForm onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Thêm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default StudentModalAdd;
