import React, { Suspense, useState } from "react";
import Table from "src/components/Table";
import {
  ClassroomDocument,
  useClassrooms,
  classroomKeys,
} from "../../api/classroom";
import { CellProps, Column } from "react-table";
import dayjs from "dayjs";
import {
  Tooltip,
  Text,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
  Avatar,
} from "@chakra-ui/react";
import useSearchParams from "../../utils/useSearchParams";
import { FiEdit } from "react-icons/fi";
import ClassroomModalEdit from "./ClassroomModalEdit";
import { Status } from "src/types/Status";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdRestore } from "react-icons/md";
import { updateClassroom } from "../../api/classroom";
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { formatMoney } from "src/utils/format";
import { isArray } from "lodash";

function ClassroomTable() {
  const queryClient = useQueryClient();
  const { params } = useSearchParams();
  const { data } = useClassrooms(params);
  const [editingData, setEditingData] = useState<ClassroomDocument>({});

  const { isOpen, onClose, onOpen } = useDisclosure();

  const editClassroom = (classroom: ClassroomDocument) => {
    onOpen();
    setEditingData(classroom);
  };

  const archiveClassroom = async (classroom: ClassroomDocument) => {
    try {
      if (window.confirm(`Lưu trữ ${classroom?.name}`)) {
        await updateClassroom(classroom._id, { status: Status.Inactive });
        queryClient.invalidateQueries(classroomKeys.all);
      }
    } catch (error) {}
  };

  const restoreClassroom = async (classroom: ClassroomDocument) => {
    try {
      if (window.confirm(`Khôi phục ${classroom?.name}`)) {
        await updateClassroom(classroom._id, { status: Status.Active });
        queryClient.invalidateQueries(classroomKeys.all);
      }
    } catch (error) {}
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: CellProps<ClassroomDocument>) => (
          <Link
            color="blue.300"
            to={`/classroom/${row.original?._id}`}
            as={RouterLink}
          >
            {row.original.name}
          </Link>
        ),
      },
      // {
      //   Header: "Số học viên",
      //   Cell: ({ row }: any) => (
      //     <Link
      //       color="blue.300"
      //       to={`/classroom/${row.original?._id}`}
      //       as={RouterLink}
      //     >
      //       {isArray(row.original.students) ? row.original.students.length : 0}
      //     </Link>
      //   ),
      // },
      {
        Header: "Phụ trách",
        accessor: "coach",
        Cell: ({ value }) => (
          <Link color="blue.300" to={`/user/${value?._id}`} as={RouterLink}>
            <HStack>
              <Avatar size="xs" name={value?.name} />
              <Text>{value?.name}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Lương dạy",
        accessor: "salary",
        Cell: ({ value }) => <Text>{formatMoney(value || 0)}</Text>,
      },
      {
        Header: "Ngày bắt đầu",
        accessor: "schedule",
        Cell: ({ value }) => (
          <Text>{value?.fromDate && dayjs(value?.fromDate).format("LL")}</Text>
        ),
      },
      {
        Header: "Trung tâm",
        accessor: "center",
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: "Cập nhật",
        accessor: "updatedAt",
        Cell: ({ value }) => (
          <Tooltip label={dayjs(value).format("LLLL")}>
            {dayjs(value).fromNow()}
          </Tooltip>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: CellProps<ClassroomDocument>) => {
          const isActive = row.original.status === Status.Active;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Lưu trữ" : "Khôi phục"}>
                <IconButton
                  icon={
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBinLine : MdRestore}
                    />
                  }
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      archiveClassroom(row.original);
                    } else {
                      restoreClassroom(row.original);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip label="Chỉnh sửa">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={() => editClassroom(row.original)}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      <ClassroomModalEdit
        isOpen={isOpen}
        onClose={onClose}
        editingData={editingData}
      />
      <Table columns={columns} data={data?.docs || []} />
    </>
  );
}

function ClassroomTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <ClassroomTable />
    </Suspense>
  );
}

export default ClassroomTableWrapper;
