import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { UserDocument } from "./user";
import { PaymentMethod } from "src/types/PaymentMethod";
import { StudentDocument } from "./student";
import { CenterDocument } from "./center";

export interface CreateTransactionDto {
  amount: number;
  method: PaymentMethod;
  student: string;
  center: string;
  sale: string;
  date: string;
  note: string;
}

export interface GetTransactionDto extends Partial<CreateTransactionDto> {}

export interface UpdateTransactionDto extends Partial<CreateTransactionDto> {
  status?: Status;
  commission?: number;
}

export interface TransactionDocument extends TimeStamp, ObjectId {
  status: Status;
  amount: number;
  method: PaymentMethod;
  student: StudentDocument;
  center: CenterDocument;
  note: string;
  date: Date;
  sale: UserDocument;
  createdBy: UserDocument;
}

// api
export const createTransaction = async (
  createTransactionDto: CreateTransactionDto
) => {
  const { data } = await axios.post("/transaction", createTransactionDto);
  return data;
};

export const getTransaction = async (id: string) => {
  const { data } = await axios.get<TransactionDocument>(`/transaction/${id}`);
  return data;
};

export const getTransactions = async (
  params?: QueryOptionParams & GetTransactionDto
) => {
  const { data } = await axios.get<PaginateDocument<TransactionDocument>>(
    "/transaction",
    {
      params: { page: 1, limit: 20, ...params },
    }
  );
  return data;
};

const deleteTransaction = async (id: string) => {
  const { data } = await axios.delete(`/transaction/${id}`);
  return data;
};

export const getTotalAmount = async (id: string) => {
  const { data } = await axios.get<{ totalAmount: number }>(
    `/transaction/${id}/total`
  );
  return data;
};

// hooks
export const transactionKeys = {
  ...generateQueryKey("transaction"),
  total: ["transaction", "total"],
};

export const useTransactions = (
  params?: QueryOptionParams & GetTransactionDto
) => {
  return useQuery(transactionKeys.list(params), () => getTransactions(params));
};

export const useTransaction = (id: string) => {
  return useQuery(transactionKeys.detail(id), () => getTransaction(id));
};

export const useCreateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation(createTransaction, {
    onSuccess: () => queryClient.invalidateQueries(transactionKeys.lists()),
  });
};

export const useTotalAmount = (id: string) => {
  return useQuery(transactionKeys.total, () => getTotalAmount(id));
};

export const useDeleteTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTransaction, {
    onSuccess: () => queryClient.invalidateQueries(transactionKeys.lists()),
  });
};

export const useUpdateTransaction = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateTransactionDto: UpdateTransactionDto) =>
      axios
        .patch(`/transaction/${id}`, updateTransactionDto)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(transactionKeys.all);
      },
    }
  );
};
