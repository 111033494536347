import React, { useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { useCurrentUser } from "../api/auth"
import { Role } from "src/types/Role";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Avatar,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  Input,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { useUsers } from "src/api/user";
import { Suspense } from "react";

interface FilterByUserProps {
  name?: string;
  label?: string;
}

function Filter({
  name = "takeCareBy",
  label = "Take care",
}: FilterByUserProps) {
  const initialFocusRef = React.useRef();
  const [search, setSearch] = useState("");
  const [isClear, setClear] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  // const { data: currentUser } = useCurrentUser();
  // const role = currentUser?.roles.filter(role => role.value === Role.User)
  const [takeCareBy, setTakeCareBy] = useQueryParam(name, StringParam);
  // if (!takeCareBy && !isClear && role?.length) {
  //   setTakeCareBy(currentUser?._id)
  // }
  const { data: users } = useUsers();

  const filteredUsers = (users?.docs || []).filter((user) => {
    const userName = user.name?.toLowerCase() || "";
    return userName.includes(search.toLowerCase());
  });

  const selectedUser = users?.docs?.find((item) => item._id === takeCareBy);

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={takeCareBy ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedUser?.name || label}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>{label}</PopoverHeader>
        <PopoverBody>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            mb={3}
            ref={initialFocusRef}
            type="text"
            placeholder=""
          />
          <Box maxH="56" overflowY="auto">
            {filteredUsers.map((user) => (
              <HStack
                onClick={() => {
                  setTakeCareBy(user._id);
                  onClose();
                }}
                color={user._id === takeCareBy ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={user._id}
                p={2}
              >
                <Avatar size="xs" name={user.name} />
                <Text fontSize="sm" fontWeight="semibold">
                  {user.name}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setTakeCareBy("");
                onClose();
                setClear(true)
              }}
            >
              Xóa
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByUser(props: FilterByUserProps) {
  return (
    <Suspense
      fallback={
        <Button variant="ghost" isLoading>
          {props.label}
        </Button>
      }
    >
      <Filter {...props} />
    </Suspense>
  );
}

export default FilterByUser;
