import { mode, Styles } from "@chakra-ui/theme-tools";

const styles: Styles = {
  global: (props) => ({
    body: {
      color: mode("gray.600", "gray.300")(props),
      bg: mode("white", "gray.800")(props),
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base",
      fontWeight: 500,
    },
    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
    a: {
      color: props.colorMode === "dark" ? "blue.300" : "blue.500",
    },
    "table.border": {
      "td, th": {
        border: "1px solid black",
      },
    },
    "table.padding": {
      "td, th": {
        padding: "6px 0",
      },
    },
  }),
};

export default styles;
