import { Box, Flex, Spacer, HStack, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import FilterClearButton from "src/components/FilterClearButton";
import TransactionTable from "./TransactionTable";
import TransactionPaging from "./TransactionPaging";
import FilterByTime from "src/components/FilterByTime";

function Transaction() {
  return (
    <Flex direction="column">
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterByTime name="date" label="Ngày giao dịch" />
              <FilterClearButton />
            </HStack>
            <Spacer />
            <TransactionPaging />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <TransactionTable />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export default Transaction;
