import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { Status } from "src/types/Status";
import { SkillCategoryDocument } from "./skill-category";

export interface CreateSkillDto {
  name: string;
  category: string;
  star: number;
  video: string;
}

export interface GetSkillDto extends Partial<CreateSkillDto> {}

export interface UpdateSkillDto extends Partial<CreateSkillDto> {
  status?: Status;
}

export interface SkillDocument extends TimeStamp, ObjectId {
  status: Status;
  name: string;
  category: SkillCategoryDocument;
  star: number;
  video: string;
}

// api
export const createSkill = async (createSkillDto: CreateSkillDto) => {
  const { data } = await axios.post("/skill", createSkillDto);
  return data;
};

export const updateSkill = async (
  id: string,
  createSkillDto: UpdateSkillDto
) => {
  const { data } = await axios.patch(`/skill/${id}`, createSkillDto);
  return data;
};

export const getSkill = async (id: string) => {
  const { data } = await axios.get<SkillDocument>(`/skill/${id}`);
  return data;
};

export const getSkills = async (params?: QueryOptionParams & GetSkillDto) => {
  const { data } = await axios.get<PaginateDocument<SkillDocument>>("/skill", {
    params: { page: 1, limit: 20, ...params },
  });
  return data;
};

const deleteSkill = async (id: string) => {
  const { data } = await axios.delete(`/skill/${id}`);
  return data;
};

// hooks
export const skillKeys = generateQueryKey("skill");

export const useSkills = (params?: QueryOptionParams & GetSkillDto) => {
  return useQuery(skillKeys.list(params), () => getSkills(params));
};

export const useSkill = (id: string) => {
  return useQuery(skillKeys.detail(id), () => getSkill(id));
};

export const useCreateSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(createSkill, {
    onSuccess: () => queryClient.invalidateQueries(skillKeys.lists()),
  });
};

export const useUpdateSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: string; data: UpdateSkillDto }) =>
      updateSkill(id, data),
    {
      onSuccess: () => queryClient.invalidateQueries(skillKeys.lists()),
    }
  );
};

export const useDeleteSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSkill, {
    onSuccess: () => queryClient.invalidateQueries(skillKeys.lists()),
  });
};
