import {
  HStack,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link
} from "@chakra-ui/react";
import React from "react";
import { Suspense } from "react";
import { ACCESS_TOKEN } from "src/constants/localStorage";
import { useCurrentUser, currentUserKey } from '../api/auth';
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";

const thisMonth = {
  startDate: dayjs().startOf("month").toISOString(),
  endDate: dayjs().endOf("month").toISOString(),
  dateKey: "createdAt",
};
function NavUserInfo() {
  const { data: user } = useCurrentUser();
  const queryClient = useQueryClient();

  const signOut = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    queryClient.invalidateQueries(currentUserKey)
  };

  return (
    <HStack>
      <Text fontSize="sm" fontWeight="semibold">{user?.name}</Text>
      <Menu>
        <MenuButton>
          <Avatar size="sm" name={user?.name} />
        </MenuButton>
        <MenuList>
          <MenuItem >
            <Link
              as={RouterLink}
              to={`/user/${user?._id}?${queryString.stringify(thisMonth)}`}
              fontWeight="semibold"
              color="blue.300"
            >
              Trang cá nhân
            </Link>
          </MenuItem>
          <MenuItem onClick={signOut}>Đăng xuất</MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
}

function NavUserInfoWrapper() {
  return (
    <Suspense fallback="loading...">
      <NavUserInfo />
    </Suspense>
  );
}

export default NavUserInfoWrapper;
