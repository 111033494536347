import React, { Suspense, useEffect } from "react";
import { useStudent, useUpdateStudent } from "../../api/student";
import {
  Container,
  Heading,
  HStack,
  Stack,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Spinner,
  Breadcrumb,
  Button,
  BreadcrumbItem,
  BreadcrumbLink,
  useDisclosure,
  Text,
  Spacer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Divider,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import StudentModalEdit from "../student/StudentModalEdit";
import Transaction from "./Transaction";
import TransactionModalAdd from "./TransactionModalAdd";
import { formatMoney } from "../../utils/format";
import History from "./History";
import { useStudentMoney } from "./hooks/money";
import Ability from "./Ability";
import { updateStudentBalanceById } from "../../api/student";
import { Form } from "react-final-form";
import LabeledAvatarField from "src/components/LabeledAvatarField";
import Skill from "./Skill";

function StudentDetail() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenTransaction,
    onClose: onCloseTransaction,
    onOpen: onOpenTransaction,
  } = useDisclosure();

  const { id } = useParams<{ id: string }>();

  const { data } = useStudent(id);
  const updateStudent = useUpdateStudent();
  const { total, spend } = useStudentMoney(id);

  useEffect(() => {
    updateStudentBalanceById(id);
  }, [id]);

  return (
    <Container maxW="container.xl">
      <Breadcrumb
        my={8}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/student?page=1">
            Học viên
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{data?.number}. {data?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack spacing={5} mb={5} align="start">
        <Form
          initialValues={{ photoUrl: data?.photoUrl || "" }}
          onSubmit={async (values) => {
            updateStudent.mutate({
              id: id,
              data: { photoUrl: values.photoUrl },
            });
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LabeledAvatarField
                submitOnClose
                name="photoUrl"
                avatarProps={{ size: "xl" }}
              />
            </form>
          )}
        />
        <Stack align="start">
          <Heading size="md">{data?.number}. {data?.name}</Heading>
          <Heading as="h3" size="sm" color="gray.400" fontWeight="medium">
            {data?.phone}
          </Heading>
          <Button onClick={onOpen} variant="outline" size="xs">
            Sửa thông tin
          </Button>
          {data && (
            <StudentModalEdit
              isOpen={isOpen}
              onClose={onClose}
              editingData={data}
            />
          )}
        </Stack>
        <Spacer />
        <Stack>
          <Popover>
            <PopoverTrigger>
              <Button
                rightIcon={<ChevronDownIcon />}
                colorScheme={"teal"}
                size="md"
                variant="outline"
              >
                {formatMoney(total - spend)}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Chi tiết</PopoverHeader>
              <PopoverBody>
                <Stack py={2} spacing={3}>
                  <HStack justifyContent="space-between">
                    <Text>Tổng nạp</Text>
                    <Text color="teal.200">+{formatMoney(total)}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text>Tổng chi</Text>
                    <Text color="red.200">-{formatMoney(spend)}</Text>
                  </HStack>
                  <Divider />
                  <HStack justifyContent="space-between">
                    <Text>Còn lại</Text>
                    <Text borderRadius="md" fontWeight="bold" color="teal.200">
                      {formatMoney(total - spend)}
                    </Text>
                  </HStack>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Button onClick={onOpenTransaction} colorScheme="teal">
            Tạo giao dịch
          </Button>
          <TransactionModalAdd
            isOpen={isOpenTransaction}
            onClose={onCloseTransaction}
          />
        </Stack>
      </HStack>
      <Tabs isLazy>
        <TabList>
          <Tab>Lịch sử học</Tab>
          <Tab>Thông số</Tab>
          <Tab>Lịch sử giao dịch</Tab>
          <Tab>Kĩ năng</Tab>
        </TabList>
        <TabPanels>
        <TabPanel px={0}>
            <History />
          </TabPanel>
          <TabPanel px={0}>
            <Ability />
          </TabPanel>
          <TabPanel px={0}>
            <Transaction />
          </TabPanel>
          <TabPanel px={0}>
            <Skill />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

function StudentDetailWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <StudentDetail />
    </Suspense>
  );
}

export default StudentDetailWrapper;
