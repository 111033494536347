import axios from "axios";
import { ACCESS_TOKEN } from '../constants/localStorage';

axios.defaults.baseURL = process.env.REACT_APP_API;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem(ACCESS_TOKEN);
    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

export default instance;
