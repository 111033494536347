import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useTransactions } from "../../api/transaction";
import FilterByPage from "src/components/FilterByPage";

function TransactionPaging() {
  const { params } = useSearchParams();
  const { data } = useTransactions(params);

  return (
    <FilterByPage totalDocs={data?.totalDocs} totalPages={data?.totalPages} />
  );
}

function TransactionPagingWrapper() {
  return (
    <Suspense fallback="">
      <TransactionPaging />
    </Suspense>
  );
}

export default TransactionPagingWrapper;
