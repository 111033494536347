import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { Button } from "@chakra-ui/react";
import TransactionForm from "./TransactionUpdateForm";
import {
  TransactionDocument,
  UpdateTransactionDto,
} from "../../api/transaction";
import { pick } from "lodash";
import { formatMoney } from "src/utils/format";
import { useUpdateTransaction } from "../../api/transaction";
import {useResponsiveModalValue} from "src/utils/useResponsiveModal";

const FORM_ID = "add-transaction";

interface TransactionUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: TransactionDocument;
}

function TransactionUpdateModal({
  isOpen,
  onClose,
  editingData,
}: TransactionUpdateModalProps) {
  const updateTransaction = useUpdateTransaction(editingData._id);

  const onSubmit = async (values: UpdateTransactionDto) => {
    updateTransaction.mutate(values);
    onClose();
  };

  return (
    <Modal  {...useResponsiveModalValue()} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`${editingData.student?.name}: ${formatMoney(
          editingData.amount
        )}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TransactionForm
            initialValues={{
              ...pick(editingData, [
                "amount",
                "commission",
                "date",
                "method",
                "note",
              ]),
              sale: editingData?.sale?._id,
            }}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Hủy
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Cập nhật
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TransactionUpdateModal;
